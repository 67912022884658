import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Message,
  Breadcrumb,
} from "element-react";
import Start from "./start";
import UploadSubmission from "./uploadSubmission";
import Authors from "./authors";
import MetaData from "./metaData";
import SubmitArticle from "./submitArticle";
import { useHistory } from "react-router";
import RouteEnum from "constants/routesEnum";
import { Link } from "react-router-dom";
import { GetArticle, clearArticle } from "store/actions/articles";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "helpers";

const Submisson = (props) => {
  const [steps, setSteps] = useState(1);
  const [selectedTab, setSelectedTab] = useState("1");
  const dispatch = useDispatch();
  const history = useHistory();
  const { articleId, revisionId } = props.match.params;
  const { article } = useSelector((st: any) => st.articleReducer);

  useEffect(() => {
    if (!isEmpty(article)) {
      var step = article.Steps;
      if (step <= 4) step++;
      setSteps(step);

      if (!isEmpty(revisionId)) step = 3;
      setSelectedTab(`${step}`);
    } else {
      setSteps(1);
      setSelectedTab(`1`);
    }
  }, [article]);

  useEffect(() => {
    if (articleId) {
      fetchArticle(articleId);
    } else {
      dispatch(clearArticle());
    }
  }, [articleId]);

  const fetchArticle = async (articleId) => {
    await dispatch(GetArticle(articleId));
  };

  const handleOnSaveStep1 = () => {
    updateStep(2);
    setSelectedTab("2");
  };

  const handleOnSaveStep2 = () => {
    updateStep(3);
    setSelectedTab("3");
  };
  const handleOnSaveStep3 = () => {
    updateStep(4);
    setSelectedTab("4");
  };
  const handleOnSaveStep4 = () => {
    updateStep(5);
    setSelectedTab("5");
  };
  const handleConfirmation = () => {
    Message({
      showClose: true,
      message: "Congrats, submission completed.",
      type: "success",
    });
    history.push(`${RouteEnum.submitter}`);
  };
  const updateStep = (newStep: number) => {
    if (newStep > steps) {
      setSteps(newStep);
    }
  };

  const isDisabled = (tab) => {
    return steps < tab;
  };

  const handleTabClicked = (e) => {
    const { name } = e.props;
    setSelectedTab(name);
  };

  const tabs = [
    {
      label: "1.Start",
      name: "1",
      component: (selectedTab) => (
        <Start onSave={() => handleOnSaveStep1()} selectedTab={selectedTab} />
      ),
    },
    {
      label: "2. Authors and CoAuthors",
      name: "2",
      component: (selectedTab) => (
        <Authors onSave={() => handleOnSaveStep2()} />
      ),
    },
    {
      label: "3. Upload Submission",
      name: "3",
      component: (selectedTab) => (
        <UploadSubmission onSave={() => handleOnSaveStep3()} isRevision={!isEmpty(revisionId)}/>
      ),
    },
    {
      label: "4. Enter Metadata",
      name: "4",
      component: (selectedTab) => (
        <MetaData
          onSave={() => handleOnSaveStep4()}
          selectedTab={selectedTab}
        />
      ),
    },
    {
      label: "5.Confirmation",
      name: "5",
      component: () => (
        <SubmitArticle
          onConfirmation={() => handleConfirmation()}
          revisionId={revisionId}
        />
      ),
    },
  ];

  return (
    <>
      <Breadcrumb separator="/">
        <Breadcrumb.Item>
          <Link to={RouteEnum.submitter}>Home</Link>
        </Breadcrumb.Item>
        {!isEmpty(revisionId) && (
          <Breadcrumb.Item>
            <Link to={`${RouteEnum.revisionDetail}/${revisionId}`}>
              Revision Detail
            </Link>
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>Submission</Breadcrumb.Item>
      </Breadcrumb>

      <div className="submission">
        <Tabs type="card" value={selectedTab} onTabClick={handleTabClicked}>
          {tabs.map((item, index) => (
            <Tabs.Pane
              key={index}
              label={item.label}
              name={item.name}
              disabled={isDisabled(index + 1)}
            >
              {item.component(selectedTab)}
            </Tabs.Pane>
          ))}
        </Tabs>
      </div>
    </>
  );
};
export default Submisson;
