import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { MessageBox } from 'element-react'
import { DeleteArticle } from 'store/actions/articles'
import RouteEnum from 'constants/routesEnum'
import ArticleStatusEnum from 'constants/articleStatusEnum'
import Articles from 'scenes/common/articles'
import Actions from './actions'

const Submissions = (props) => {
  const { selectedTab } = props
  const articlesRef: any = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()

  const getStatus = () => {
    var status = '0'
    if (selectedTab == 'NEW') status = `${ArticleStatusEnum.NEW}`
    else if (selectedTab == 'SUBMITTED') {
      status = status = [
        ArticleStatusEnum.SUBMITTED,
        ArticleStatusEnum.ASSIGNEDEDITOR,
        ArticleStatusEnum.REVIEWING,
        ArticleStatusEnum.REVIEWED,
        ArticleStatusEnum.RESUBMITTED,
        ArticleStatusEnum.FINALAPPROVED
      ].toString()
    } else if (selectedTab == 'REVERTED')
      status = `${ArticleStatusEnum.REVISION}`
    else if (selectedTab == 'REJECTED') status = `${ArticleStatusEnum.REJECTED},${ArticleStatusEnum.FINALREJECTED}`
    else if (selectedTab == 'APPROVED') status = `${ArticleStatusEnum.APPROVED},${ArticleStatusEnum.FINALAPPROVED}`
    return status
  }

  const confirmDelete = (obj) => {
    MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    })
      .then(async () => {
        await dispatch(DeleteArticle(obj.Id))
        articlesRef.current.loadData()
      })
      .catch(() => { })
  }

  const renderAction = (row, index) => {
    return (
      <Actions
        row={row}
        handleEditClick={(row) =>
          history.push(`${RouteEnum.submission}/${row.Id}`)
        }
        handleDelete={(row) => confirmDelete(row)}
        handleRevisionClick={(row) =>
          history.push(`${RouteEnum.revisionDetail}/${row.RevisionId}`)
        }
      />
    )
  }
  return (
    <div className="submitter">
      <Articles
        selectedTab={selectedTab}
        actions={renderAction}
        getStatus={getStatus}
        ref={articlesRef}
      />
    </div>
  )
}

export default Submissions
