import RoleEnum from "constants/roleEnum";
import { UserDetailInterface } from "interfaces/actions/auth";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let isAuth = localStorage.getItem("AuthToken");
  let role = localStorage.getItem("Role");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.role === RoleEnum.Public) return <Component {...props} />;
        if (isAuth && rest.role === role) return <Component {...props} />;
        return <Redirect to="/login" />;
      }}
    />
  );
};
export default PrivateRoute;
