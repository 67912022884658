import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const TabTitle = ({ item, selectedTab, count }) => {
  const { articleCounts } = useSelector((state: any) => state.articleReducer);
  const getValue = (name) => {
    var res = articleCounts.find((x) => x.Type === name);

    return (res && res.Total) || 0;
  };
  return (
    <div>
      <span className="tab-title">{item.label}</span>
      <span
        className={classNames("tab-count", {
          "tab-count-active": selectedTab === item.name,
        })}
      >
        {getValue(item.name)}
      </span>
    </div>
  );
};
export default TabTitle;
