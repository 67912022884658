import React, { useEffect, useState } from 'react'
import { AttachmentsButton } from 'components/form'
import CommentEditor from './commentEditor'
import { useDispatch } from 'react-redux'
import { DeleteComment } from 'store/actions/comment'
import { Message, MessageBox } from 'element-react'
const CommentBody = (props) => {
  const { comment, article } = props
  const dispatch = useDispatch()
  useEffect(() => {
    setState({ ...state, comment })
  }, [comment])

  const [state, setState] = useState({ isEdit: false, comment: null })
  const handleSaveClicked = (obj) => {
    setState({
      ...state,
      isEdit: false,
      comment: { ...state.comment, Comment: obj.Comment },
    })
    props.onSaved && props.onSaved()
  }

  const handleDelete = async (obj: any) => {
    MessageBox.confirm(
      'This will permanently delete the comment. Continue?',
      'Warning',
      {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      },
    )
      .then(async () => {
        const res: any = await dispatch(DeleteComment(obj?.Id))
        if (res.Status) props.onDelete && props.onDelete()
      })
      .catch(() => {
        Message({
          type: 'info',
          message: 'Delete canceled',
        })
      })
  }
  return (
    <>
      {!state.isEdit ? (
        <>
          <div className="comment-body ck-editor-content">
            <a href={`/uploads/${comment.ServerFileName}`} target="_blank">
              {state?.comment?.UserFileName}
            </a>
            <div
              dangerouslySetInnerHTML={{ __html: state?.comment?.Comment }}
            />
            <div className="comment-body-attachments">
              <AttachmentsButton
                isPreview
                value={state?.comment?.Attachments}
                title="Attachments"
              />
            </div>
          </div>
          <div className="editdelete">
            {article?.CanEdit && (
              <span onClick={() => setState({ ...state, isEdit: true })}>
                Edit
              </span>
            )}
            {article?.CanDelete && (
              <span onClick={() => handleDelete(state?.comment)}> Delete </span>
            )}
          </div>
        </>
      ) : (
        <CommentEditor
          onSaveClicked={handleSaveClicked}
          editData={state?.comment}
          onCancel={() => setState({ ...state, isEdit: false })}
        />
      )}
    </>
  )
}
export default CommentBody
