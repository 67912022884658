import React, { useRef, useState } from "react";
import { Form, Input, Button, Select, Dialog, Message } from "element-react";
import { changePassword } from "store/actions/user";
import { useDispatch } from "react-redux";

const ChangeUserPasswordDailog = (props) => {
  const { user, isVisible, setVisible } = props;
  const ref: any = useRef();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    isLoading: false,
  });

  const rules = {
    newPassword: [
      {
        required: true,
        message: "New password is required",
        trigger: "blur",
      },
    ],
  };

  const handleChangePassword = async () => {
    ref.current.validate(async (valid) => {
      if (valid) {
        var res: any = await dispatch(
          changePassword({
            currentPassword: state.currentPassword,
            newPassword: state.newPassword,
            confirmNewPassword: state.confirmNewPassword,
          })
        );
        if (res && res.Status) reset();
      }
    });
  };

  const reset = () => {
    ref.current.resetFields();
    setState({
      newPassword: "",
      currentPassword: "",
      confirmNewPassword: "",
      isLoading: false,
    });
    setVisible(false);
  };
  return (
    <Dialog
      title="Change Password"
      visible={isVisible}
      onCancel={() => reset()}
      style={{ width: "350px" }}
    >
      <Dialog.Body>
        <Form ref={ref} rules={rules}>
          <Form.Item>
            <Input
              name="currentPassword"
              type="password"
              value={state.currentPassword}
              placeholder="Old Pasword"
              onChange={(val: any) =>
                setState({ ...state, currentPassword: val })
              }
            ></Input>
          </Form.Item>
          <Form.Item>
            <Input
              name="newPassword"
              type="password"
              value={state.newPassword}
              placeholder="New Pasword"
              onChange={(val: any) => setState({ ...state, newPassword: val })}
            ></Input>
          </Form.Item>
          <Form.Item>
            <Input
              name="confirmNewPassword"
              type="password"
              value={state.confirmNewPassword}
              placeholder="New Pasword"
              onChange={(val: any) =>
                setState({ ...state, confirmNewPassword: val })
              }
            ></Input>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => handleChangePassword()}
              loading={state.isLoading}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Dialog.Body>
    </Dialog>
  );
};

export default ChangeUserPasswordDailog;
