import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Dialog,
  Message,
  MessageBox,
  Breadcrumb,
  Tabs,
} from "element-react";
import RouteEnum from "constants/routesEnum";
import ArticleStatusEnum from "constants/articleStatusEnum";
import Start from "./components/start";
import Authors from "./components/author";
import Attachments from "./components/attachment";
import MetaInfo from "./components/metaInfo";
import { GetArticle, clearArticle } from "store/actions/articles";
import { UserDetailInterface } from "interfaces/actions/auth";
import RoleEnum from "constants/roleEnum";

const ViewArticle = (props) => {
  const { articleId } = props.match.params;

  const dispatch = useDispatch();
  const { RoleName } = useSelector(
    (state: any) => state.userReducer.loggedInUserDetail as UserDetailInterface
  );

  useEffect(() => {
    if (articleId) {
      fetchArticle(articleId);
    } else {
      dispatch(clearArticle());
    }
  }, [articleId]);

  const fetchArticle = async (articleId) => {
    await dispatch(GetArticle(articleId));
  };

  return (
    <div>
      <Breadcrumb separator="/">
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>View</Breadcrumb.Item>
      </Breadcrumb>

      <div className="viewArticle">
        <Start />
        <MetaInfo />
        <Authors />
        <Attachments />
      </div>
    </div>
  );
};

export default ViewArticle;
