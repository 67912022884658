import React, { useEffect, useState } from "react";
import { Button, Dialog, Progress } from "element-react";
import Loader from "components/loader.tsx";
import { Wrapper } from "components/form";
import Attachments from "scenes/common/attachments";
import { createMarkup, isEmpty } from "helpers";
import { FaUserPlus, FaTrash } from "react-icons/fa";
import ReviewStatusEnum from "constants/reviewStatusEnum";
import classNames from "classnames";
import ReviewCard from "./reviewCard";

const ReviewDetail = ({ review, dispatch, showAddReviewer, onSuccess }) => {
  const [state, setState] = useState({
    isLoading: false,
    review: null,
  });
  const handleOnReviewerDelete = (reviewerId) => {
    onSuccess && onSuccess();
  };

  return (
    <Loader isLoading={state.isLoading}>
      <div className="reviewdetail-header">
        <h4>{review && review.Title}</h4>
        <Button size="mini" type="primary" onClick={() => showAddReviewer()}>
          <FaUserPlus />
          Add Reviewer
        </Button>
      </div>
      {review && (
        <div>
          {review.Reviews.length === 0 ? (
            <div>No reveiw request has been requested yet.</div>
          ) : (
            review.Reviews.map((x, index) => (
              <ReviewCard
                key={index}
                review={x}
                handleOnDelete={handleOnReviewerDelete}
              />
            ))
          )}
        </div>
      )}
    </Loader>
  );
};

export default ReviewDetail;
