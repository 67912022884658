import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  Input,
  Radio,
  Message,
  DatePicker,
} from "element-react";
import { FaPlus, FaMinus, FaUserPlus } from "react-icons/fa";
import TableView from "components/tableView";
import { userWithAssignedInfo } from "store/actions/articles";
import { useDispatch } from "react-redux";
import RoleEnum from "constants/roleEnum";
import { isEmpty } from "helpers";

const AssignUserButton = (props) => {
  const { title, onAssignClick } = props;

  return (
    <Button size="mini" onClick={onAssignClick}>
      <FaUserPlus />
      {isEmpty(title) ? "Assign" : title}
    </Button>
  );
};

export default AssignUserButton;
