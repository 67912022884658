import { post, get, api } from "networkService";
import types from "store/types";

export const GetTemplates = () => async (dispatch: Function) => {
  const res: any = await get(api.meta.templates, dispatch);
  dispatch({ type: types.GET_TEMPLATES, payload: res });
};

export const GetCheckList = (sectionId: any) => async (dispatch: Function) => {
  const res: any = await get(`${api.meta.checkList}/${sectionId}`, dispatch);
  dispatch({ type: types.GET_CHECKLIST, payload: res });
};

export const GetSection = () => async (dispatch: Function) => {
  const res: any = await get(api.meta.sections, dispatch);
  dispatch({ type: types.GET_SECTIONS, payload: res });
};

export const GetSubSection = () => async (dispatch: Function) => {
  const res: any = await get(api.meta.subSections, dispatch);
  dispatch({ type: types.GET_SUBSECTIONS, payload: res });
};
export const GetComponents = () => async (dispatch: Function) => {
  const res: any = await get(api.meta.components, dispatch);
  dispatch({ type: types.GET_COMPONENTS, payload: res });
};
export const GetRecommendations = () => async (dispatch: Function) => {
  const res: any = await get(api.meta.recommendations, dispatch);
  dispatch({ type: types.GET_RECOMMENDATIONS, payload: res });
};

export const GetSalutations = () => async (dispatch: Function) => {
  const res: any = await get(api.meta.salutations, dispatch);
  if (res && res.Status)
    dispatch({ type: types.GET_SALUTATIONS, payload: res.Data });
};
//recommendations
