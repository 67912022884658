import React, { useState } from "react";
import { Button } from "element-react";
import { useDispatch, useSelector } from "react-redux";
import { SaveConfrimation } from "store/actions/articles";
import ArticleStatusEnum from "constants/articleStatusEnum";

const SubmitArticle = (props) => {
  const { onConfirmation, revisionId } = props;
  const { article } = useSelector((st: any) => st.articleReducer);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const onSaveClicked = async () => {
    var param = {
      articleId: article.Id,
      revisionId: parseInt(revisionId),
      steps: 5,
    };

    setLoading(true);
    var res: any = await dispatch(SaveConfrimation(param));
    setLoading(false);
    if (res && res.Status) onConfirmation();
  };
  const checkIfDisabled = () => {
    if (article.Id != 0 && !article.CanEdit) return true;
    return article.Status === ArticleStatusEnum.SUBMITTED;
  };
  return (
    <div>
      <div className="upload-steps__info">
        Your submission has been uploaded and is ready to be sent. You may go
        back to review and adjust any of the information you have entered before
        continuing. When you are ready, click "Finish Submission"
        {revisionId}
      </div>
      <div>
        <Button
          disabled={checkIfDisabled()}
          type="primary"
          onClick={() => onSaveClicked()}
          loading={isLoading}
        >
          Finish Submission
        </Button>
      </div>
    </div>
  );
};

export default SubmitArticle;
