import React, { useState } from "react";
import { Badge, Button, Dialog, Progress } from "element-react";
import { MdOutlineChat, MdPreview } from "react-icons/md";
import Comments from "./index";

const CommentButton = (props) => {
  const { articleId, article } = props;
  const [state, setState] = useState({ show: false });
  return (
    <>
      <span
        className="comment"
        onClick={() => setState({ ...state, show: true })}
      >
        <Badge value={article?.TotalComments || 0}>
          <MdOutlineChat />
        </Badge>
      </span>

      {state.show && (
        <Dialog
          lockScroll
          top="5%"
          // customClass="comment-modal"
          title="Comments"
          visible={state.show}
          onCancel={() => setState({ ...state, show: false })}
        >
          <Dialog.Body>
            <Comments articleId={articleId} article={article} />
          </Dialog.Body>
        </Dialog>
      )}
    </>
  );
};

export default CommentButton;
