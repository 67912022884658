import React from "react";
import { Table, Pagination } from "element-react";
interface TableProps {
  showNoData: boolean;
  width?: any;
  columns: any[];
  data: any[];
  totalData?: number;
  PageIndex?: number;
  pageSize?: number;
  handlePageSizeChanged?: (size?: number) => void;
  handlePageChanged?: (currentPage?: number) => void;
}

const TableView = (props: TableProps) => {
  const {
    columns,
    showNoData,
    data,
    totalData,
    pageSize,
    PageIndex,
    handlePageSizeChanged,
    handlePageChanged,
  } = props;

  return (
    <>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        data={data}
        border={true}
      />
      {totalData && totalData === 0 && (
        <div className="no-table-data">No Data</div>
      )}
      {totalData && totalData > 0 && (
        <Pagination
          layout="total, sizes, prev, pager, next"
          total={totalData}
          pageSizes={[10, 20, 50, 100]}
          pageSize={pageSize}
          currentPage={PageIndex}
          onSizeChange={handlePageSizeChanged}
          onCurrentChange={handlePageChanged}
        />
      )}
    </>
  );
};
TableView.defaultProps = {
  width: "100%",
  showNoData: true,
};
export default TableView;
