export enum ArticleStatusEnum {
  NEW = 1,
  SUBMITTED = 2,
  RESUBMITTED = 3,
  REVISION = 4,
  REJECTED = 5,
  APPROVED = 6,
  ASSIGNEDEDITOR = 7,
  // ASSIGNEDREVIEWER = 8,
  REVIEWING = 9,
  REVIEWED = 10,
  REVIEWDECLINED = 11,
  FINALAPPROVED = 12,
  FINALREJECTED = 13
}
export default ArticleStatusEnum;
