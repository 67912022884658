import React from "react";
const Wrapper = (props) => {
  const { label, children } = props;
  return (
    <div className="wrapper">
      {label && <div className="wrapper-label">{label}</div>}
      <div className="wrapper-control">{children}</div>
    </div>
  );
};

export default Wrapper;
