import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { CountArticle, GetArticles } from 'store/actions/articles'
import CardView from './cardView'
import SearchFilter from './searchFilter'

const Articles = ({ selectedTab, getStatus, actions }, ref) => {
  if (ref == null) return
  const [state, setState] = useState({
    status: 0,
    PageIndex: 1,
    pageSize: 20,
    propsalTitle: '',
    section: '',
    subSection: '',
  })
  useEffect(() => {
    var status = getStatus()
    fetchData({ ...state, status, selectedTab })
  }, [selectedTab])

  useImperativeHandle(ref, () => ({
    async loadData() {
      var status = getStatus()

      await fetchData({ ...state, status, selectedTab })
    },
  }))

  const dispatch = useDispatch()
  const history = useHistory()
  const [list, setList] = useState([])

  const fetchData = async (param) => {
    var ls: any = []
    countArticles(param)
    ls = await dispatch(GetArticles(param))
    setList(ls)
  }
  const countArticles = async (param) => {
    dispatch(CountArticle(param))
  }

  const handleSearchClicked = () => {
    var status = getStatus()
    let newState = { ...state, pageIndex: 1, pageSize: 20, status }
    setState(newState)
    fetchData(newState)
  }
  const handlePageSizeChanged = (size) => {
    let newState = { ...state, pageIndex: 1, pageSize: size, status: getStatus() }
    setState(newState)
    fetchData(newState)
  }
  const handlePageChanged = (page) => {
    let newState = { ...state, pageIndex: page, status: getStatus() }
    setState(newState)
    fetchData(newState)
  }

  let totalData = 0
  if (list && list.length > 0) {
    totalData = list[0].TotalData
  }
  return (
    <div className="submitter">
      <SearchFilter
        state={state}
        setState={setState}
        onSearchClicked={handleSearchClicked}
      />
      <CardView
        actions={actions}
        data={list}
        totalData={totalData}
        pageSize={state.pageSize}
        PageIndex={state.PageIndex}
        handlePageSizeChanged={handlePageSizeChanged}
        handlePageChanged={handlePageChanged}
      />
    </div>
  )
}

export default forwardRef(Articles)
