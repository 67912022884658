import Submisson from "scenes/submitter/submission";
import Revision from "scenes/submitter/revision";
import Submitter from "scenes/submitter";
import RoleEnum from "constants/roleEnum";
import RouteEnum from "constants/routesEnum";

const SubmitterRoutes = [
  {
    exact: true,
    component: Submitter,
    role: RoleEnum.Submitter,
    title: "Dashboard",
    path: RouteEnum.submitter,
  },
  {
    exact: true,
    component: Submisson,
    role: RoleEnum.Submitter,
    title: "Submission",
    path: RouteEnum.submission,
  },
  {
    exact: true,
    component: Submisson,
    role: RoleEnum.Submitter,
    title: "Submission",
    path: `${RouteEnum.submission}/:articleId`,
  },
  {
    exact: true,
    component: Submisson,
    role: RoleEnum.Submitter,
    title: "Revision",
    path: `${RouteEnum.revision}/:revisionId/:articleId`,
  },
  {
    exact: true,
    component: Revision,
    role: RoleEnum.Submitter,
    title: "Submission",
    path: `${RouteEnum.revisionDetail}/:revisionId`,
  },
];

export default SubmitterRoutes;
