export enum RouteEnum {
  base = "/",
  login = "/login",
  viewArticle = "/view-article",
  comments = "/comments",

  adminSubmission = "/admin/submission",
  user = "/admin/user",

  forgotPassword = "/reset-password",
  resetPassword = "/reset-password/send",
  signup = "/signup",
  verifyEmailAddress = "/email-verification",

  submitter = "/submitter",
  submission = "/submitter/submission",
  revision = "/submitter/revision",
  revisionDetail = "/submitter/revision-detail",
  editor = "/editor",
  editorUser = "/editor/user",

  reviewer = "/reviewer",
  review = "/reviewer/review",
}

export default RouteEnum;
