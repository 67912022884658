import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Layout, Table } from "element-react";
import { AttachmentButton } from "components/form";
import EntryForm from "./entryForm";
import { SaveAuthors } from "store/actions/articles";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "helpers";

const CoAuthorList = (props) => {
  //setCoAuthorData
  const { CoAuthors, onEditClicked, onDelelteClicked } = props;
  const columns = [
    { label: "Name", prop: "FullName", width: 180 },
    { label: "Salutation", prop: "Salutation", width: 180 },
    { label: "Designation", prop: "Designation", width: 180 },
    { label: "Institute", prop: "Institute", width: 180 },
    { label: "PostalAddress", prop: "PostalAddress", width: 180 },
    { label: "MobileNo", prop: "MobileNo", width: 180 },
    { label: "Email", prop: "Email", width: 180 },
    {
      label: "",
      width: 160,
      fixed: "right",
      render: (row, column, index) => {
        return (
          <span>
            <Button
              type="text"
              size="small"
              onClick={() => onEditClicked(index, row)}
            >
              Edit
            </Button>
            <Button
              type="text"
              size="small"
              onClick={() => onDelelteClicked(index, row)}
            >
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  return (
    CoAuthors &&
    CoAuthors.length > 0 && (
      <Table columns={columns} data={CoAuthors} fit={true} />
    )
  );
};
export default CoAuthorList;
