import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog } from "element-react";
import AssignReviewerForm from "./assignReviewerForm";
import ReviewDetail from "./reviewDetail";
import { useDispatch } from "react-redux";
import { GetReviewByArticleId } from "store/actions/articles";
import { FaUserPlus } from "react-icons/fa";

const AssignReviewer = (props) => {
  const [state, setState] = useState({
    name: "",
    isLoading: false,
    review: null,
    showReviewerForm: false,
  });
  const { row, isVisible, setVisible, articlesRef } = props;
  const dispatch = useDispatch();

  useEffect(() => {}, [row.ReviewId]);
  const handleCancel = () => {
    setVisible(false);
  };
  const onSuccess = () => {
    //setVisible(false);
    loadReview(row.Id);
  };

  useEffect(() => {
    row && row.Id && loadReview(row.Id);
  }, [row]);

  const loadReview = async (articleId) => {
    setState({ ...state, isLoading: true });
    var res = await dispatch(GetReviewByArticleId(articleId));
    setState({
      ...state,
      isLoading: false,
      review: res,
      showReviewerForm: false,
    });
  };

  return (
    <>
      <div>
        {state.showReviewerForm ? (
          <AssignReviewerForm
            {...props}
            onSuccess={onSuccess}
            onCancel={() => setState({ ...state, showReviewerForm: false })}
          />
        ) : (
          <ReviewDetail
            review={state.review}
            dispatch={dispatch}
            onSuccess={onSuccess}
            showAddReviewer={() =>
              setState({ ...state, showReviewerForm: true })
            }
          />
        )}
      </div>
    </>
  );
};

export default AssignReviewer;
