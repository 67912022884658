import React, { useEffect, useState } from "react";
import { FiFile } from "react-icons/fi";
import { Button } from "element-react";

function SumissionList({
  attachments,
  handleAttachmentEditClick,
  handleAttachmentDeleteClick,
  isRevision,
}) {
  const [state, setState] = useState({});
  useEffect(() => {
    let index = -1;
    var newAttachment = attachments.reduce((a, b) => {
      let key = `${b.ComponentId}`;
      if (!a) {
        a = {};
      }
      if (!a?.[key]) {
        a[key] = { items: [], componentName: b.ComponentName };
      }

      a[key].items.push({ ...b, index: ++index });

      return a;
    }, {});
    if (newAttachment) {
      setState(newAttachment);
    }
  }, [attachments]);

  return (
    <div className="upload-steps__submissions-body">
      {Object.keys(state || {}).map((key: any, idx: number) => {
        return (
          <div className="upload-steps__submissions-group">
            <div className="upload-steps__submissions-group-header">
              {state[key]?.componentName}.
            </div>
            {state[key]?.items?.map?.((attachment, index) => (
              <div className="upload-steps__attachment" key={`${idx}-${index}`}>
                <div className="upload-steps__attachment-info">
                  <div className="upload-steps__attachment-info-title">
                    <FiFile />
                    {attachment.UserFileName
                      ? attachment.UserFileName
                      : attachment.Attachment && attachment.Attachment.name}
                  </div>
                  <div className="upload-steps__attachment-info-subtitle">
                    Component: {attachment.ComponentName}
                  </div>
                  <div className="upload-steps__attachment-info-subtitle">
                    Description: {attachment.Description}
                  </div>
                </div>
                {/* {!isRevision && ( */}
                <div className="upload-steps__attachment-action">
                  <Button
                    type="text"
                    onClick={() =>
                      handleAttachmentEditClick(attachment.index)
                    }
                  >
                    Edit
                    {/* {`${idx}-${index}= +${attachment.index}`}.  */}
                  </Button>
                  <Button
                    type="text"
                    onClick={() =>
                      handleAttachmentDeleteClick(attachment.index)
                    }
                  >
                    Delete
                  </Button>
                </div>
                {/*  )} */}
              </div>
            ))}
          </div>
        );
      })}
      { }
    </div>
  );
}

export default SumissionList;
