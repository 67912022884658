import React, { useState, useRef, useEffect } from 'react'
import { Form, Input, Button, Layout, Dialog, Select } from 'element-react'
import { AttachmentButton } from 'components/form'
import { emailRule, getRequiredRules, isEmpty, mobileRules } from 'helpers'
import { useSelector } from 'react-redux'

const initialState = {
  FullName: '',
  Salutation: '',
  Designation: '',
  Institute: '',
  PostalAddress: '',
  MobileNo: '',
  Email: '',
  Photo_ServerFileName: '',
  Photo_UserFileName: '',
  Photo: null,
  DigitalSignature_ServerFileName: '',
  DigitalSignature_UserFileName: '',
  DigitalSignature: null,
}

const EntryForm = (props) => {
  const { coAuthorList, coAuthorData, onCancel, onSave } = props
  const formRef: any = useRef()
  const rules = {
    FullName: [getRequiredRules('Full name is required.')],
    Salutation: [getRequiredRules('Salutation is required.')],
    Designation: [getRequiredRules('Designation is required.')],
    Institute: [getRequiredRules('Institute is required.')],
    PostalAddress: [getRequiredRules('Address is required.')],
    Email: [emailRule()],
    // Photo_UserFileName: [getRequiredRules('Photo is required.')],
    //DigitalSignature_UserFileName: [getRequiredRules('Signature is required.')],
    MobileNo: [mobileRules()],
  }
  const [state, setState] = useState(initialState)
  const { salutations } = useSelector((st: any) => st.metaReducer)

  useEffect(() => {
    if (coAuthorData.index >= 0) {
      var obj = coAuthorList[coAuthorData.index]
      setState({ ...obj })
    } else {
      setState(initialState)
    }
  }, [coAuthorData])

  const onChange = (name, val) => {
    setState({ ...state, [name]: val })
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()

    formRef.current.validate(async (valid) => {
      if (valid) {
        const { index } = coAuthorData
        onSave && onSave(index, state)
      } else {
        return false
      }
    })
  }
  const handleReset = (e: any) => {
    formRef.current.resetFields()
    setState({} as any)
  }
  return (
    <div>
      <Dialog
        title="Co-Authors"
        visible={coAuthorData.isVisible}
        onCancel={() => onCancel()}
      >
        <Dialog.Body>
          <Form
            ref={formRef}
            rules={rules}
            className="demo-form-stacked"
            model={state}
            labelPosition="top"
            labelWidth="100"
          >
            <Layout.Row gutter="20">
              <Layout.Col span="12">
                <Form.Item label="Salutation" prop="Salutation">
                  <Select
                    value={state.Salutation}
                    placeholder="Salutation"
                    onChange={(val: any) => onChange('Salutation', val)}
                  >
                    {salutations &&
                      salutations.map((item, index) => (
                        <Select.Option
                          key={index}
                          label={item}
                          value={item}
                        ></Select.Option>
                      ))}
                  </Select>
                  {/* <Input
                    value={state.Salutation}
                    onChange={(val: any) => onChange("Salutation", val)}
                  ></Input> */}
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
              <Layout.Col span="12">
                <Form.Item label="Full Name" prop="FullName">
                  <Input
                    value={state.FullName}
                    onChange={(val: any) => onChange('FullName', val)}
                  ></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col span="12">
                <Form.Item label="Email" prop="Email">
                  <Input
                    value={state.Email}
                    onChange={(val: any) => onChange('Email', val)}
                  ></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
              <Layout.Col span="12">
                <Form.Item label="Mobile" prop="MobileNo">
                  <Input
                    value={state.MobileNo}
                    onChange={(val: any) => onChange('MobileNo', val)}
                  ></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col span="12">
                <Form.Item label="Address" prop="PostalAddress">
                  <Input
                    value={state.PostalAddress}
                    onChange={(val: any) => onChange('PostalAddress', val)}
                  ></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
              <Layout.Col span="12">
                <Form.Item label="Institute" prop="Institute">
                  <Input
                    value={state.Institute}
                    onChange={(val: any) => onChange('Institute', val)}
                  ></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col span="12">
                <Form.Item label="Designation" prop="Designation">
                  <Input
                    value={state.Designation}
                    onChange={(val: any) => onChange('Designation', val)}
                  ></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>

            <Layout.Row>
              <Layout.Col span="12">
                <Form.Item prop="Photo_UserFileName">
                  <AttachmentButton
                    label="Photo"
                    value={state.Photo}
                    name="Photo"
                    onChange={onChange}
                    validExtensions={['jpg', 'jpeg', 'png']}
                  />
                </Form.Item>
              </Layout.Col>
              <Layout.Col span="12">
                <Form.Item prop="Photo_UserFileName">
                  <AttachmentButton
                    label="Digital Signature"
                    value={state.DigitalSignature}
                    name="DigitalSignature"
                    onChange={onChange}
                    validExtensions={['jpg', 'jpeg', 'png']}
                  />
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item>
              <Button type="primary" onClick={handleSubmit} nativeType="submit">
                Save
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </Form.Item>
          </Form>
        </Dialog.Body>
      </Dialog>
    </div>
  )
}

export default EntryForm
