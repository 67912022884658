import React from "react";
import { Menu, Layout as ELayout } from "element-react";
import { Link } from "react-router-dom";
import RouteEnum from "constants/routesEnum";

const AdminSideBar = (props) => {
  const onOpen = () => {};
  const onClose = () => {};

  return (
    <div className="admin-side-bar">
      <Menu
        defaultActive="2"
        className="el-menu-vertical-demo"
        onOpen={onOpen}
        onClose={onClose}
      >
        <Menu.SubMenu
          index="1"
          title={
            <span>
              <i className="el-icon-caret-right"></i>User Module
            </span>
          }
        >
          <Menu.Item index="1-1">
            <Link to={RouteEnum.user}> User List</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item index="2">
          <i className="el-icon-menu"></i>{" "}
          <Link to={RouteEnum.adminSubmission}> Submission</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default AdminSideBar;
