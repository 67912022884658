import React, { useEffect } from "react";
import queryString from "query-string";
import { verifyUserEmailAddress } from "store/actions/common";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import RoutesEnum from "constants/routesEnum";

const VerifyEmailAddress = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    verify();
  }, []);
  const verify = async () => {
    const params = queryString.parse(props.location.search);
    var res = await dispatch(verifyUserEmailAddress({ param: params.param }));
    if (res) history.push(RoutesEnum.login);
  };
  return (
    <div>
      Your email address will be verified and after success redirects to login
      page.
    </div>
  );
};

export default VerifyEmailAddress;
