import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Select } from 'element-react'
import { CheckList, Editor } from 'components/form'
import { SaveStart } from 'store/actions/articles'
import { getRequiredRules, isEmpty } from 'helpers'
import ArticleStatusEnum from 'constants/articleStatusEnum'
import TemplateEnum from 'constants/templateEnum'
import { GetCheckList } from 'store/actions/meta'
import Loader from 'components/loader.tsx'

const Start = (props) => {
  var ref: any = useRef()
  const { onSave, selectedTab } = props
  const [isLoadingCheckList, setLoadingCheckList] = useState(false)
  const [state, setState] = useState({
    ArticleId: 0,
    SectionId: 0,
    SubSectionId: 0,
    CommentsForEditor: '',
    CheckList: [],
    SubSections: [],
  })
  const [isLoading, setLoading] = useState(false)
  const rules = {
    SectionId: [getRequiredRules('Section is required.', 'number')],
    CheckList: [getRequiredRules('Selecte at least one check list.', 'array')], // [isEmptyRules("")],
  }
  const dispatch = useDispatch()

  const { checkList, templates, sections, subSections } = useSelector(
    (state: any) => state.metaReducer,
  )
  const { article } = useSelector((st: any) => st.articleReducer)

  useEffect(() => {
    let obj = {
      ArticleId: 0,
      SectionId: null,
      SubSectionId: null,
      CommentsForEditor: '',
      CheckList: [],
      SubSections: [],
    }
    if (!isEmpty(article)) {
      loadCheckList(article.SectionId)
      obj = {
        ArticleId: article.Id,
        SectionId: article.SectionId,
        SubSectionId: article.SubSectionId,
        CommentsForEditor: article.CommentsForEditor,
        SubSections: getSubSections(article.SectionId),
        CheckList:
          (article.CheckList &&
            article.CheckList.map((x) => ({
              Id: x.CheckListId,
              Name: x.CheckListName,
            }))) ||
          [],
      }
    }

    setState(obj)
  }, [article, selectedTab])

  const getSubSections = (sectionId) =>
    subSections.filter((x) => x.SectionId === sectionId) || []

  const onChange = async (key: any, value: any) => {
    let newState = { ...state }
    newState[key] = value
    if (key === 'SectionId') {
      newState.SubSections = getSubSections(value)
      loadCheckList(value)
    }
    setState(newState)
  }

  const loadCheckList = async (sectionId: any) => {
    setLoadingCheckList(true)
    await dispatch(GetCheckList(sectionId))
    setLoadingCheckList(false)
  }
  const copyrightStatements = templates.find(
    (x) => x.TemplateKey === TemplateEnum.CopyrightStatements,
  )

  const privacyStatements = templates.find(
    (x) => x.TemplateKey === TemplateEnum.PrivacyStatements,
  )

  const handleNextClick = async () => {
    ref.current.validate(async (valid) => {
      if (valid) {
        setLoading(true)
        const param = {
          SectionId: state.SectionId,
          SubSectionId: state.SubSectionId,
          CheckList: state.CheckList,
          Comments: state.CommentsForEditor,
          ArticleId: state.ArticleId,
          Steps: 1,
        }

        var res: any = await dispatch(SaveStart(param))
        setLoading(false)
        if (res && res.Status) {
          onSave && onSave()
        }
      } else {
        return false
      }
    })
  }

  const checkIfDisabled = () => {
    let id = article.Id || 0
    if (id != 0 && !article.CanEdit) return true
    return article.Status === ArticleStatusEnum.SUBMITTED
  }

  return (
    <div className="user-form">
      <Form
        ref={ref}
        rules={rules}
        className="demo-form-stacked"
        model={state}
        labelPosition="top"
        labelWidth="100"
      >
        <Form.Item
          label={copyrightStatements && copyrightStatements.TemplateTitle}
        >
          <div>{copyrightStatements && copyrightStatements.TemplateText}</div>
        </Form.Item>
        <Form.Item label="Type of Submission" prop="SectionId">
          <Select
            value={state.SectionId}
            placeholder="Type of Submission"
            onChange={(val: any) => onChange('SectionId', val)}
          >
            {sections &&
              sections.map((role, index) => (
                <Select.Option
                  key={index}
                  label={role.Name}
                  value={role.Id}
                ></Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Sub Type of Submission" prop="SubSectionId">
          <Select
            value={state.SubSectionId}
            placeholder="Sub Type of Submission"
            onChange={(val: any) => onChange('SubSectionId', val)}
          >
            {state.SubSections &&
              state.SubSections.map((obj, index) => (
                <Select.Option
                  key={index}
                  label={obj.Name}
                  value={obj.Id}
                ></Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="CheckList" prop="CheckList">
          <Loader isLoading={isLoadingCheckList}>
            <CheckList
              list={checkList}
              value={state.CheckList}
              onChange={(val: any) => onChange('CheckList', val)}
            />
          </Loader>
        </Form.Item>
        <Form.Item label="Comments for the Editor" prop="CommentsForEditor">
          <Editor
            name="CommentsForEditor"
            onChange={onChange}
            value={state.CommentsForEditor}
          />
        </Form.Item>
        <Form.Item
          label={privacyStatements && privacyStatements.TemplateTitle}
          prop="privacyStatements"
        >
          <div>{privacyStatements && privacyStatements.TemplateText}</div>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={handleNextClick}
            loading={isLoading}
            disabled={checkIfDisabled()}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default Start
