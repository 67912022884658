import React, { useState } from "react";
import { Dialog, Button, Input, Form } from "element-react";
import {
  FaPlus,
  FaMinus,
  FaUserPlus,
  FaCheck,
  FaUndo,
  FaBan,
} from "react-icons/fa";
import TableView from "components/tableView";
import ArticleStatusEnum from "constants/articleStatusEnum";
import { useDispatch, useSelector } from "react-redux";

const ActionConfirmation = ({ row, status, title, handleSave }) => {
  const [state, setState] = useState({ remarks: "" });
  const { messages } = useSelector((state: any) => state.validateReducer);
  const [isVisible, setVisible] = useState(false);

  const onClick = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setState({ remarks: "" });
    setVisible(false);
  };

  const getTitleFor = () => {
    return `Are you sure, you want to ${title.toLowerCase()} this article?`;
  };
  const getIcon = () => {
    if (status === ArticleStatusEnum.APPROVED) return <FaCheck />;
    if (status === ArticleStatusEnum.REVISION) return <FaUndo />;
    if (status === ArticleStatusEnum.REJECTED) return <FaBan />;
  };

  const onSave = async (e) => {
    var param = { status, articleId: row.Id, remarks: state.remarks };
    await handleSave(param);
    closeModal();
  };
  return (
    <>
      <Button size="mini" onClick={() => onClick()}>
        {getIcon()}
        {title}
      </Button>
      {isVisible && (
        <Dialog
          title={getTitleFor()}
          visible={isVisible}
          onCancel={closeModal}
          style={{ width: "500px" }}
        >
          <Dialog.Body>
            <Form>
              <Form.Item label="Remarks">
                <Input
                  type="textarea"
                  placeholder="Remarks"
                  value={state.remarks}
                  onChange={(val: any) => setState({ ...state, remarks: val })}
                ></Input>
              </Form.Item>
              <div className="flex-end">
                <Button type="primary" onClick={onSave}>
                  Save
                </Button>
              </div>
            </Form>
          </Dialog.Body>
        </Dialog>
      )}
    </>
  );
};

export default ActionConfirmation;
