import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select, Checkbox } from "element-react";
import { CheckList } from "components/form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SubmitDownloadAndReview } from "store/actions/articles";
import { isEmpty } from "helpers";
import ArticleStatusEnum from "constants/articleStatusEnum";
import Attachments from "../attachments";
import UploadReviewFiles from "../uploadFiles";
import Loader from "components/loader.tsx";

const DownloadReview = (props) => {
  const { review } = useSelector((st: any) => st.articleReducer);
  const { onSave } = props;
  const dispatch = useDispatch();
  const { recommendations } = useSelector((state: any) => state.metaReducer);
  const [isLoading, setLoading] = useState(false);
  const [commentsForEditor, setCommentsForEditor] = useState("");
  const [commentsForEditorAndAuthor, setCommentsForEditorAndAuthor] =
    useState("");
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (!isEmpty(review)) {
      setCommentsForEditor(review.CommentsForEditor || "");
      setCommentsForEditorAndAuthor(review.CommentsForEditorAndAuthor || "");
      setState({ RecommendationId: review.RecommendationId });
      setAttachments(review.ReviewFiles || []);
    }
  }, [review]);

  const [state, setState] = useState({ RecommendationId: "" });
  const onChange = (name, val) => {
    setState({ ...state, [name]: val });
  };
  const onSaveClicked = async () => {
    var param = {
      reviewId: review.ReviewId,
      commentsForEditor,
      commentsForEditorAndAuthor,
      recommendationId: state.RecommendationId,
      files: attachments,
    };
    setLoading(true);
    var res: any = await dispatch(SubmitDownloadAndReview(param));
    setLoading(false);
    if (res && res.Status) onSave();
  };
  return (
    <Loader isLoading={isLoading}>
      <div className="user-form">
        <Form
          className="demo-form-stacked"
          labelPosition="top"
          labelWidth="100"
        >
          {review &&
            review.SubmissoinFiles &&
            review.SubmissoinFiles.length > 0 && (
              <div className="margin-bottom-10">
                <Attachments
                  files={review.SubmissoinFiles}
                  title="Submission Files"
                />
              </div>
            )}
          <Form.Item label="Comments for Author and editor">
            <CKEditor
              editor={ClassicEditor}
              data={commentsForEditorAndAuthor}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCommentsForEditorAndAuthor(data);
              }}
            />
          </Form.Item>
          <Form.Item label="Comment for Editor only">
            <CKEditor
              editor={ClassicEditor}
              data={commentsForEditor}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCommentsForEditor(data);
              }}
            />
          </Form.Item>
          <Form.Item label="Recommendation">
            <Select
              value={state.RecommendationId}
              placeholder="Recommendation"
              onChange={(val: any) => onChange("RecommendationId", val)}
            >
              {recommendations &&
                recommendations.map((role, index) => (
                  <Select.Option
                    key={index}
                    label={role.Name}
                    value={role.Id}
                  ></Select.Option>
                ))}
            </Select>
          </Form.Item>

          <UploadReviewFiles
            attachments={attachments}
            setAttachments={setAttachments}
            title="Review Attachments"
          />

          <Form.Item>
            <Button
              type="primary"
              onClick={onSaveClicked}
              loading={isLoading}
              disabled={review.ArticleStatus === ArticleStatusEnum.REVIEWED}
            >
              Save and Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Loader>
  );
};
export default DownloadReview;
