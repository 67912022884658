import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Dialog,
  Message,
  MessageBox,
  Breadcrumb,
  Tabs,
  Badge,
} from "element-react";
import Routes from "routes";
import { AddUserInterface } from "interfaces/user";
import { GetRoles } from "store/actions/common";
import { GetUserList, DeleteUser, ChangeUserStatus } from "store/actions/user";
import RouteEnum from "constants/routesEnum";
import ArticleStatusEnum from "constants/articleStatusEnum";
import SubmissionList from "./components/submissionList";
import TabTitle from "scenes/common/tabTitle";

const AdminLandingPage = () => {
  const [selectedTab, setSelectedTab] = useState("NEW");
  const tabs = [
    {
      label: "New Submission",
      name: "NEW",
    },
    {
      label: "On Going Review",
      name: "ONGOINGREVIEW",
    },
    {
      label: "Approved",
      name: "FINALAPPROVED",
    },
    {
      label: "Rejected",
      name: "FINALREJECTED",
    },
  ];

  const handleTabClicked = (e) => {
    const { name } = e.props;
    setSelectedTab(name);
  };

  return (
    <div>
      <Breadcrumb separator="/">
        <Breadcrumb.Item>
          <Link to={RouteEnum.adminSubmission}>Home</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="submission">
        <Tabs type="card" value={selectedTab} onTabClick={handleTabClicked}>
          {tabs.map((item, index) => (
            <Tabs.Pane
              key={index}
              label={
                <TabTitle item={item} selectedTab={selectedTab} count={24} />
              }
              name={item.name}
            ></Tabs.Pane>
          ))}
        </Tabs>

        {<SubmissionList selectedTab={selectedTab} />}
      </div>
    </div>
  );
};

export default AdminLandingPage;
