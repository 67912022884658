import React, { useEffect } from "react";

const useOutsideClick = (ref, setDrop, parentRef) => {
  function handleClickOutside(event) {
    if (parentRef) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!parentRef.current.contains(event.target)) {
          setDrop && setDrop();
        }
      }
    } else {
      if (ref.current && !ref.current.contains(event.target)) {
        setDrop && setDrop();
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export default useOutsideClick;
