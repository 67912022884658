import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import AssignUser from "./assignUser";
import { useDispatch } from "react-redux";
import { Button } from 'element-react'
import ApproveButton from "./approve-button";
import RejectButton from "./reject-button";
import ArticleStatusEnum from "constants/articleStatusEnum";
import { UpdateArticleStatus } from "store/actions/articles";
import RequestRevision from "../../editor/components/requestRevision";
import { FaTrash } from 'react-icons/fa'


const Actions = (props) => {
  const { row, selectedTab, articlesRef, handleDelete, } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const UpdateStatus = async (param) => {
    await dispatch(UpdateArticleStatus(param));
    articlesRef.current.loadData();
  };


  const getActions = () => {
    var actions = [];
    actions.push(<AssignUser {...props} dispatch={dispatch} />);
    actions.push(
      <ApproveButton
        row={row}
        handleSave={UpdateStatus}
        status={ArticleStatusEnum.FINALAPPROVED}
        title="Approve"
      />
    );
    actions.push(
      <RejectButton
        row={row}
        handleSave={UpdateStatus}
        status={ArticleStatusEnum.FINALREJECTED}
        title="Reject"
      />
    );

    if (row.Status === ArticleStatusEnum.SUBMITTED) {
      actions.push(
        <Button size="mini" onClick={() => handleDelete(row)}>
          <FaTrash />
          Delete
        </Button>,
      )
    }
    if (
      selectedTab === "NEW"
    )
      actions.push(
        <RequestRevision
          row={row}
          handleSave={UpdateStatus}
          status={ArticleStatusEnum.REVISION}
          title="Request Author for Revisions"
          dispatch={dispatch}
          loadData={() => articlesRef.current.loadData()}
        />
      );
    return actions;
  };

  //|| row.Status === ArticleStatusEnum.SUBMITTED

  return <>{getActions()}</>;
};

export default Actions;
