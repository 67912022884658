import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Notification } from "element-react";
import { clearMessage } from "store/actions/validateAction";

const Toast = (props) => {
  const { messages } = useSelector((state: any) => state.validateReducer);
  const dispatch = useDispatch();
  const timeout = 3000;

  const getType = (messageType) => {
    let type = messageType.toLowerCase();

    if (
      !(
        type === "success " ||
        type === "warning" ||
        type === "info" ||
        type === "error"
      )
    )
      type = "info";
    return type;
  };
  useEffect(() => {
    if (
      messages &&
      messages.message &&
      messages.message !== undefined &&
      messages.message.length > 0
    ) {
      if (Array.isArray(messages.message)) {
        messages.message.forEach((message: any) => {
          const type = getType(messages.type);

          Notification({
            message: message,
            type: type,
            duration: timeout,
            offset: 50,
          });

          setTimeout(() => {
            dispatch(clearMessage());
          }, timeout);
        });
      } else {
        let type = getType(messages.type);

        if (
          !(
            type === "success " ||
            type === "warning" ||
            type === "info" ||
            type === "error"
          )
        )
          type = "info";

        Notification({
          message: messages.message,
          type: type,
          duration: timeout,
          offset: 50,
        });

        setTimeout(() => {
          dispatch(clearMessage());
        }, timeout);
      }
    }
  }, [messages]);

  return <></>;
};
export default Toast;
