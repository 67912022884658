import Constant from "constants/index";
import { Form } from "element-react";
import { isEmpty } from "helpers";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Author = (props) => {
  const { checkList, templates, sections } = useSelector(
    (state: any) => state.metaReducer
  );
  const [authors, setAuthors] = useState([]);
  const { article } = useSelector((st: any) => st.articleReducer);
  useEffect(() => {
    if (!isEmpty(article)) {
      setAuthors([
        {
          Photo_ServerFileName: article.InvestigatorPhoto_ServerFileName,
          Salutation: article.InvestigatorSalutation,
          FullName: article.InvestigatorName,
          Designation: article.InvestigatorDesignation,
          Email: article.InvestigatorEmail,
          Institue: article.InvestigatorInstitute,
          PostalAddress: article.InvestigatorPostalAddress,
          MobileNo: article.InvestigatorMobileNo,
          DigitalSignature_ServerFileName:
            article.InvestigatorDigitalSignature_ServerFileName,
          isAuthor: true,
        },
        ...article.ArticleCoAuthors,
      ]);
    }
  }, article);

  const copyrightStatements = templates.find(
    (x) => x.TemplateKey === "copyrightStatements"
  );

  const privacyStatements = templates.find(
    (x) => x.TemplateKey === "privacyStatements"
  );
  if (isEmpty(article)) return null;
  return (
    <div className="viewArticle__author">
      <h1>Author</h1>
      <div className="viewArticle__coauthor">
        <CoAuthor ArticleCoAuthors={authors} />
      </div>
    </div>
  );
};

const CoAuthor = ({ ArticleCoAuthors }) => {
  return (
    <>
      <table>
        <tr className="co-auth">
          <th>Name</th>
          <th>Designation</th>
          <th>Email</th>
          <th>Institue</th>
          <th>Address</th>
          <th>Mobile</th>
          <th>Type</th>
          <th>Signature</th>
        </tr>
        {ArticleCoAuthors &&
          ArticleCoAuthors.map((x, index) => (
            <tr key={index}>
              <td>
                <div className="td-author-info">
                  {x.Photo_ServerFileName && (
                    <img
                      src={`${Constant.Uploads}/${x.Photo_ServerFileName}`}
                      width="40"
                      height="40"
                    />
                  )}
                  {`${x.Salutation} ${x.FullName}`}
                </div>
              </td>
              <td>{x.Designation}</td>
              <td>{x.Email}</td>
              <td>{x.Institute}</td>
              <td>{x.PostalAddress}</td>
              <td>{x.MobileNo}</td>
              <td>{x.isAuthor ? "Author" : "Co-author"}</td>
              <td>
                <img
                  src={`${Constant.Uploads}/${x.DigitalSignature_ServerFileName}`}
                  width="40"
                  height="40"
                />
              </td>
            </tr>
          ))}
      </table>
    </>
  );
};

export default Author;
