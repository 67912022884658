import EditorLanding from "scenes/editor";
import Submitter from "scenes/submitter";

import User from "scenes/Administrator/user";
import RoleEnum from "constants/roleEnum";
import RouteEnum from "constants/routesEnum";

const EditorRoutes = [
  {
    exact: true,
    component: User,
    role: RoleEnum.Editor,
    title: "User",
    path: RouteEnum.editorUser,
  },
  {
    exact: true,
    component: EditorLanding,
    role: RoleEnum.Editor,
    title: "Dashboard",
    path: RouteEnum.editor,
  },
];

export default EditorRoutes;
