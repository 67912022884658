import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Dialog,
  Message,
  MessageBox,
} from "element-react";
import Routes from "routes";
import { AddUserInterface } from "interfaces/user";
import { GetRoles } from "store/actions/common";
import { GetUserList, DeleteUser, ChangeUserStatus } from "store/actions/user";
import Search from "./search";
import AddUser from "./addUser";
import ChangeUserPassword from "./changeUserPassword";

const Index = () => {
  const [isVisible, setVisible] = useState(false);
  const [data, setData] = useState({
    Roles: [],
  });
  const [state, setState] = useState({
    userName: "",
    pageIndex: 1,
    pageSize: 20,
    isActive: "true",
  });
  useEffect(() => {
    fetchUserList(state);
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();
  const { userList } = useSelector((state: any) => state.userReducer);
  useEffect(() => {}, [userList]);

  const fetchUserList = async (param) => {
    await dispatch(GetUserList(param));
  };

  const handleSearchClicked = () => {
    let newState = { ...state, pageIndex: 1, pageSize: 20 };
    setState(newState);
    fetchUserList(newState);
  };
  const handlePageSizeChanged = (size) => {
    let newState = { ...state, pageSize: size };
    setState(newState);
    fetchUserList(newState);
  };
  const handlePageChanged = (page) => {
    let newState = { ...state, pageIndex: page };
    setState(newState);
    fetchUserList(newState);
  };
  const handleAddUser = (user) => {
    setData(user);
    setVisible(true);
  };
  const handleSaveSucees = () => {
    setVisible(false);
    fetchUserList(state);
  };
  const columns = [
    {
      label: "User Name/ Email",
      prop: "UserName",
      width: 300,
    },

    {
      label: "Name",
      prop: "FullName",
    },
    {
      label: "Mobile",
      prop: "MobileNumber",
      width: 120,
    },
    
    {
      label: "Role",
      prop: "RoleName",
      width: 120,
    },

    {
      label: "",
      width: 150,
      fixed: "right",
      render: (row, column, index) => {
        return <ChangeUserPassword user={row} key={index} />;
      },
    },
    {
      label: "",
      width: 100,
      fixed: "right",
      render: (row, column, index) => {
        return (
          <span>
            <Button
              type="text"
              size="small"
              onClick={() => changeUserStatus(row)}
            >
              {row.IsInactive ? "Enable" : "Disable"}
            </Button>
          </span>
        );
      },
    },
    {
      label: "",
      width: 160,
      fixed: "right",
      render: (row, column, index) => {
        return (
          <span>
            <Button type="text" size="small" onClick={() => handleAddUser(row)}>
              Edit
            </Button>
            <Button type="text" size="small" onClick={() => confirmDelete(row)}>
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  const confirmDelete = (user) => {
    MessageBox.confirm(
      "This will permanently delete the user. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(async () => {
        await dispatch(DeleteUser(user.Id));
        fetchUserList(state);
      })
      .catch(() => {});
  };
  const changeUserStatus = async (user) => {
    await dispatch(
      ChangeUserStatus({ userId: user.Id, isInactive: !user.IsInactive })
    );
    fetchUserList(state);
  };

  let totalData = 0;
  if (userList && userList.length > 0) {
    totalData = userList[0].TotalData;
  }

  return (
    <div className="user">
      <Search
        state={state}
        setState={setState}
        onSearchClicked={handleSearchClicked}
        onAddUserClicked={handleAddUser}
      />
      <Table
        style={{ width: "100%" }}
        columns={columns}
        data={userList}
        border={true}
      />

      <Pagination
        layout="total, sizes, prev, pager, next"
        total={totalData}
        pageSizes={[10, 20, 50, 100]}
        pageSize={state.pageSize}
        currentPage={state.pageIndex}
        onSizeChange={handlePageSizeChanged}
        onCurrentChange={handlePageChanged}
      />

      <UserDialog
        state={data}
        setState={setData}
        isVisible={isVisible}
        setVisible={setVisible}
        onSaveSuccess={handleSaveSucees}
      />
    </div>
  );
};

const UserDialog = (props) => {
  const { isVisible, setVisible } = props;
  return (
    <Dialog
      title="Add User"
      visible={isVisible}
      onCancel={() => {
        props?.setState({ ...props?.state, Roles: [] });
        setVisible(false);
      }}
    >
      <Dialog.Body>
        <AddUser {...props} />
      </Dialog.Body>
    </Dialog>
  );
};
export default Index;
