import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Button, Dialog } from "element-react";
import ActionConfirmation from "./actionConfirmation";
import ArticleStatusEnum from "constants/articleStatusEnum";
import AssignUserButton from "./assignUserButton";
import AssignReviewer from "./assignReviewer";
import { UpdateArticleStatus } from "store/actions/articles";
import { useDispatch } from "react-redux";
import RequestRevision from "./requestRevision";

const Actions = (props) => {
  const { row, selectedTab, articlesRef, loadData } = props;
  const [isVisible, setVisible] = useState(false);
  // const dispatch = useDispatch();

  const dispatch = useDispatch();
  const history = useHistory();
  const onAssignClick = () => {
    setVisible(true);
  };
  const updateArticleStatus = async (param) => {
    await dispatch(UpdateArticleStatus(param));
    articlesRef.current.loadData();
  };
  const getActions = () => {
    if (selectedTab === "REJECTED" || selectedTab === "APPROVED" ||selectedTab === "REVERTED" || selectedTab === "COMPLETED")
      return []

    let actions = [];
    actions.push(
      <ActionConfirmation
        row={row}
        handleSave={updateArticleStatus}
        status={ArticleStatusEnum.APPROVED}
        title="Approve"
      />
    );
    if (
      selectedTab === "NEW" ||
      selectedTab === "NEEDACTIONS" ||
      selectedTab === "UNDERREVIEW"
    )
      actions.push(
        <RequestRevision
          row={row}
          handleSave={updateArticleStatus}
          status={ArticleStatusEnum.REVISION}
          title="Request Author for Revisions"
          dispatch={dispatch}
          loadData={() => articlesRef.current.loadData()}
        />
      );

    actions.push(
      <ActionConfirmation
        row={row}
        handleSave={updateArticleStatus}
        status={ArticleStatusEnum.REJECTED}
        title="Reject"
      />
    );

    actions.push(
      <AssignUserButton
        title="Reviews"
        onAssignClick={onAssignClick}
      />
    );

    return actions;
  };

  return (
    <>
      {getActions()}

      {isVisible && (
        <Dialog
          closeOnClickModal={false}
          title="Review History"
          visible={isVisible}
          onCancel={() => setVisible(false)}
          style={{ width: "800px" }}
        >
          <Dialog.Body>
            <AssignReviewer
              row={row}
              isVisible={isVisible}
              setVisible={setVisible}
              articlesRef={articlesRef}
            />
          </Dialog.Body>
        </Dialog>
      )}
    </>
  );
};

export default Actions;
