import React from "react";
import { Form, Input, Button, Select, Dialog } from "element-react";
const Search = (props) => {
  const { state, setState, onSearchClicked, onAddUserClicked } = props;

  const onChangeSearch = (name, val) => {
    setState({ ...state, [name]: val });
  };
  return (
    <div className="user-search">
      <Form inline={true}>
        <Form.Item>
          <Input
            style={{ width: "300px" }}
            value={state.userName}
            placeholder="User name"
            onChange={(val: any) => onChangeSearch("userName", val)}
          ></Input>
        </Form.Item>
        <Form.Item>
          <Select
            value={state.isActive}
            placeholder="IsActive"
            onChange={(val: any) => onChangeSearch("IsActive", val)}
          >
            <Select.Option label="Active" value="true"></Select.Option>
            <Select.Option label="Inactive" value="false"></Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => onSearchClicked()}>
            Search
          </Button>
        </Form.Item>
      </Form>
      <div>
        <Button onClick={() => onAddUserClicked({})}>Add User</Button>
      </div>
    </div>
  );
};

export default Search;
