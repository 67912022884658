import { isEmpty } from "helpers";
import { post, put, get, deletion, api } from "networkService";
import types from "store/types";

export const GetCommenstList = (id) => async (dispatch: Function) => {
  const res: any = await get(`${api.articles.comment}/${id}`, dispatch);
  return res;
};
export const PostComment = (param) => async (dispatch: Function) => {
  let data = new FormData();
  for (var key in param) {
    if (key !== "Files") {
      data.append(key, param[key]);
    } else {
      for (var i = 0; i < param.Files.length; i++) {
        if (param.Files[i]?.file)
          data.append(`Files`, param.Files[i].file || null);
        else {
          let file: any = param.Files[i];
          for (var fileKey in file) {
            data.append(`Attachments[${i}][${fileKey}]`, file[fileKey]);
          }
        }
      }
    }
  }

  const res: any = await post(
    `${api.articles.comment}`,
    dispatch,
    data,
    true,
    true
  );

  return res;
};

export const DeleteComment = (id) => async (dispatch: Function) => {
  //(`${api.articles.comment}/${id}`, dispatch);
  const res: any = await deletion(`${api.articles.comment}`, dispatch, id);
  debugger;
  return res;
};
