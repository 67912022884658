import React, { useEffect, useRef, useState } from "react";
import { Input, Checkbox } from "element-react";
import TableView from "components/tableView";
import { userWithAssignedInfo } from "store/actions/articles";
import RoleEnum from "constants/roleEnum";
import Popover from "components/popup/_popover";

const ReviewerPopup = (props) => {
  const {
    popRef,
    setOpen,
    open,
    dispatch,
    name,
    onChange,
    row,
    selectedReviewer,
  } = props;
  const [state, setState] = useState({ name: "" });
  const [selected, setSelected] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [value, setValue] = useState([]);
  useEffect(() => {
    setValue(selectedReviewer);
  }, [selectedReviewer]);

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = async (fullname) => {
    var res: any = await dispatch(
      userWithAssignedInfo({
        articleId: row.Id,
        fullname: fullname,
        role: RoleEnum.Reviewer,
      })
    );
    //  res.map((x) => ({ ...x, label: x.FullName, value: x.Id }));
    //var index = res.findIndex((x) => x.IsAssigned);
    //setSelectedIndex(index);
    setTableData(res);
  };
  const onSelected = (row) => {
    const newValue = [...value];
    const index = newValue.findIndex((x) => x.Id === row.Id);
    if (index >= 0) newValue.splice(index, 1);
    else newValue.push(row);
    onChange && onChange(name, newValue);
  };
  const isChecked = (row) =>
    value && value.filter((x) => x.Id === row.Id).length > 0;

  const columns = [
    {
      label: "",
      prop: "",
      width: 60,
      render: (row, column, index) => {
        return (
          <div>
            <Checkbox
              checked={isChecked(row)}
              onChange={() => onSelected(row)}
            />
          </div>
        );
      },
    },
    {
      label: "Reviewer",
      prop: "FullName",
      render: (row, column, index) => (
        <div onClick={() => onSelected(row)}>{row.FullName}</div>
      ),
    },
    {
      label: "Done",
      prop: "Done",
      width: 120,
      render: (row, column, index) => (
        <div onClick={() => onSelected(row)}>{row.Done}</div>
      ),
    },
    {
      label: "Active",
      prop: "Active",
      width: 120,
      render: (row, column, index) => (
        <div onClick={() => onSelected(row)}>{row.Active}</div>
      ),
    },
  ];

  const onChangeName = (val) => {
    setState({ ...state, name: val });
    fetchData(val);
  };
  return (
    <Popover
      parentRef={popRef}
      open={open}
      handleOutsideClick={() => setOpen(!open)}
    >
      <div className="assign-popup">
        <div className="margin-bottom-10">
          <Input
            value={state.name}
            placeholder="Search Reviewer"
            onChange={(val: any) => onChangeName(val)}
          ></Input>
        </div>
        <div className="margin-bottom-10">
          <TableView showNoData={false} columns={columns} data={tableData} />
        </div>
      </div>
    </Popover>
  );
};
export default ReviewerPopup;
