import React, { useRef, useState } from "react";
import queryString from "query-string";
import {
  Form,
  Input,
  Button,
  Select,
  Dialog,
  Message,
  Layout,
} from "element-react";
import { resetPassword } from "store/actions/user";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import RoutesEnum from "constants/routesEnum";

const ForgotPassword = (props) => {
  const { user } = props;
  const ref: any = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = queryString.parse(props.location.search);

  const [state, setState] = useState({
    Email: "",
    Password: "",
    ConfirmPassword: "",
    isLoading: false,
    Code: params.code,
  });

  const rules = {
    Email: [
      {
        required: true,
        message: "Email is required",
        trigger: "blur",
      },
      {
        type: "email",
        message: "Please input correct email address",
        trigger: "blur,change",
      },
    ],
    Password: [
      { required: true, message: "Please input the password", trigger: "blur" },
      {
        validator: (rule, value, callback) => {
          if (value === "") {
            callback(new Error("Please input the password"));
          } else {
            if (state.Password !== "") {
              ref.current.validateField("ConfirmPassword");
            }
            callback();
          }
        },
      },
    ],
    ConfirmPassword: [
      {
        required: true,
        message: "Please input the password again",
        trigger: "blur",
      },
      {
        validator: (rule, value, callback) => {
          if (value === "") {
            callback(new Error("Please input the password again"));
          } else if (value !== state.Password) {
            callback(new Error("Two inputs don't match!"));
          } else {
            callback();
          }
        },
      },
    ],
  };
  const onChange = (name, val) => setState({ ...state, [name]: val });

  const handleSubmit = () => {
    ref.current.validate(async (valid) => {
      if (valid) {
        setState({ ...state, isLoading: true });
        const { Email, Code, Password, ConfirmPassword } = state;

        var res: any = await dispatch(
          resetPassword({ Email, Code, Password, ConfirmPassword })
        );
        setState({ ...state, isLoading: false });

        if (res) history.push(RoutesEnum.login);
      } else {
        return false;
      }
    });
  };

  return (
    <div className="container">
      <div className="sign-up-form">
        <Form
          ref={ref}
          rules={rules}
          className="demo-form-stacked"
          model={state}
          labelPosition="top"
          labelWidth="100"
        >
          <Layout.Row gutter="20">
            <Form.Item label="Email" prop="Email">
              <Input
                value={state.Email}
                onChange={(val: any) => onChange("Email", val)}
              ></Input>
            </Form.Item>
            <Form.Item label="Password" prop="Password">
              <Input
                type="password"
                value={state.Password}
                onChange={(val: any) => onChange("Password", val)}
              ></Input>
            </Form.Item>

            <Form.Item label="Confirm Password" prop="ConfirmPassword">
              <Input
                type="password"
                value={state.ConfirmPassword}
                onChange={(val: any) => onChange("ConfirmPassword", val)}
              ></Input>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={state.isLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Layout.Row>
        </Form>
      </div>
    </div>
  );
};
export default ForgotPassword;
