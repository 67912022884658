import SubmitterRoutes from "./submitterRoutes";
import PublicRoutes from "./publicRoutes";
import AdminRoutes from "./adminRoutes";
import EditorRoutes from "./editorRoutes";
import ReviewerRoutes from "./reviewerRoutes";

const routes = [
  ...PublicRoutes,
  ...SubmitterRoutes,
  ...AdminRoutes,
  ...EditorRoutes,
  ...ReviewerRoutes,
];
export default routes;
