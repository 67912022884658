import isEmpty from "./isEmpty";

export function createMarkup(html) {
  return { __html: html };
}
export const validateFile = (file, validExtensions) => {
  let extensions = validExtensions || [];

  if (extensions.find(x => x === "*"))
    return true
  if (extensions.length === 0) return true;
  var regex = new RegExp(extensions.join("|"), "g");
  // file && file.toLowerCase().match(/.(jpg|jpeg|png)$/i)
  var validateExt = (file && file.name.toLowerCase().match(regex)) || [];
  return validateExt.length > 0;
};

export const containsInvalidFiles = (files, validExtensions) => {
  let extensions = validExtensions || [];
  if (extensions.length === 0) return true;
  var regex = new RegExp(extensions.join("|"), "g");
  // file && file.toLowerCase().match(/.(jpg|jpeg|png)$/i)
  var invalideFiles = files.filter((x) => {
    var ls = x.name.toLowerCase().match(regex) || [];
    return ls.length <= 0;
  });
  //   (file && file.name.toLowerCase().match(regex)) || [];
  return invalideFiles.length > 0;
};
export const getRequiredRules = (message, type = "string") => ({
  required: true,
  message: message,
  trigger: "blur",
  type: type,
});
export const emailRule = () => ({
  type: "email",
  message: "Please input correct email address",
  trigger: "blur,change",
});

export const mobileRules = () => {
  // let reg = new Regex(/^[0-9]{10}$/);
  return {
    validator: (rule, value, callback) => {
      if (isEmpty(value)) callback();
      let isvalid = value && value.match(/^[0-9]{10}$/i);
      if (!isvalid) callback(new Error("Valid mobile number is required"));
      else callback();
    },
    trigger: "blur,change",
  };
};

export const emptyRules = (message) => {
  return {
    validator: (rule, value, callback) => {
      if (isEmpty(value)) {
        callback(new Error(message));
      } else {
        callback();
      }
    },
  };
};
