import { Form } from 'element-react'
import { isEmpty } from 'helpers'
import React from 'react'
import { useSelector } from 'react-redux'
const MetaInfo = (props) => {
  // const { templates } = useSelector((state: any) => state.metaReducer)

  const { article } = useSelector((st: any) => st.articleReducer)

  // const copyrightStatements = templates.find(
  //   (x) => x.TemplateKey === 'copyrightStatements',
  // )

  // const privacyStatements = templates.find(
  //   (x) => x.TemplateKey === 'privacyStatements',
  // )

  if (isEmpty(article)) return null
  return (
    <div className="viewArticle__meta">
      <Form className="demo-form-stacked" labelPosition="top" labelWidth="100">
        <Form.Item label="Title">
          <div
            className="ck-editor-content"
            dangerouslySetInnerHTML={{ __html: article.ProposalTitle }}
          />
        </Form.Item>
        <Form.Item label="Abstract">
          <div dangerouslySetInnerHTML={{ __html: article.ProposalAbstract }} />
        </Form.Item>
        {/* <Form.Item label="Methods">
          <div
            className="ck-editor-content"
            dangerouslySetInnerHTML={{ __html: article.PropsalMethods }}
          />
        </Form.Item>
        <Form.Item label="Cited References">
          <div
            className="ck-editor-content"
            dangerouslySetInnerHTML={{
              __html: article.ProposalCitedReferences,
            }}
          />
        </Form.Item> */}
      </Form>
    </div>
  )
}
export default MetaInfo
