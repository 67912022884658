import { AttachmentButton } from "components/form";
import { isEmpty } from "helpers";
import React from "react";
import { useSelector } from "react-redux";

const Attachments = (props) => {
  const { article } = useSelector((st: any) => st.articleReducer);
  const desStyle = {
    padding: "10px",
    backgroundColor: "#f1f1f1",
    margin: "5px 0px",
    borderRadius: "5px:"
  }
  if (isEmpty(article)) return null;
  return (
    <div className="viewArticle__attachment">
      <h1>Attachments</h1>
      <div>
        {article.ArticleAttachments.map((x, index) => (
          <div key={index}>
            <label>{x.ComponentName}</label>
            <div style={desStyle}>{x.Description}</div>
            <AttachmentButton
              isPreview
              value={{
                serverFileName: x.ServerFileName,
                name: x.UserFileName,
              }}
              name="Photo"
            />

          </div>
        ))}
      </div>
    </div>
  );
};
export default Attachments;
