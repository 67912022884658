import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, Select, Checkbox } from 'element-react'
import { CheckList, Editor } from 'components/form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { SaveMetaData } from 'store/actions/articles'
import { getRequiredRules, isEmpty } from 'helpers'
import ArticleStatusEnum from 'constants/articleStatusEnum'

const initialState = {
  prefix: '',
  introduction: '',
  methods: '',
  citedReference: '',
  abstract: '',
}

const MetaData = (props) => {
  const { article } = useSelector((st: any) => st.articleReducer)
  var ref: any = useRef()
  const [state, setState] = useState(initialState)
  useEffect(() => {
    if (!isEmpty(article)) {
      setState({
        prefix: article.ProposalTitle,
        introduction: article.ProposalIntroduction,
        methods: article.PropsalMethods,
        citedReference: article.ProposalCitedReferences,
        abstract: article.ProposalAbstract,
      })
      // handleChange("abstract", article.ProposalAbstract)
    }
  }, [article, props.selectedTab])
  const { onSave } = props
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)

  const rules = {
    prefix: [getRequiredRules('This field is required.')],
    // introduction: [getRequiredRules('Introduction is required')],
    // methods: [getRequiredRules('Methods is required')],
    // citedReference: [getRequiredRules('Cited reference is required')],
    abstract: [getRequiredRules('Abstract is required')],
  }

  const onSaveClicked = async () => {
    ref.current.validate(async (valid) => {
      if (valid) {
        var param = {
          ...state,
          articleId: article.Id,
          steps: 4,
        }
        setLoading(true)
        var res: any = await dispatch(SaveMetaData(param))
        setLoading(false)
        if (res && res.Status) onSave()
      }
    })
  }
  const handleChange = (name, value) => {
    setState({ ...state, [name]: value })
  }
  const checkIfDisabled = () => {
    if (article.Id != 0 && !article.CanEdit) return true
    return article.Status === ArticleStatusEnum.SUBMITTED
  }
  return (
    <div className="user-form">
      <Form
        className="demo-form-stacked"
        labelPosition="top"
        labelWidth="100"
        model={state}
        ref={ref}
        rules={rules}
      >
        <Form.Item label="Title" prop="prefix">
          <Input
            type="text"
            value={state.prefix}
            onChange={(val: any) => handleChange('prefix', val)}
          ></Input>
        </Form.Item>

        {/* <Form.Item label="Introduction" prop="introduction">
          <Editor
            name="introduction"
            onChange={handleChange}
            value={state.introduction}
          />
        </Form.Item>
        <Form.Item label="Methods" prop="methods">
          <Editor
            name="methods"
            onChange={handleChange}
            value={state.methods}
          />
        </Form.Item>
        <Form.Item label="Cited References" prop="citedReference">
          <Editor
            name="citedReference"
            onChange={handleChange}
            value={state.citedReference}
          />
        </Form.Item> */}

        <Form.Item label="Abstract" prop="abstract">
          <Editor
            name="abstract"
            onChange={handleChange}
            value={state.abstract}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={onSaveClicked}
            loading={isLoading}
            disabled={checkIfDisabled()}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default MetaData
