import RoleEnum from "constants/roleEnum";
import RouteEnum from "constants/routesEnum";
import { UserDetailInterface } from "interfaces/actions/auth";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

const BaseRoute = ({ component: Component, ...rest }) => {
  let role = localStorage.getItem("Role");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (role === RoleEnum.Submitter)
          return <Redirect to={RouteEnum.submitter} />;
        if (role === RoleEnum.Administrator)
          return <Redirect to={RouteEnum.adminSubmission} />;
        if (role === RoleEnum.Editor) return <Redirect to={RouteEnum.editor} />;
        if (role === RoleEnum.Reviewer)
          return <Redirect to={RouteEnum.reviewer} />;
        return <Redirect to="/login" />;
      }}
    />
  );
};
export default BaseRoute;
