import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CgAttachment, CgFile } from 'react-icons/cg'
import SvgIcons from 'components/icons/svgIcons'
import { FaTimes } from 'react-icons/fa'
import classnames from 'classnames'
import Constant from 'constants/index'
import checkFileExtension from 'helpers/checkFileExtension'
import { api } from 'networkService/api'
import ImageViewer from 'components/imageViewer'
import { isEmpty, validateFile } from 'helpers'

const AttachmentButton = (props) => {
  const fileRef: any = useRef()

  const {
    enableUpload,
    name,
    disabled,
    multiple,
    maxFiles,
    value,
    onChange,
    label,
    isPreview,
    validExtensions,
  } = props
  const [preview, setPreview] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (isEmpty(value)) {
      fileRef.current.value = ''
    }
  }, [value])

  const handleChange = (event) => {
    const { files } = event.target
    setError(false)
    const file = (files && files[0]) || null

    if (!validateFile(file, validExtensions)) {
      fileRef.current.value = ''
      setError(true)

      onChange && onChange(name, null)
    } else onChange && onChange(name, file)
  }
  const openFileOption = () => {
    if (isPreview) return
    fileRef.current.click()
  }
  const getDownloadLink = (file) => {
    const param = {
      serverFileName: file.serverFileName,
      name: file.name,
      FileType: file.FileType,
    }
    let url = `v1/${api.common.downloadFiles}/${
      file.serverFileName || file.ServerFileName
    }`
    return url
  }
  const getDiplayIcon = (file) => {
    const { isDocs, isImage, isPdf } = checkFileExtension(
      file.serverFileName || file.name,
    )
    //  if (isEmpty(file)) return "noif ilelel";

    let icon = isDocs ? (
      <SvgIcons name="document" size="20" />
    ) : isPdf ? (
      <SvgIcons name="pdf" size="20" />
    ) : isImage ? (
      isEmpty(file.serverFileName) && file ? (
        <img src={URL.createObjectURL(file)} width="20" height="20" />
      ) : (
        <img
          src={`${window.location.origin}/${Constant.Uploads}/${file.serverFileName}`}
          width="20"
          height="20"
        />
      )
    ) : (
      <CgFile />
    )
    return icon
  }
  return (
    <>
      {!isPreview && (
        <input
          ref={fileRef}
          type="file"
          style={{ display: 'none' }}
          multiple={multiple}
          name={name}
          disabled={disabled}
          onChange={(event) => handleChange && handleChange(event)}
        />
      )}
      <div className="upload-container">
        {label && <span className="upload-label">{label}</span>}

        {value && (
          <div
            className="upload-item"
            onClick={(e) => {
              const { isImage } = checkFileExtension(
                value.serverFileName ||
                  value.ServerFileName ||
                  value.name ||
                  value.UserFileName,
              )
              if (isImage)
                value &&
                  !isEmpty(value.serverFileName || value.ServerFileName) &&
                  setPreview(true)
              else {
                e.stopPropagation()
                window.open(getDownloadLink(value))
              }
            }}
          >
            {!isEmpty(value.name || value.UserFileName) && (
              <>
                <div className="upload-item-icon">{getDiplayIcon(value)}</div>
                <div className="upload-item-name">
                  {value.name || value.UserFileName}
                  {!isPreview && (
                    <div className="btn-remove">
                      <FaTimes
                        style={{ margin: '0px' }}
                        size="12"
                        color="#fff"
                        onClick={(e) => {
                          e.stopPropagation()
                          fileRef.current.value = ''
                          onChange && onChange(name, null)
                          // setSelectedIndex(index);
                          // setOpen(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {!isPreview && (
          <div className="upload-button" onClick={() => openFileOption()}>
            <div className="el-button">
              <CgAttachment />
              <span className="upload-info">{'Click to Upload'} </span>
            </div>
          </div>
        )}
      </div>
      {error && (
        <div className="upload-error">
          Invalid File. Supported files are {validExtensions?.toString()}
        </div>
      )}
      {preview && (
        <ImageViewer
          dowloadLinkUrl={getDownloadLink(value)}
          hostedUrl={`${window.location.origin}${Constant.Uploads}`}
          show={preview}
          title={value.name || value.serverFileName}
          image={value}
          onClose={setPreview}
        />
      )}
    </>
  )
}

AttachmentButton.defaultProps = { isPreview: false, enableUpload: true }

export default AttachmentButton
