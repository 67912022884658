import React, { useEffect, useRef, useState } from "react";
import { Dialog, Form, Input, Select, Button } from "element-react";
import { AttachmentButton } from "components/form";
import { useSelector } from "react-redux";

const initialState = {
  Attachment: null,
};

const UploadFile = (props) => {
  const { isVisible, onSave, onCancel, selectedFileData, title } = props;
  const [state, setState] = useState(initialState);
  const { components } = useSelector((state: any) => state.metaReducer);

  useEffect(() => {
    const data = selectedFileData.data;
    if (data) {
      setState({
        ...data,
        Attachment: data.Attachment || {
          serverFileName: data.ServerFileName,
          name: data.UserFileName,
        },
      });
    } else {
      setState(initialState);
    }
  }, [selectedFileData]);

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const handleSave = () => {
    let obj = {
      ...state,
    };
    onSave && onSave(selectedFileData.index, obj);
    setState(initialState);
  };
  return (
    <Dialog title={title} visible={isVisible} onCancel={onCancel}>
      <Dialog.Body>
        <Form model={state} labelPosition="top">
          <Form.Item label="Attachment">
            <AttachmentButton
              value={state.Attachment}
              name="Attachment"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={() => handleSave()}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Dialog.Body>
    </Dialog>
  );
};
export default UploadFile;
