import { isEmpty } from "helpers";
import { post, put, get, deletion, api } from "networkService";
import types from "store/types";

export const GetArticles = (param) => async (dispatch: Function) => {
  const res: any = await post(
    `${api.articles.article}`,
    dispatch,
    param,
    false
  );

  if (res && res.Status) return res.Data;

  return [];
};
export const CountArticle = (param) => async (dispatch: Function) => {
  const res: any = await post(api.articles.count, dispatch, param, false);
  if (!isEmpty(res))
    dispatch({ type: types.COUNT_ARTICLE, payload: res && res.Data });
};

export const GetArticle = (id) => async (dispatch: Function) => {
  const res: any = await get(`${api.articles.article}/${id}`, dispatch);
  if (!isEmpty(res))
    dispatch({ type: types.GET_ARTICLE, payload: res && res.Data });
  return res;
};

export const DeleteArticle = (id) => async (dispatch: Function) => {
  const res: any = await deletion(api.articles.article, dispatch, id);
  if (!isEmpty(res))
    dispatch({
      type: types.DELETE_ARTICLE,
      payload: { id, status: (res && res.Status) || false },
    });
  return res;
};

export const clearArticle = () => async (dispatch: Function) => {
  dispatch({ type: types.GET_ARTICLE, payload: {} });
};

export const SaveStart = (param) => async (dispatch: Function) => {
  const res: any = await post(api.articles.start, dispatch, param);
  if (!isEmpty(res))
    dispatch({ type: types.GET_ARTICLE, payload: res && res.Data });
  return res;
};

export const SaveAuthors = (param) => async (dispatch: Function) => {
  const res: any = await post(
    api.articles.authors,
    dispatch,
    param,
    true,
    true
  );
  if (!isEmpty(res))
    dispatch({ type: types.GET_ARTICLE, payload: res && res.Data });
  return res;
};

export const SaveAttachment = (param) => async (dispatch: Function) => {
  const res: any = await post(
    api.articles.attachments,
    dispatch,
    param,
    true,
    true
  );
  if (!isEmpty(res))
    dispatch({ type: types.GET_ARTICLE, payload: res && res.Data });
  return res;
};

export const SaveMetaData = (param) => async (dispatch: Function) => {
  const res: any = await post(
    api.articles.metadata,
    dispatch,
    param,
    true,
    false
  );
  if (!isEmpty(res))
    dispatch({ type: types.GET_ARTICLE, payload: res && res.Data });
  return res;
};

export const SaveConfrimation = (param) => async (dispatch: Function) => {
  const res: any = await post(
    api.articles.confirmation,
    dispatch,
    param,
    true,
    false
  );
  if (!isEmpty(res))
    dispatch({ type: types.GET_ARTICLE, payload: res && res.Data });
  return res;
};

export const AssignArticleForEditor = (param) => async (dispatch: Function) => {
  const res: any = await post(
    api.articles.assignArticleForEditor,
    dispatch,
    param,
    true
  );
  return res;
};

export const SaveReview = (param) => async (dispatch: Function) => {
  let val = isEmpty(param.ReviewId);
  const res: any = isEmpty(param.reviewId)
    ? await post(api.articles.review, dispatch, param, true)
    : await put(api.articles.review, dispatch, param, true);
  return res;
};

export const AssignToReviewers = (param) => async (dispatch: Function) => {
  const res: any = await post(
    api.articles.assigntToReviewer,
    dispatch,
    param,
    true
  );
  return res;
};

export const GetReviewDetails = (id) => async (dispatch: Function) => {
  const res: any = await get(`${api.articles.reviewDetail}/${id}`, dispatch);
  if (!isEmpty(res)) {
    dispatch({ type: types.GET_REVIEW, payload: res && res.Data });
    return res.Data;
  }
  return null;
};

export const GetReview = (id) => async (dispatch: Function) => {
  const res: any = await get(`${api.articles.review}/${id}`, dispatch);
  if (res && res.Status) return res.Data;
  return null;
};

export const GetReviewByArticleId = (id) => async (dispatch: Function) => {
  const res: any = await get(`${api.articles.reviewByArticle}/${id}`, dispatch);
  if (res && res.Status) return res.Data;
  return null;
};

export const AcknowledgeReview =
  (reviewId, status) => async (dispatch: Function) => {
    const res: any = await post(`${api.articles.acknowledgeReview}`, dispatch, {
      reviewId,
      status,
    });

    return res;
  };

export const SubmitDownloadAndReview =
  (param) => async (dispatch: Function) => {
    let data = new FormData();
    for (var key in param) {
      if (key !== "files") {
        data.append(key, param[key]);
      } else {
        for (var i = 0; i < param.files.length; i++) {
          var item = param.files[i];
          data.append(`Files`, item.Attachment || null);
          // data.append(`Files[${i}].FileFormat`, item.FileFormat);
          // data.append(`Files[${i}].FileType`, item.FileType);
          // data.append(`Files[${i}].ServerFileName`, item.ServerFileName);
          // data.append(`Files[${i}].Size`, item.Size);
          // data.append(`Files[${i}].UserFileName`, item.UserFileName);
        }
      }
    }

    const res: any = await post(
      `${api.articles.submitDownloadAndReview}`,
      dispatch,
      data,
      true,
      true
    );

    return res;
  };

export const SubmitReview = (reviewId) => async (dispatch: Function) => {
  const res: any = await post(
    `${api.articles.submitReview}/${reviewId}`,
    dispatch,
    {},
    true
  );

  return res;
};
export const userWithAssignedInfo = (param) => async (dipatch: Function) => {
  const res: any = await get(api.articles.assingedInfo, dipatch, param);
  if (res.Status) return res.Data;
  return [];
};

export const UpdateArticleStatus = (param) => async (dispatch: Function) => {
  const res: any = await post(api.articles.updateStatus, dispatch, param);
  return res;
};

export const RequestRevision = (param) => async (dispatch: Function) => {
  // const res: any = await post(api.articles.requestRevision, dispatch, param);
  // return res;

  let data = new FormData();
  for (var key in param) {
    if (key !== "Files") {
      data.append(key, param[key]);
    } else {
      for (var i = 0; i < param.Files.length; i++) {
        var item = param.Files[i];
        data.append(`Files`, item.Attachment || null);
      }
    }
  }
  const res: any = await post(
    `${api.articles.requestRevision}`,
    dispatch,
    data,
    true,
    true
  );

  return res;
};

export const GetRevision = (id) => async (dispatch: Function) => {
  const res: any = await get(`${api.articles.revision}/${id}`, dispatch);
  if (res && res.Status) return res.Data;
  return null;
};

export const DeleteReviewer = (userId) => async (dispatch: Function) => {
  const res: any = await deletion(api.articles.reviewer, dispatch, userId);
  if (res && res.Status) return res.Data;
  return null;
};
