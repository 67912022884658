import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Dropdown } from 'element-react'
import logo from '../../assets/logo.png'
import { useHistory } from 'react-router'
import { logout } from 'store/actions/auth'
import { GetLoggedInUserDetails, changePassword } from 'store/actions/user'
import {
  GetSection,
  GetTemplates,
  GetComponents,
  GetRecommendations,
  GetSubSection,
  GetSalutations,
} from 'store/actions/meta'
import { GetRoles } from 'store/actions/common'
import { UserDetailInterface } from 'interfaces/actions/auth'
import ChangePasswordDialog from './changePassword'
import { Link } from 'react-router-dom'
import RouteEnum from 'constants/routesEnum'
import Toast from 'components/toast'
import { isEmpty } from 'helpers'

export default function Header(props: any) {
  const history = useHistory()
  let authToken = localStorage.getItem('AuthToken')
  const dispatch = useDispatch()
  const [isChangePasswordVisible, setChangePasswordVisible] = useState(false)
  const [isAuth, setAuth] = useState(false)
  const { Email, FullName, UserName, RoleName } = useSelector(
    (state: any) => state.userReducer.loggedInUserDetail as UserDetailInterface,
  )
  var fileName: string = RoleName

  useEffect(() => {
    if (authToken) {
      loadData()
    } else {
      setAuth(false)
    }
    //userManual(RoleName)
  }, [authToken])

  const loadData = async () => {
    var res: any = await dispatch(GetLoggedInUserDetails())
    if (isEmpty(res.UserName)) {
      handleLogout()
      return
    }
    dispatch(GetSection())
    dispatch(GetSubSection())
    dispatch(GetTemplates())
    dispatch(GetComponents())
    dispatch(GetRecommendations())
    dispatch(GetSalutations())
    setAuth(true)
  }

  const handleCommand = (command) => {
    if (command === 'logout') {
      handleLogout()
    } else if (command === 'changePassword') {
      setChangePasswordVisible(true)
    } else if (command === 'download') {
      // userManual(RoleName);
    }
  }

  const handleLogout = () => {
    dispatch(logout({}, history))
  }
  const updatePassword = () => {}

  // const userManual = (role: string) => {
  //   if (role === "Submitter") {
  //     fileName = "Submitter.docx";
  //     download(fileName);
  //   } else if (role === "Editor") {
  //     fileName = "Editor.docx";
  //     download(fileName);
  //   } else if (role === "Reviewer") {
  //     fileName = "Reviewer.docx";
  //     download(fileName);
  //   } else {
  //     fileName = "Admin.docx";
  //     download(fileName);
  //   }
  // };

  // const download = (fileName: string) => {
  //   <a href={`/${fileName}`} download />;
  // };
  return (
    <>
      <Toast />
      <div className="app-header ">
        <div className="app-header-logo institute-name  ">
          <img src={logo} />
          <h2 className="">
            <Link to={RouteEnum.base}>
              B&B Institutional Review Committee - B&B IRC/Journal Portal
            </Link>{' '}
          </h2>
        </div>
        {isAuth && (
          <div className="app-header-drop">
            <Dropdown
              onCommand={handleCommand}
              menu={
                <Dropdown.Menu>
                  <Dropdown.Item command="changePassword">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item command="logout"> Log out</Dropdown.Item>
                  {/* <Dropdown.Item command="download">
                    Download {RoleName} Manual
                  </Dropdown.Item> */}
                  <Dropdown.Item>
                    <a href={`/${fileName}.docx`} download>
                      {`Download ${RoleName} Guide`}
                    </a>
                  </Dropdown.Item>
                </Dropdown.Menu>
              }
            >
              <div className="el-dropdown-link drop-title">
                <div className="drop-title-info">
                  <span className="drop-title-info-title">
                    Welcome {FullName}
                  </span>
                  <span className="drop-title-info-subtitle">
                    logged in as {RoleName}
                  </span>
                </div>
                <i className="el-icon-caret-bottom el-icon--right"></i>
              </div>
            </Dropdown>

            <ChangePasswordDialog
              isVisible={isChangePasswordVisible}
              setVisible={setChangePasswordVisible}
            />
          </div>
        )}
      </div>
    </>
  )
}
