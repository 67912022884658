import React, { useRef, useState } from "react";
import { Form, Input, Button, Select, Dialog, Message } from "element-react";
import { changePasswordByUser } from "store/actions/user";
import { useDispatch } from "react-redux";

const ChangeUserPassword = (props) => {
  const { user } = props;
  const ref: any = useRef();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    newPassword: "",
    isVisible: false,
    isLoading: false,
  });

  const rules = {
    newPassword: [
      {
        required: true,
        message: "New password is required",
        trigger: "blur",
      },
    ],
  };

  const handleChangePassword = async () => {
    ref.current.validate(async (valid) => {
      if (valid) {
        var res: any = await dispatch(
          changePasswordByUser({
            userName: user.UserName,
            newPassword: state.newPassword,
          })
        );
        if (res && res.Status) reset();
      }
    });
  };

  const reset = () => {
    ref.current.resetFields();
    setState({ newPassword: "", isLoading: false, isVisible: false });
  };
  return (
    <>
      <Button
        type="text"
        size="small"
        onClick={() => setState({ ...state, isVisible: true })}
      >
        Reset Password
      </Button>
      <Dialog
        title="Change Password"
        visible={state.isVisible}
        onCancel={() => reset()}
        style={{ width: "350px" }}
      >
        <Dialog.Body>
          <Form ref={ref} rules={rules}>
            <Form.Item>
              <Input
                name="newPassword"
                type="password"
                style={{ width: "270px" }}
                value={state.newPassword}
                placeholder="New Pasword"
                onChange={(val: any) =>
                  setState({ ...state, newPassword: val })
                }
              ></Input>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => handleChangePassword()}
                loading={state.isLoading}
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </Dialog.Body>
      </Dialog>
    </>
  );
};

export default ChangeUserPassword;
