import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import { Form, Input, Button, Select, Layout, Switch } from "element-react";
import { SaveUser, signUp } from "store/actions/user";
import routes from "constants/routesEnum";
import ReCAPTCHA from "react-google-recaptcha";

const initialState = {
  Roles: [],
  Email: "",
  FullName: "",
  MobileNumber: "",
  Country: "",
  Affiliation: "",
  Password: "",
  ConfirmPassword: "",
  Captcha: "",
};
const SignUp = (props) => {
  const [state, setState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);
  const { roles } = useSelector((state: any) => state.commonReducer);
  var formRef: any = useRef();
  const recaptchaRef: any = useRef();

  const rules = {
    FullName: [
      {
        required: true,
        message: "Full name is required",
        trigger: "blur",
      },
    ],
    Email: [
      {
        required: true,
        message: "Email is required",
        trigger: "blur",
      },
      {
        type: "email",
        message: "Please input correct email address",
        trigger: "blur,change",
      },
    ],
    Password: [
      { required: true, message: "Please input the password", trigger: "blur" },
      {
        validator: (rule, value, callback) => {
          if (value === "") {
            callback(new Error("Please input the password"));
          } else {
            if (state.Password !== "") {
              formRef.current.validateField("ConfirmPassword");
            }
            callback();
          }
        },
      },
    ],
    ConfirmPassword: [
      {
        required: true,
        message: "Please input the password again",
        trigger: "blur",
      },
      {
        validator: (rule, value, callback) => {
          if (value === "") {
            callback(new Error("Please input the password again"));
          } else if (value !== state.Password) {
            callback(new Error("Two inputs don't match!"));
          } else {
            callback();
          }
        },
      },
    ],
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const onChange = (key: any, value: any) => {
    if (key === "IsInactive") {
      value = !value;
    }
    setState({ ...state, [key]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    formRef.current.validate(async (valid) => {
      if (valid) {
        setLoading(true);
        const param = { ...state, UserName: state.Email };
        var res: any = await dispatch(signUp(param));
        setLoading(false);
        if (res) history.push(routes.login);
      } else {
        return false;
      }
    });
  };
  const handleReset = (e: any) => {
    formRef.current.resetFields();
    setState(initialState);
  };

  return (
    <div className="container">
      <div className="sign-up-form">
        <Form
          ref={formRef}
          rules={rules}
          className="demo-form-stacked"
          model={state}
          labelPosition="top"
          labelWidth="100"
        >
          <Layout.Row gutter="20">
            <Form.Item label="Full Name" prop="FullName">
              <Input
                value={state.FullName}
                onChange={(val: any) => onChange("FullName", val)}
              ></Input>
            </Form.Item>

            <Form.Item label="Email" prop="Email">
              <Input
                value={state.Email}
                onChange={(val: any) => onChange("Email", val)}
              ></Input>
            </Form.Item>

            <Form.Item label="Mobile" prop="MobileNumber">
              <Input
                value={state.MobileNumber}
                onChange={(val: any) => onChange("MobileNumber", val)}
              ></Input>
            </Form.Item>

            <Form.Item label="Country" prop="Country">
              <Input
                value={state.Country}
                onChange={(val: any) => onChange("Country", val)}
              ></Input>
            </Form.Item>

            <Form.Item label="Affiliation" prop="Affiliation">
              <Input
                value={state.Affiliation}
                onChange={(val: any) => onChange("Affiliation", val)}
              ></Input>
            </Form.Item>

            <Form.Item label="Password" prop="Password">
              <Input
                type="password"
                value={state.Password}
                onChange={(val: any) => onChange("Password", val)}
              ></Input>
            </Form.Item>

            <Form.Item label="Confirm Password" prop="ConfirmPassword">
              <Input
                type="password"
                value={state.ConfirmPassword}
                onChange={(val: any) => onChange("ConfirmPassword", val)}
              ></Input>
            </Form.Item>
            <Form.Item>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdEjqkeAAAAAAfuSmszHsZMq2N36JRQO9cLVoe8"
                onChange={(val) => onChange("Captcha", val)}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                onClick={handleSubmit}
                nativeType="submit"
                loading={isLoading}
              >
                Sign up
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </Form.Item>
          </Layout.Row>
        </Form>
      </div>
    </div>
  );
};
export default SignUp;
