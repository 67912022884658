import React, { useEffect, useState } from "react";
import { Button, Dialog } from "element-react";
import { useDispatch } from "react-redux";
import { GetReviewByArticleId, RequestRevision } from "store/actions/articles";
import Loader from "components/loader.tsx";
import { Wrapper } from "components/form";
import UploadFiles from "scenes/common/uploadFiles";
import { Editor } from "components/form";
const RequestForRevision = (props) => {
  const { row, title, dispatch, loadData } = props;

  const [state, setState] = useState({
    isVisible: false,
    isLoading: false,
    review: null,
    comments: "",
    attachments: [],
  });
  // const [commentsForAuthor, setCommentsForAuthor] = useState("");

  const onClick = () => {
    setState({ ...state, isVisible: true });
  };
  const closeModal = () => {
    setState({ ...state, isVisible: false });
  };
  const onClickRequestRevison = async () => {
    const { comments, attachments } = state;
    const param = { ArticleId: row.Id, Comments: comments, Files: attachments };
    setState({ ...state, isLoading: true });
    const res = await dispatch(RequestRevision(param));

    if (res && res.Status) {
      setState({ ...state, isVisible: false, isLoading: false });
      loadData();
    } else {
      setState({ ...state, isLoading: false });
    }
  };

  return (
    <>
      <Button size="mini" onClick={() => onClick()}>
        {title}
      </Button>
      {state.isVisible && (
        <Dialog
          title="Request Revision"
          visible={state.isVisible}
          onCancel={closeModal}
          style={{ width: "900px" }}
        >
          <Dialog.Body>
            <Loader isLoading={state.isLoading}>
              <div>
                <Wrapper label="Comments For Author">
                  <Editor
                    name="comments"
                    value={state.comments}
                    onChange={(name, val) => {
                      setState({ ...state, comments: val });
                    }}
                  />
                </Wrapper>
                <UploadFiles
                  title="Revision Attachments"
                  attachments={state.attachments}
                  setAttachments={(files) =>
                    setState({ ...state, attachments: files })
                  }
                />
                <Wrapper>
                  <Button
                    type="primary"
                    onClick={onClickRequestRevison}
                    loading={state.isLoading}
                  >
                    Request Revision
                  </Button>
                </Wrapper>
              </div>
            </Loader>
          </Dialog.Body>
        </Dialog>
      )}
    </>
  );
};
export default RequestForRevision;
