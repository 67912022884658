import { HWrapper } from 'components/form'
import RoleEnum from 'constants/roleEnum'
import { RouteEnum } from 'constants/routesEnum'
import { Badge, Button, Progress } from 'element-react'
import { isEmpty, renderStatus } from 'helpers'
import { UserDetailInterface } from 'interfaces/actions/auth'
import React from 'react'
import { FaList } from 'react-icons/fa'
import { MdOutlineChat, MdPreview } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CommentButton from '../../../comments/commentButton'

const ArticleCard = (props) => {
  const { article, actions, index } = props
  const history = useHistory()
  const { RoleName } = useSelector(
    (state: any) => state.userReducer.loggedInUserDetail as UserDetailInterface,
  )

  const completion = (article && ((article.Steps || 0) / 5) * 100) || 0
  const reviewCompletion =
    (article && ((article.ReviewSteps || 0) / 4) * 100) || 0

  const cardDetailRightCol = () => {
    return (
      RoleName === RoleEnum.Reviewer && (
        <div className="article-card__details-col-2">
          {article && !isEmpty(article.ReviewResponsedOnString) && (
            <div className="info">
              <span className="info-date">
                {article.ReviewResponsedOnString}
              </span>
              <span className="info-label">Responded On</span>
            </div>
          )}
          {article && !isEmpty(article.ReviewResponseDueDate) && (
            <div className="info">
              <span className="info-date">
                {article.ReviewResponseDueDateString}
              </span>
              <span className="info-label">Response Due Date</span>
            </div>
          )}
          {article && !isEmpty(article.ReviewDueDateString) && (
            <div className="info">
              <span className="info-date">{article.ReviewDueDateString}</span>
              <span className="info-label">Review Due Date</span>
            </div>
          )}
        </div>
      )
    )
  }
  return (
    <div className="article-card">
      <div className="article-card__details">
        <div className="article-card__details-col-1">
          <div>
            <HWrapper label="Title">{article.ProposalTitle}</HWrapper>
            <HWrapper label="Section">{article.SectionName}</HWrapper>
            <HWrapper label="Sub-Section">{article.SubSectionName}</HWrapper>

            <div className="article-card__info">
              {article.SubmittedOn && (
                <>
                  <div className="article-card__info-detail">
                    {RoleName === RoleEnum.Administrator ||
                      RoleName === RoleEnum.Editor ? (
                      <span>
                        Submitted by{' '}
                        {`${article.InvestigatorSalutation} ${article.InvestigatorName}`}{' '}
                        on
                      </span>
                    ) : (
                      <span>Submitted on</span>
                    )}
                    <span>{article.DateInString}</span>
                  </div>
                  <div className="article-card__info-block">
                    <span>Submission Number:</span>
                    <span>{article.SubmissionNumber}</span>
                  </div>
                </>
              )}
            </div>


            <div className="article-card__status">
              {RoleName === RoleEnum.Reviewer ? (
                <div className="article-card__status-progress">
                  <Progress
                    type="circle"
                    strokeWidth={12}
                    percentage={reviewCompletion}
                  />
                </div>
              ) : (
                <div className="article-card__status-progress">
                  <Progress
                    type="circle"
                    strokeWidth={12}
                    percentage={completion}
                  />
                </div>
              )}
              {(RoleName === RoleEnum.Administrator ||
                RoleName === RoleEnum.Editor) && (
                  <>
                    <div
                      className="article-card__status-reviewinfo"
                      title={`${article.CompletedReview} Completed Reviews / ${article.TotalReview} Total Reviews `}
                    >
                      <MdPreview /> {article.CompletedReview}/
                      {article.TotalReview}
                    </div>
                    {article.TotalReview > 0 && (
                      <>
                        <div className="article-card__status-reviewinfo">
                          <span>Total requested review: </span>
                          {article.TotalReview}
                        </div>
                        <div className="article-card__status-reviewinfo">
                          <span>Waiting Reviews for Acceptance: </span>
                          {article.NoOfReviewWaitingAcceptance}
                        </div>
                        <div className="article-card__status-reviewinfo">
                          <span>Total Accepted Reviews: </span>
                          {article.NoOfReviewAccepted}
                        </div>
                        <div className="article-card__status-reviewinfo">
                          <span>Total Submitted Reviews: </span>
                          {article.CompletedReview}
                        </div>
                      </>
                    )}
                  </>
                )}
              {renderStatus(
                article,
                RoleName,
                article.Status,
                article.ReviewRecommendation,
                article.NoOfReviewWaitingAcceptance
              )}
            </div>
          </div>
        </div>
        {cardDetailRightCol()}
      </div>
      <div className="article-card__action">
        <div className="article-card__action-left">
          <CommentButton articleId={article.Id} article={article} />

          {RoleName !== RoleEnum.Reviewer && (
            <Button
              size="mini"
              onClick={() =>
                history.push(`${RouteEnum.viewArticle}/${article.Id}`)
              }
            >
              <FaList />
              View
            </Button>
          )}
        </div>
        <div className="article-card__action-right">
          {actions && actions(article, index)}
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
