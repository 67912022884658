import React from "react";
import { Table, Pagination, Progress, Button, Badge } from "element-react";
import ArticleCard from "./articleCard";
interface TableProps {
  width?: any;
  data: any[];
  totalData: number;
  PageIndex: number;
  pageSize: number;
  actions: Function;
  handlePageSizeChanged: (size?: number) => void;
  handlePageChanged: (currentPage?: number) => void;
}

const CardView = (props: TableProps) => {
  const {
    data,
    totalData,
    pageSize,
    PageIndex,
    handlePageSizeChanged,
    handlePageChanged,
    actions,
  } = props;

  return (
    <>
      {data &&
        data.map((x, index) => (
          <ArticleCard
            key={index}
            index={index}
            article={x}
            actions={actions}
          />
        ))}

      {totalData === 0 && <div className="no-table-data">No Data</div>}
      {totalData > 0 && (
        <Pagination
          layout="total, sizes, prev, pager, next"
          total={totalData}
          pageSizes={[10, 20, 50, 100]}
          pageSize={pageSize}
          currentPage={PageIndex}
          onSizeChange={handlePageSizeChanged}
          onCurrentChange={handlePageChanged}
        />
      )}
    </>
  );
};
CardView.defaultProps = {
  width: "100%",
};

export default CardView;
