import React, { useEffect, useState } from "react";
import { Dialog, Button, Input, Radio, Message } from "element-react";
import { FaPlus, FaMinus, FaUserPlus } from "react-icons/fa";
import TableView from "components/tableView";
import {
  AssignArticleForEditor,
  userWithAssignedInfo,
} from "store/actions/articles";
import { useDispatch } from "react-redux";
import RoleEnum from "constants/roleEnum";

const AssignUser = (assignProps) => {
  const { row, dispatch } = assignProps;

  const [state, setState] = useState({ editorName: "" });
  const [isVisible, setVisible] = useState(false);
  const onAssingClick = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button size="mini" onClick={() => onAssingClick()}>
        <FaUserPlus />
        Assign
      </Button>
      {isVisible && (
        <Dialog
          closeOnClickModal={false}
          title="Assign Editor"
          visible={isVisible}
          onCancel={handleCancel}
          style={{ width: "800px" }}
        >
          <Dialog.Body>
            <div>
              <Editors {...assignProps} onSave={handleCancel} />
            </div>
          </Dialog.Body>
        </Dialog>
      )}
    </>
  );
};

const Editors = (props) => {
  const { row, dispatch, articlesRef, onSave } = props;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    editorName: "",
    remarks: "",
  });
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    fetchData("");
  }, []);
  const [tableData, setTableData] = useState([]);

  const fetchData = async (fullname) => {
    var res: any = await dispatch(
      userWithAssignedInfo({
        articleId: row.Id,
        fullname: fullname,
        role: RoleEnum.Editor,
      })
    );
    var index = res.findIndex((x) => x.IsAssigned);
    setSelectedIndex(index);
    setTableData(res);
  };

  const onChangeSearch = (name, val) => {
    setState({ ...state, [name]: val });
    setSelectedIndex(-1);
    fetchData(val);
  };

  const columns = [
    {
      label: "Editor",
      prop: "FullName",
      render: (row, column, index) => (
        <div onClick={() => setSelectedIndex(index)}>{row.FullName}</div>
      ),
    },
    {
      label: "Done",
      prop: "Done",
      width: 120,
      render: (row, column, index) => (
        <div onClick={() => setSelectedIndex(index)}>{row.Done}</div>
      ),
    },
    {
      label: "Active",
      prop: "Active",
      width: 120,
      render: (row, column, index) => (
        <div onClick={() => setSelectedIndex(index)}>{row.Active}</div>
      ),
    },
    {
      label: "",
      prop: "",
      width: 60,
      render: (row, column, index) => {
        return (
          <div onClick={() => setSelectedIndex(index)}>
            <Radio value="" checked={selectedIndex === index} />
          </div>
        );
      },
    },
  ];

  const onSaveClicked = async () => {
    if (selectedIndex === -1) {
      Message({
        message: "At least one editor is required.",
        type: "warning",
      });
      return;
    }
    var remarks = state.remarks;
    var assignTo = tableData[selectedIndex].Id;
    const articleId = props && props.row.Id;
    const assignedAs = RoleEnum.Editor;
    setLoading(true);
    const res = await dispatch(
      AssignArticleForEditor({ articleId, assignTo, assignedAs, remarks })
    );
    setLoading(false);
    onSave();
    articlesRef && articlesRef.current.loadData();
  };
  return (
    <div>
      <div className="margin-bottom-10">
        <Input
          value={state.editorName}
          placeholder="Search Editor"
          onChange={(val: any) => onChangeSearch("editorName", val)}
        ></Input>
      </div>
      <div className="margin-bottom-10">
        <TableView showNoData={false} columns={columns} data={tableData} />
      </div>
      <div className="margin-bottom-10">
        <Input
          type="textarea"
          value={state.remarks}
          onChange={(val: any) => setState({ ...state, remarks: val })}
        ></Input>
      </div>
      <div className="flex-end">
        <Button type="primary" onClick={onSaveClicked} loading={loading}>
          Save
        </Button>
      </div>
    </div>
  );
};
export default AssignUser;
