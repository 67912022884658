export const api = {
  auth: {
    siginIn: "auth/signin",
    signOut: "auth/signout",
  },
  meta: {
    templates: "meta/templates",
    checkList: "meta/checklist",
    sections: "meta/sections",
    subSections: "meta/sub-sections",
    components: "meta/components",
    salutations: "meta/salutations",
    recommendations: "meta/recommendations",
  },
  common: {
    roles: "security/roles",
    emailTemplate: "common/email-template",
    downloadFiles: `common/download`,
  },
  users: {
    verifyEmail: "security/verify-email",
    signup: "security/signup",
    list: "security/users/list",
    users: "security/users",
    userDetails: "security/users/details",
    forgotPassword: "security/users/forgot-password",
    resetPassword: "security/users/reset-password",
    changePassword: "security/users/change-password",
    changePasswordByUser: "security/users/change-user-password",
    status: "security/users/status",
  },
  articles: {
    article: "articles/article",
    count: "articles/count",
    start: "articles/start",
    authors: "articles/authors",
    attachments: "articles/attachments",
    metadata: "articles/metadata",
    confirmation: "articles/submit",
    assignArticleForEditor: "articles/assign-article/editor",

    assingedInfo: "articles/assigned-info",
    updateStatus: "articles/update-status",
    review: "articles/review",
    assigntToReviewer: "articles/assign-to-reviewer",
    reviewDetail: "articles/review-detail",
    reviewByArticle: "articles/review-by-article",
    acknowledgeReview: "articles/acknowledge-review",
    submitDownloadAndReview: "articles/submit-download-review",
    submitReview: "articles/submit-review",
    requestRevision: "articles/request-revision",
    revision: "articles/revision",
    reviewer: "articles/reviewer",
    comment: "articles/comments",
  },
};
