import React, { useEffect, useState } from 'react'
import { Button, Notification } from 'element-react'
import UploadFile from './uploadFile'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'helpers'

import { SaveAttachment } from 'store/actions/articles'
import ArticleStatusEnum from 'constants/articleStatusEnum'
import SubmissionList from './sumissionList'

const UploadSubmission = (props) => {
  const { onSave ,isRevision} = props
  const [isVisible, setVisible] = useState(false)
  const [selectedFileData, setSelectedFileData] = useState({
    data: null,
    index: -1,
  })
  const dispatch = useDispatch()

  const [attachments, setAttachments] = useState([])
  const { article } = useSelector((st: any) => st.articleReducer)
  const { components } = useSelector((state: any) => state.metaReducer)

  useEffect(() => {
    if (!isEmpty(article)) {
      let attachments = article.ArticleAttachments || []
      setAttachments([...attachments])
    }
  }, [article])

  const handleSaveFile = (index, obj) => {
    let newAttachments = [...attachments]

    if (index >= 0) newAttachments[index] = obj
    else newAttachments.push(obj)

    setAttachments(newAttachments)
    setVisible(false)
  }

  const onSaveClicked = async () => {
    var attachmentIds = attachments.map((x) => x.ComponentId)
    var requiredComponent = components.filter(
      (x) => x.IsRequired === true && !attachmentIds.includes(x.Id),
    )

    var mustHaveNames = requiredComponent.map((x) => x.Name).toString()

    if (requiredComponent.length > 0) {
      Notification({
        message: `Please add components for ${mustHaveNames}`,
        type: 'error',
        duration: 3000,
        offset: 50,
      })
      return
    }
    let data = new FormData()
    data.append('ArticleId', article.Id)
    data.append('Steps', '3')

    for (var i = 0; i < attachments.length; i++) {
      var item = attachments[i]
      data.append(`Attachments[${i}].Attachment`, item.Attachment || null)
      data.append(`Attachments[${i}].FileFormat`, item.FileFormat)
      data.append(`Attachments[${i}].FileType`, item.FileType)
      data.append(`Attachments[${i}].ServerFileName`, item.ServerFileName)
      data.append(`Attachments[${i}].Size`, item.Size)
      data.append(`Attachments[${i}].UserFileName`, item.UserFileName)
      data.append(`Attachments[${i}].ComponentId`, item.ComponentId)
      data.append(`Attachments[${i}].Description`, item.Description)
    }
    var res: any = await dispatch(SaveAttachment(data))
    if (res && res.Status) onSave()
    //  onSave && onSave();
  }

  const handleAttachmentEditClick = (index) => {
    setSelectedFileData({ index: index, data: { ...attachments[index] } })
    setVisible(true)
  }

  const handleAttachmentDeleteClick = (index) => {
    let newAttachments = [...attachments]
    newAttachments.splice(index, 1)
    setAttachments(newAttachments)
  }
  const checkIfDisabled = () => {
    if (attachments && attachments.length === 0) return true
    else if (article.Id != 0 && !article.CanEdit) return true
    return article.Status === ArticleStatusEnum.SUBMITTED
  }

  const renderSubmissionList = () => {}
  return (
    <div className="upload-steps__submissions">
      <div className="upload-steps__submissions-header">
        <div>Submission Files</div>
        <div className="upload-steps__submissions-header-action">
          <div>
            <Button
              type="text"
              disabled={article.Status === ArticleStatusEnum.SUBMITTED}
              onClick={() => {
                setSelectedFileData({ index: -1, data: null })
                setVisible(true)
              }}
            >
              Upload File
            </Button>
          </div>
        </div>
      </div>

      <SubmissionList
        attachments={attachments}
        handleAttachmentDeleteClick={handleAttachmentDeleteClick}
        handleAttachmentEditClick={handleAttachmentEditClick}
        isRevision={isRevision}
      />

      <UploadFile
        isVisible={isVisible}
        setVisible={setVisible}
        selectedFileData={selectedFileData}
        onCancel={() => {
          setVisible(false)
          setSelectedFileData({ index: -1, data: null })
        }}
        onSave={(index, obj) => handleSaveFile(index, obj)}
      />
      <div className="upload-steps__submissions-footer">
        <Button
          type="primary"
          onClick={onSaveClicked}
          disabled={checkIfDisabled()}
        >
          Save and Continue
        </Button>
      </div>
    </div>
  )
}
export default UploadSubmission
