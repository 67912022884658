import ViewArticle from "scenes/viewArticle";
import VerifyEmailAddress from "scenes/Administrator/user/verifyEmailAddress";
import ForgotPassword from "scenes/Administrator/user/forgotPassword";
import PasswordReset from "scenes/Administrator/user/sendPasswordResetLink";
import SignUp from "scenes/Administrator/user/signUp";
import RoleEnum from "constants/roleEnum";
import RouteEnum from "constants/routesEnum";
import Comments from "scenes/comments";
const PublicRoutes = [
  {
    exact: true,
    component: ForgotPassword,
    role: RoleEnum.Public,
    title: "Reset Password",
    path: RouteEnum.forgotPassword,
  },
  {
    exact: true,
    component: PasswordReset,
    role: RoleEnum.Public,
    title: "Reset Password",
    path: RouteEnum.resetPassword,
  },
  {
    exact: true,
    component: SignUp,
    role: RoleEnum.Public,
    title: "Sign up",
    path: RouteEnum.signup,
  },
  {
    exact: true,
    component: VerifyEmailAddress,
    role: RoleEnum.Public,
    title: "Verify Email",
    path: RouteEnum.verifyEmailAddress,
  },
  {
    exact: true,
    component: ViewArticle,
    role: RoleEnum.Public,
    title: "View Article",
    path: `${RouteEnum.viewArticle}/:articleId`,
  },
  {
    exact: true,
    component: Comments,
    role: RoleEnum.Public,
    title: "Commetns",
    path: `${RouteEnum.comments}/:articleId`,
  },
];

export default PublicRoutes;
