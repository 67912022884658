import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button } from 'element-react'
import { MdPreview } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'helpers'

const Actions = (props) => {
  const { row, selectedTab, handleReviewClick } = props

  const getActions = () => {
    var actions = []
    console.clear()
    console.log(row)
    !isEmpty(row.ReviewId) &&
      actions.push(
        <Button size="mini" onClick={() => handleReviewClick(row.ReviewId)}>
          <MdPreview />
          {row.ReviewCompleted ? 'View' : 'Take Action'}
        </Button>,
      )

    return actions
  }

  return <> {getActions()}</>
}

export default Actions
