import ReviewerLanding from "scenes/reviewer";
import Submitter from "scenes/submitter";
import RoleEnum from "constants/roleEnum";
import RouteEnum from "constants/routesEnum";
import Review from "scenes/common/review";
const ReviewerRoutes = [
  {
    exact: true,
    component: ReviewerLanding,
    role: RoleEnum.Reviewer,
    title: "Dashboard",
    path: RouteEnum.reviewer,
  },
  {
    exact: true,
    component: Review,
    role: RoleEnum.Reviewer,
    title: "Review",
    path: `${RouteEnum.review}/:reviewId`,
  },
];

export default ReviewerRoutes;
