import { post, put, get, deletion, api } from "networkService";
import types from "store/types";

export const GetLoggedInUserDetails = () => async (dispatch: Function) => {
  const res = await get(api.users.userDetails, dispatch);
  dispatch({ type: types.GET_LOGGEDIN_USER_DETAIL, payload: res });
  return res;
};

export const GetUserList = (param) => async (dispatch: Function) => {
  const res: any = await post(api.users.list, dispatch, param, false);
  dispatch({ type: types.GET_USER_LIST, payload: res.Data });
  return res.Data;
};

export const SaveUser = (param) => async (dispatch: Function) => {
  const act = param.Id ? put : post;
  const res: any = await act(api.users.users, dispatch, param);
  return res.Data;
};
export const DeleteUser = (id) => async (dispatch: Function) => {
  const res: any = await deletion(`${api.users.users}`, dispatch, id);
  return res;
};

export const ChangeUserStatus =
  ({ userId, isInactive }) =>
  async (dispatch: Function) => {
    const res: any = await get(
      `${api.users.status}/${userId}?isInactive=${isInactive}`,
      dispatch
    );
    return res.Data;
  };

export const changePasswordByUser =
  ({ userName, newPassword }) =>
  async (dispatch: Function) => {
    const res = await post(api.users.changePasswordByUser, dispatch, {
      userName,
      newPassword,
    });
    return res;
  };

export const changePassword =
  ({ currentPassword, newPassword, confirmNewPassword }) =>
  async (dispatch: Function) => {
    const res = await post(api.users.changePassword, dispatch, {
      currentPassword,
      confirmNewPassword,
      newPassword,
    });
    return res;
  };

export const signUp = (param) => async (dispatch: Function) => {
  const res = await post(api.users.signup, dispatch, param);
  return res;
};

export const forgotPassword =
  ({ email }) =>
  async (dipatch: Function) => {
    const res = await post(api.users.forgotPassword, dipatch, { email });
    return res;
  };

export const resetPassword = (param) => async (dipatch: Function) => {
  const res = await post(api.users.resetPassword, dipatch, param);
  return res;
};
