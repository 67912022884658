import { Breadcrumb, Button, Dialog } from "element-react";
import React, { useEffect, useState } from "react";
import UploadRevisionFiles from "./uploadRevisionFiles";
import SubmissionForm from "../../common/articleSubmissionForm";
import { createMarkup, isEmpty } from "helpers";
import { GetRevision } from "store/actions/articles";
import { useDispatch } from "react-redux";
import Loader from "components/loader.tsx";
import { Wrapper } from "components/form";
import Attachments from "scenes/common/attachments";
import { useHistory } from "react-router";
import RouteEnum from "constants/routesEnum";
import { Link } from "react-router-dom";
const Revision = (props) => {
  const [state, setState] = useState({
    show: false,
    isloading: false,
    revision: null,
  });

  const { revisionId } = props.match.params;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(revisionId)) {
      fetchRevision(revisionId);
    }
  }, [revisionId]);

  const fetchRevision = async (id) => {
    var res = await dispatch(GetRevision(id));
    setState({ ...state, revision: res });
  };
  const { revision, isloading } = state;
  return (
    <Loader isLoading={isloading}>
      <Breadcrumb separator="/">
        <Breadcrumb.Item>
          <Link to={RouteEnum.submitter}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Revision Detail</Breadcrumb.Item>
      </Breadcrumb>
      {isEmpty(revision) ? null : (
        <div className="revision">
          <div className="revison-content">
            <div>
              <Wrapper label="Comments">
                <div
                  className="ck-editor-content"
                  dangerouslySetInnerHTML={createMarkup(revision.Comments)}
                ></div>
              </Wrapper>
              <Wrapper>
                <Attachments
                  files={revision.Attachments}
                  title="Review Files"
                />
              </Wrapper>
              <Wrapper>
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(
                      `${RouteEnum.revision}/${revisionId}/${revision.ArticleId}`
                    );
                  }}
                >
                  Revise
                </Button>
              </Wrapper>
            </div>
          </div>
        </div>
      )}
    </Loader>
  );
};

export default Revision;
