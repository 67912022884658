import React, { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";

const Editor = (props) => {
  const { value, name } = props;
  const config = {
    // extraPlugins: [ListStyle],
  };

  return ClassicEditor && (
    <CKEditor
      config={config}
      editor={ClassicEditor}
      data={value || ""}
      onReady={editor => {
        editor?.setData(value ?? "")
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        props.onChange && props.onChange(name, data);
      }}
    />
  );
};
export default Editor;
