import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router";

import Header from "./header";
import Login from "../../scenes/auth/login";
import routes from "constants/routesEnum";
import { Menu, Layout as ELayout } from "element-react";
import RoleEnum from "constants/roleEnum";
import AdminLayout from "./AdminLayout";
import EditorLayout from "./EditorLayout";

export default function Layout(props: any) {
  let authToken = localStorage.getItem("AuthToken");
  const [state, setState] = useState(authToken);
  let history = useHistory();
  let role = localStorage.getItem("Role");
  // useEffect(() => {
  // }, [authToken]);
  if (!authToken || authToken === "null") {
    // history.push(routes.login);
  }
  return (
    <>
      <Header />
      {role === RoleEnum.Administrator ? (
        <AdminLayout {...props} />
      ) : role === RoleEnum.Editor ? (
        <EditorLayout {...props} />
      ) : (
        <div className="app-container">{props.children}</div>
      )}
    </>
  );
}
