import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CgAttachment, CgFile } from 'react-icons/cg'
import SvgIcons from 'components/icons/svgIcons'
import { FaTimes } from 'react-icons/fa'
import classnames from 'classnames'
import Constant from 'constants/index'
import checkFileExtension from 'helpers/checkFileExtension'
import { api } from 'networkService/api'
import ImageViewer from 'components/imageViewer'
import { containsInvalidFiles, isEmpty, validateFile } from 'helpers'

const AttachmentsButton = (props) => {
  const fileRef: any = useRef()

  const {
    enableUpload,
    name,
    disabled,
    multiple,
    maxFiles,
    value,
    onChange,
    label,
    isPreview,
    validExtensions,
  } = props
  const [state, setState] = useState({ preview: false, selectedValue: null })
  const [error, setError] = useState(false)
  const [fileList, setFileList] = useState([])
  useEffect(() => {
    if (value) {
      if (fileRef && fileRef.current) fileRef.current.value = ''
      let files =
        value?.map((x) => ({
          ...x,
          file: x.file,
          name: x.UserFileName || x.userFileName || x.name,
          serverFileName: x.ServerFileName || null,
        })) || []

      setFileList(files)
    }
  }, [value])

  const handleChange = (event) => {
    const { files } = event.target
    setError(false)
    const list = []

    for (var i = 0; i < files.length; i++) {
      var file = files[i]
      if (!validateFile(file, validExtensions)) {
        fileRef.current.value = ''
        setError(true)
        onChange && onChange(name, [...fileList])
        return
      }
      list.push({ file, name: file.name })
    }
    onChange && onChange(name, [...fileList, ...list])
    // debugger;
    // if (containsInvalidFiles(files, validExtensions)) {
    //   fileRef.current.value = "";
    //   setError(true);

    //   onChange && onChange(name, null);
    // } else onChange && onChange(name, [...value, ...files]);
  }
  const openFileOption = () => {
    if (isPreview) return
    fileRef.current.click()
  }
  const getDownloadLink = (file) => {
    const param = {
      serverFileName: file.serverFileName,
      name: file.name,
      FileType: file.FileType,
    }
    let url = `v1/${api.common.downloadFiles}/${file.serverFileName}`
    return url
  }
  const getDiplayIcon = (file) => {
    const { isDocs, isImage, isPdf } = checkFileExtension(
      file.serverFileName || file.name,
    )
    //  if (isEmpty(file)) return "noif ilelel";

    let icon = isDocs ? (
      <SvgIcons name="document" size="20" />
    ) : isPdf ? (
      <SvgIcons name="pdf" size="20" />
    ) : isImage ? (
      file && isEmpty(file.serverFileName) ? (
        <img src={URL.createObjectURL(file?.file)} width="20" height="20" />
      ) : (
        <img
          src={`/${Constant.Uploads}/${file.serverFileName}`}
          width="20"
          height="20"
        />
      )
    ) : (
      <CgFile />
    )
    return icon
  }
  return (
    <>
      {!isPreview && (
        <input
          ref={fileRef}
          type="file"
          style={{ display: 'none' }}
          multiple={multiple}
          name={name}
          disabled={disabled}
          onChange={(event) => handleChange && handleChange(event)}
        />
      )}
      <div className="upload-row-container">
        {label && <span className="upload-label">{label}</span>}
        {fileList?.map((file, index) => (
          <div
            key={index}
            className="upload-item"
            onClick={(e) => {
              const { isImage } = checkFileExtension(
                file.serverFileName || file.name,
              )
              if (isImage)
                file &&
                  !isEmpty(file.serverFileName) &&
                  setState({ ...state, preview: true, selectedValue: file })
              else {
                e.stopPropagation()
                window.open(getDownloadLink(file))
              }
            }}
          >
            {!isEmpty(file.name) && (
              <>
                <div className="upload-item-icon">{getDiplayIcon(file)}</div>
                <div className="upload-item-name">
                  {file.name}
                  {!isPreview && (
                    <div className="btn-remove">
                      <FaTimes
                        style={{ margin: '0px' }}
                        size="12"
                        color="#fff"
                        onClick={(e) => {
                          debugger
                          e.stopPropagation()
                          fileRef.current.value = ''
                          value.splice(index, 1)
                          var el = []
                          if (value.length > 0) el = [...value]
                          onChange && onChange(name, el)
                          // setSelectedIndex(index);
                          // setOpen(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
        {!isPreview && (
          <div className="upload-button" onClick={() => openFileOption()}>
            <div className="el-button">
              <CgAttachment />
              <span className="upload-info">{'Click to Upload'} </span>
            </div>
          </div>
        )}
      </div>
      {error && (
        <div className="upload-error">
          Invalid File. Supported files are {validExtensions.toString()}
        </div>
      )}
      {state.preview && (
        <ImageViewer
          dowloadLinkUrl={getDownloadLink(state.selectedValue)}
          // hostedUrl={`/${Constant.Uploads}`}
          hostedUrl={`${window.location.origin}/${Constant.Uploads}`}
          show={state.preview}
          title={state.selectedValue.name || state.selectedValue.serverFileName}
          image={state.selectedValue}
          onClose={() =>
            setState({ ...state, preview: false, selectedValue: null })
          }
        />
      )}
    </>
  )
}

AttachmentsButton.defaultProps = { isPreview: false, enableUpload: true }

export default AttachmentsButton
