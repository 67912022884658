import RoleEnum from "constants/roleEnum";
import RouteEnum from "constants/routesEnum";
import User from "scenes/Administrator/user";
import AdminLandingPage from "scenes/Administrator";

const AdminRoutes = [
  {
    exact: true,
    component: User,
    role: RoleEnum.Administrator,
    title: "User",
    path: RouteEnum.user,
  },
  {
    exact: true,
    component: AdminLandingPage,
    role: RoleEnum.Administrator,
    title: "Submissions",
    path: RouteEnum.adminSubmission,
  },
];

export default AdminRoutes;
