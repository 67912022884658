import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Dialog,
  Message,
  MessageBox,
  Breadcrumb,
  Progress,
  Dropdown,
} from "element-react";
import Routes from "routes";
import { GetArticles, DeleteArticle } from "store/actions/articles";
import RouteEnum from "constants/routesEnum";
import ArticleStatusEnum from "constants/articleStatusEnum";
import TableView from "components/tableView";
import SplitButton from "components/popup/splitButton";
import Articles from "scenes/common/articles";
import Actions from "./actions";

const Submissions = (props) => {
  const { selectedTab } = props;
  const articlesRef: any = useRef(null);

  const getStatus = () => {
    var status = "0";

    if (selectedTab == "NEW") status = `${ArticleStatusEnum.ASSIGNEDEDITOR}`;
    else if (selectedTab == "UNDERREVIEW") {
      status = [ArticleStatusEnum.REVIEWING].toString();
    } else if (selectedTab == "NEEDACTIONS")
      status = [
        ArticleStatusEnum.REVIEWED,
        ArticleStatusEnum.RESUBMITTED,
      ].toString();
    else if (selectedTab == "APPROVED")
      status = [ArticleStatusEnum.APPROVED].toString();
    else if (selectedTab == "REVERTED")
      status = [ArticleStatusEnum.REVISION].toString();
    else if (selectedTab == "REJECTED")
      status = [ArticleStatusEnum.REJECTED].toString();
    else if (selectedTab == "COMPLETED")
      status = [ArticleStatusEnum.FINALAPPROVED, ArticleStatusEnum.FINALREJECTED].toString();

    return status;
  };

  const renderAction = (row, index) => {
    return (
      <Actions row={row} selectedTab={selectedTab} articlesRef={articlesRef} />
    );
  };
  return (
    <>
      <div className="submitter">
        <Articles
          selectedTab={selectedTab}
          actions={renderAction}
          getStatus={getStatus}
          ref={articlesRef}
        />
      </div>
    </>
  );
};

export default Submissions;
