import React from "react";
import { ReactComponent as Document } from "assets/document.svg";
import { ReactComponent as PDF } from "assets/pdf.svg";

function getIconComponent(name) {
  switch (name) {
    case "document":
      return Document;
    case "pdf":
      return PDF;

    default:
      return null;
  }
}

interface Icons {
  name: "document" | "pdf";
  size?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
  styles?: object;
}

const SvgIcons = ({ size, color, name, height = null }: Icons) => {
  let SvgIcon = getIconComponent(name);
  return <SvgIcon width={size} height={size || height} fill={color} />;
};

SvgIcons.defaultProps = {
  size: "25px",
  color: "#3a3a3a",
};

export default SvgIcons;
