import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import { Form, Input, Button, Select, Layout, Switch } from "element-react";
import Routes from "routes";
import { AddUserInterface } from "interfaces/user";
import { GetRoles } from "store/actions/common";
import { SaveUser } from "store/actions/user";
import { mobileRules } from "helpers";

const AddUser = (props) => {
  const { state, setState, onSaveSuccess } = props;
  const [isLoading, setLoading] = useState(false);
  const { roles } = useSelector((state: any) => state.commonReducer);
  const rules = {
    FullName: [
      {
        required: true,
        message: "Full name is required",
        trigger: "blur",
      },
    ],
    Email: [
      {
        required: true,
        message: "Email is required",
        trigger: "blur",
      },
      {
        type: "email",
        message: "Please input correct email address",
        trigger: "blur,change",
      },
    ],
    Roles: [
      {
        type: "array",
        required: true,
        message: "Role is required",
        trigger: "blur",
      },
    ],
    Password: [
      { required: true, message: "Please input the password", trigger: "blur" },
      // {
      //   validator: (rule, value, callback) => {
      //     if (value === "") {
      //       callback(new Error("Please input the password"));
      //     } else {
      //       if (state.Password !== "") {
      //         formRef.current.validateField("ConfirmPassword");
      //       }
      //       callback();
      //     }
      //   },
      // },
    ],
    MobileNumber: [mobileRules()],
  };
  var formRef: any = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    await dispatch(GetRoles());
  };

  const onChange = (key: any, value: any) => {
    if (key === "IsInactive") {
      value = !value;
    }
    setState({ ...state, [key]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    formRef.current.validate(async (valid) => {
      if (valid) {
        setLoading(true);
        const param = { ...state, UserName: state.Email };
        var res: any = await dispatch(SaveUser(param));
        setLoading(false);
        if (res) {
          onSaveSuccess();
        }
      } else {
        return false;
      }
    });
  };
  const handleReset = (e: any) => {
    formRef.current.resetFields();
    setState({} as AddUserInterface);
  };

  return (
    <div className="container">
      <div className="user-form">
        <Form
          ref={formRef}
          rules={rules}
          className="demo-form-stacked"
          model={state}
          labelPosition="top"
          labelWidth="100"
        >
          <Layout.Row gutter="20">
            <Layout.Col span="12">
              <Form.Item label="Full Name" prop="FullName">
                <Input
                  value={state.FullName}
                  onChange={(val: any) => onChange("FullName", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item label="Email" prop="Email">
                <Input
                  value={state.Email}
                  onChange={(val: any) => onChange("Email", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item label="Mobile" prop="MobileNumber">
                <Input
                  value={state.MobileNumber}
                  onChange={(val: any) => onChange("MobileNumber", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item label="Country" prop="Country">
                <Input
                  value={state.Country}
                  onChange={(val: any) => onChange("Country", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item label="Affiliation" prop="Affiliation">
                <Input
                  value={state.Affiliation}
                  onChange={(val: any) => onChange("Affiliation", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item label="Role" prop="Roles">
                <Select
                  multiple={true}
                  value={state.Roles}
                  placeholder="Role"
                  onChange={(val: any) => onChange("Roles", val)}
                >
                  {roles &&
                    roles.map((role, index) => (
                      <Select.Option
                        key={index}
                        label={role.Name}
                        value={role.Name}
                      ></Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item label="Is Active">
                <Switch
                  onText=""
                  offText=""
                  value={!state.IsInactive}
                  onChange={(val: any) => onChange("IsInactive", val)}
                />
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              {!state.Id && (
                <Form.Item label="Password" prop="Password">
                  <Input
                    type="password"
                    value={state.Password}
                    onChange={(val: any) => onChange("Password", val)}
                  ></Input>
                </Form.Item>
              )}
            </Layout.Col>
          </Layout.Row>
          <Layout.Row>
            <Layout.Col span="12">
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  nativeType="submit"
                  loading={isLoading}
                >
                  Save
                </Button>
                {/* <Button onClick={handleReset}>Reset</Button> */}
              </Form.Item>
            </Layout.Col>
          </Layout.Row>
        </Form>
      </div>
    </div>
  );
};
export default AddUser;
