import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Dialog,
  Message,
  MessageBox,
  Breadcrumb,
  Progress,
  Dropdown,
} from "element-react";
import Routes from "routes";
import { GetArticles, DeleteArticle } from "store/actions/articles";
import RouteEnum from "constants/routesEnum";
import ArticleStatusEnum from "constants/articleStatusEnum";
import TableView from "components/tableView";
import SplitButton from "components/popup/splitButton";
import Articles from "scenes/common/articles";
import Actions from "./actions";

const Submissions = (props) => {
  const { selectedTab } = props;
  const articlesRef: any = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const getStatus = () => {
    var status = "0";

    if (selectedTab == "NEW") status = [ArticleStatusEnum.SUBMITTED, ArticleStatusEnum.RESUBMITTED,].toString();
    else if (selectedTab == "ONGOINGREVIEW") {
      status = [
        ArticleStatusEnum.REVISION,
        ArticleStatusEnum.REJECTED,
        ArticleStatusEnum.APPROVED,
        ArticleStatusEnum.ASSIGNEDEDITOR,
        ArticleStatusEnum.REVIEWED,
        ArticleStatusEnum.REVIEWING,
      ].toString();
    } else if (selectedTab == "FINALAPPROVED") {
      status = `${ArticleStatusEnum.FINALAPPROVED}`;
    }
    else if (selectedTab == "FINALREJECTED") {
      status = `${ArticleStatusEnum.FINALREJECTED}`;
    }
    return status;
  };

  const confirmDelete = (obj) => {
    MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    })
      .then(async () => {
        await dispatch(DeleteArticle(obj.Id))
        articlesRef.current.loadData()
      })
      .catch(() => { })
  }
  const renderAction = (row, index) => {
    return (
      <Actions row={row} selectedTab={selectedTab} articlesRef={articlesRef} handleDelete={(row) => confirmDelete(row)} />
    );
  };
  return (
    <div className="submitter">
      <Articles
        selectedTab={selectedTab}
        actions={renderAction}
        getStatus={getStatus}
        ref={articlesRef}
      />
    </div>
  );
};

export default Submissions;
