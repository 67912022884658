import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Message,
  Breadcrumb,
} from "element-react";
import Start from "./request";
import Guidelines from "./guidelines";
import UploadSubmission from "../attachments";
import DownloadReview from "./downloadReview";
import SubmitArticle from "./submitReview";
import { useHistory } from "react-router";
import RouteEnum from "constants/routesEnum";
import { Link } from "react-router-dom";
import { GetReviewDetails, clearArticle } from "store/actions/articles";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "helpers";
import Loader from "components/loader.tsx";
const Review = (props) => {
  const [steps, setSteps] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("1");
  const dispatch = useDispatch();
  const history = useHistory();
  const { reviewId } = props.match.params;
  const { review } = useSelector((st: any) => st.articleReducer);

  useEffect(() => {
    if (review) {
      let steps = review.Steps || 1;
      if (!review.IsAccepted) {
      } else if (steps == 1) steps = 3;
      else if (steps <= 3) steps++;
      setSteps(steps);
      setSelectedTab(`${steps}`);
    }
  }, [review]);

  useEffect(() => {
    if (reviewId) {
      fetchReview(reviewId);
    } else {
      dispatch(clearArticle());
    }
  }, [reviewId]);

  const fetchReview = async (reviewId) => {
    setLoading(true);
    var revResult = await dispatch(GetReviewDetails(reviewId));
    setLoading(false);
    if (revResult == null) {
      history.push(RouteEnum.base);
      return;
    }
  };

  const handleOnSaveStep1 = () => {
    updateStep(2);
    setSelectedTab("2");
  };

  const handleOnSaveStep2 = () => {
    updateStep(3);
    setSelectedTab("3");
  };
  const handleOnSaveStep3 = () => {
    updateStep(4);
    setSelectedTab("4");
  };

  const handleConfirmation = () => {
    Message({
      showClose: true,
      message: "Congrats, revision completed.",
      type: "success",
    });
    history.push(`${RouteEnum.reviewer}`);
  };
  const updateStep = (newStep: number) => {
    if (newStep > steps) {
      setSteps(newStep);
    }
  };

  const isDisabled = (tab) => {
    return steps < tab;
  };

  const handleTabClicked = (e) => {
    const { name } = e.props;
    setSelectedTab(name);
  };

  const tabs = [
    {
      label: "1.Requst",
      name: "1",
      component: <Start onSave={() => handleOnSaveStep1()} />,
    },
    {
      label: "2. Guidelines",
      name: "2",
      component: <Guidelines onSave={() => handleOnSaveStep2()} />,
    },
    {
      label: "3. Download & Review",
      name: "3",
      component: <DownloadReview onSave={() => handleOnSaveStep3()} />,
    },
    {
      label: "4.Confirmation",
      name: "4",
      component: <SubmitArticle onConfirmation={() => handleConfirmation()} />,
    },
  ];

  return (
    <>
      <Breadcrumb separator="/">
        <Breadcrumb.Item>
          <Link to={RouteEnum.reviewer}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Review</Breadcrumb.Item>
      </Breadcrumb>

      <div className="submission">
        <Loader isLoading={isLoading}>
          <Tabs type="card" value={selectedTab} onTabClick={handleTabClicked}>
            {tabs.map((item, index) => (
              <Tabs.Pane
                key={index}
                label={item.label}
                name={item.name}
                disabled={isDisabled(index + 1)}
              >
                {item.component}
              </Tabs.Pane>
            ))}
          </Tabs>
        </Loader>
      </div>
    </>
  );
};
export default Review;
