import React, { useEffect, useRef, useState } from 'react'
import { Dialog, Form, Input, Select, Button } from 'element-react'
import { AttachmentButton } from 'components/form'
import { useSelector } from 'react-redux'
import { getRequiredRules } from 'helpers'

const initialState = {
  Component: null,
  Attachment: null,
  UserFileName: '',
  ServerFileName: '',
  SupportedFiles: ['*'],
  Size: '',
  Description: "",
}

const UploadFile = (props) => {
  var ref: any = useRef()
  const { isVisible, onSave, onCancel, selectedFileData } = props
  const [state, setState] = useState(initialState)
  const { components } = useSelector((state: any) => state.metaReducer)

  useEffect(() => {
    const data = selectedFileData.data
    if (data) {
      setState({
        ...data,
        Component: components.find((x) => x.Id === data.ComponentId),
        Attachment: data.Attachment || {
          serverFileName: data.ServerFileName,
          name: data.UserFileName,
        },
      })
    } else {
      setState(initialState)
    }
  }, [selectedFileData])
  const rules = {
    Component: [getRequiredRules('Component is required.', 'object')],
    UserFileName: [getRequiredRules('Attachment is required')],
  }

  const handleChange = (name, value) => {
    var nextState = { ...state, [name]: value }
    if (value && name === 'Attachment') nextState['UserFileName'] = value.name

    if (value && name === 'Component') {
      let SupportedFiles = value?.SupportedFiles?.split(',')?.map((x) =>
        x?.trim(),
      )
      nextState['SupportedFiles'] = SupportedFiles
    }
    setState(nextState)
  }

  const handleSave = () => {
    ref.current.validate(async (valid) => {
      if (valid) {
        let obj = {
          ...state,
          ComponentId: state.Component.Id,
          ComponentName: state.Component.Name,
          Description: state.Description
        }
        onSave && onSave(selectedFileData.index, obj)
        setState(initialState)
      }
    })
  }
  return (
    <Dialog title="Article Address" visible={isVisible} onCancel={onCancel}>
      <Dialog.Body>
        <Form model={state} labelPosition="top" ref={ref} rules={rules}>
          <Form.Item label="Article Component" prop="Component">
            <Select
              value={state.Component}
              placeholder="Please select component"
              onChange={(val) => handleChange('Component', val)}
            >
              {components &&
                components.map((item, index) => (
                  <Select.Option
                    key={index}
                    label={item.Name}
                    value={item}
                  ></Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Attachment" prop="UserFileName">
            <AttachmentButton
              value={state.Attachment}
              name="Attachment"
              onChange={handleChange}
              validExtensions={state.SupportedFiles}
            />
          </Form.Item>
          <Form.Item label="Description" prop="Description">
            <Input
              type="textarea"
              value={state.Description}
              onChange={(val: any) => handleChange('Description', val)}
            ></Input>
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={() => handleSave()}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Dialog.Body>
    </Dialog>
  )
}
export default UploadFile
