import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Dialog,
  Message,
  MessageBox,
  Breadcrumb,
  Tabs,
  Badge,
} from "element-react";
import Routes from "routes";
import { AddUserInterface } from "interfaces/user";
import { GetRoles } from "store/actions/common";
import { GetUserList, DeleteUser, ChangeUserStatus } from "store/actions/user";
import RouteEnum from "constants/routesEnum";
import ArticleStatusEnum from "constants/articleStatusEnum";
import SubmissionList from "./component/submissionList";
import classNames from "classnames";
import TabTitle from "scenes/common/tabTitle";
const Submitter = () => {
  const [selectedTab, setSelectedTab] = useState("NEW");

  const dispatch = useDispatch();
  const history = useHistory();

  const tabs = [
    {
      label: "New Submission",
      name: "NEW",
    },
    {
      label: "Under Review",
      name: "SUBMITTED",
    },
    {
      label: "Need Actions",
      name: "REVERTED",
    },
    {
      label: "Rejected",
      name: "REJECTED",
    },
    {
      label: "Approved",
      name: "APPROVED",
    },
  ];
  const handleTabClicked = (e) => {
    const { name } = e.props;
    setSelectedTab(name);
  };

  return (
    <div>
      <div className="header">
        <Breadcrumb separator="/">
          <Breadcrumb.Item>
            <Link to={RouteEnum.submitter}>Home</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Link
            to={RouteEnum.submission}
            className="el-button el-button--primary"
          >
            New Submission
          </Link>
        </div>
      </div>

      <div className="submission">
        <Tabs type="card" value={selectedTab} onTabClick={handleTabClicked}>
          {tabs.map((item, index) => (
            <Tabs.Pane
              key={index}
              label={
                <TabTitle item={item} selectedTab={selectedTab} count={24000} />
              }
              name={item.name}
            ></Tabs.Pane>
          ))}
        </Tabs>

        {<SubmissionList selectedTab={selectedTab} />}
      </div>
    </div>
  );
};

export default Submitter;
