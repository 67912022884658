import types from "../types";

const initialState = {
  article: {},
  review: {},
  articleCounts: [],
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case types.GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
      };
    case types.GET_REVIEW:
      return { ...state, review: action.payload };

    case types.COUNT_ARTICLE:
      return { ...state, articleCounts: action.payload };

    default:
      return state;
  }
}
