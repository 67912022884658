import { post, get, api } from "networkService";
import types from "store/types";

export const GetRoles = () => async (dispatch: Function) => {
  const res: any = await get(api.common.roles, dispatch);
  dispatch({ type: types.GET_ROLES, payload: res.Data });
};

export const verifyUserEmailAddress =
  ({ param }) =>
  async (dispatch: Function) => {
    const res: any = await get(
      api.users.verifyEmail,
      dispatch,
      {
        param,
      },
      true
    );
    if (res) {
      return res.Status;
    }
    return false;
  };

export const GetEmailTemplate =
  (templateType) => async (dispatch: Function) => {
    const res: any = await get(
      `${api.common.emailTemplate}/${templateType}`,
      dispatch
    );
    if (res && res.Status) return res.Data;
    return null;
  };
