import React, { useRef, useState } from "react";
import { Button, Form, Input } from "element-react";
import { Link } from "react-router-dom";
import RoutesEnum from "constants/routesEnum";
import { forgotPassword } from "store/actions/user";
import { useDispatch } from "react-redux";

const PasswordReset = (props) => {
  const ref: any = useRef();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    isLoading: false,
    isSuccessSubmitting: false,
  });

  const rules = {
    email: [
      {
        required: true,
        message: "Email is required",
        trigger: "blur",
      },
      {
        type: "email",
        message: "Please input correct email address",
        trigger: "blur,change",
      },
    ],
  };

  const handleSubmit = async () => {
    ref.current.validate(async (valid) => {
      if (valid) {
        setState({ ...state, isSuccessSubmitting: true, isLoading: true });
        let res = await dispatch(forgotPassword({ email: state.email }));
        let nextState = { ...state };
        nextState.isLoading = false;
        if (res) {
          nextState.isSuccessSubmitting = true;
        }
        setState(nextState);
      }
    });
  };

  //const han
  return (
    <div className="pr-container">
      {!state.isSuccessSubmitting ? (
        <div className="reset-form">
          <Form ref={ref} rules={rules} model={state}>
            <Form.Item
              label="Enter your user account's verified email address and we will send you a password reset link."
              prop="email"
            >
              <Input
                placeholder="Enter your email address"
                value={state.email}
                onChange={(val: any) => setState({ ...state, email: val })}
              ></Input>
            </Form.Item>
            <div className="irc-form-footer">
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={state.isLoading}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <div className="reset-success">
          <h2>Reset Password</h2>
          <div className="reset-success__message">
            Check your email for a link to reset your password. If it doesn’t
            appear within a few minutes, check your spam folder.
          </div>
          <Link to={RoutesEnum.login} className="el-button el-button--primary">
            Return to sign in
          </Link>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
