import React, { useEffect } from "react";
import Attachments from "scenes/viewArticle/components/attachment";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCommenstList, PostComment } from "store/actions/comment";
import { FiUser } from "react-icons/fi";
import "./style.scss";
import moment from "moment";
import CommentEditor from "./commentEditor";
import CommentBody from "./commentBody";
import { FaPlus } from "react-icons/fa";
import { Breadcrumb, Button } from "element-react";

import { Link, NavLink, useHistory } from "react-router-dom";
import RouteEnum from "constants/routesEnum";

const Comments = (props) => {
  const [commentdata, setCommentData] = useState("");
  const [comments, setComments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [showAddComment, setAddComment] = useState(false);

  const { articleId } = props; // props.match.params;

  const dispatch = useDispatch();
  useEffect(() => {
    loadComments(articleId ?? 0);
  }, [articleId]);

  const loadComments = async (articleId) => {
    var articleComments: any = await dispatch(GetCommenstList(articleId));
    setComments(articleComments);
  };
  const onSaveClicked = async (obj) => {
    loadComments(obj.ArticleId);
    setAddComment(false);
  };
  const formatDate = (cd) => {
    return cd && moment(new Date(cd)).format("MMMM Do YYYY, h:mm:ss a");
  };

  const onSuccess = () => {
    debugger;
    loadComments(articleId);
  };
  return (
    <>
      {showAddComment ? (
        <>
          <div className="comment-container">
            <CommentEditor
              articleId={articleId}
              onSaveClicked={onSaveClicked}
              onCancel={() => setAddComment(false)}
            />
          </div>
        </>
      ) : (
        <>
          <div className="comment-container">
            {comments.length > 0 ? (
              <div className="comment-container-content">
                {comments.map((item, index) => {
                  return (
                    <>
                      <div className="comment" key={index}>
                        <div className="comment-header">
                          <div className="ic-image">
                            <FiUser />
                          </div>
                          <span className="comment-emp-name">
                            {item.FullName}
                          </span>
                          <span className="comment-date">
                            {formatDate(item.CreatedOn)}{" "}
                          </span>
                        </div>
                        <CommentBody
                          article={props.article}
                          comment={item}
                          onDelete={() => onSuccess()}
                          onSaved={() => onSuccess()}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="no-comments">No comments till now!</div>
            )}
          </div>
          <div className="add-comment">
            <div
              className="add-comment-btn"
              onClick={() => setAddComment(true)}
            >
              <FaPlus />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Comments;
