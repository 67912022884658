import axios from "axios";
import { baseURL } from "../constants/urls";
import { dispatchError } from "../helpers/dispatchError";

const getHeaders = (isMultipart = false) => {
  var header = {
    headers: {
      Authorization: localStorage.getItem("AuthToken"),
    },
  };

  if (isMultipart) {
    header.headers["content-type"] = "multipart/form-data";
  }
  return header;
};

const actionBase = axios.create({ baseURL: baseURL });

export const get = (
  url: any,
  dispatch: Function,
  param = null,
  showResponseMessage = false
) => {
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);
    actionBase
      .get(fullUrl, getHeaders())
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, resolve));
  });
};

export const get_by_id = (
  url: any,
  dispatch: Function,
  id: any,
  showResponseMessage = false
) => {
  return new Promise((resolve, reject) => {
    const fullUrl = `${url}/${id}`;
    actionBase
      .get(fullUrl, getHeaders())
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, resolve));
  });
};

export const post = (
  url: any,
  dispatch: Function,
  param: any,
  showResponseMessage = true,
  isMultipart = false
) => {
  return new Promise((resolve, reject) => {
    actionBase
      .post(url, param, getHeaders(isMultipart))
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, resolve));
  });
};

export const put = (
  url: any,
  dispatch: Function,
  param: any,
  showResponseMessage = true,
  isMultipart = false
) => {
  return new Promise((resolve, reject) => {
    actionBase
      .put(url, param, getHeaders(isMultipart))
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, resolve));
  });
};

export const deletion = (
  url: any,
  dispatch: Function,
  id: any,
  showResponseMessage = true
) => {
  return new Promise((resolve, reject) => {
    const fullUrl = `${url}/${id}`;
    actionBase
      .delete(fullUrl, getHeaders())
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, resolve));
  });
};

const getFullStringUrl = (url: any, param: any) => {
  const entries = param ? Object.entries(param) : null;
  let fullUrl = url;
  entries &&
    entries.map((entry, ind) => {
      if (ind == 0) {
        fullUrl = `${fullUrl}?${`${entry[0]}=${entry[1]}`}`;
      } else {
        fullUrl = `${fullUrl}&${`${entry[0]}=${entry[1]}`}`;
      }
    });
  return fullUrl;
};

const onSuccess = (
  res: any,
  dispatch: Function,
  resolve: any,
  showResponseMessage: any
) => {
  let response = res.data;
  if (response.Status == true) {
    if (showResponseMessage) {
      dispatchError(dispatch, response);
    }
  } else if (response.Status == undefined) {
    if (res.status == 200) {
      // resolve(response);
    } else {
      dispatchError(dispatch, "Response status is not 200");
      response = null;
    }
  } else {
    dispatchError(dispatch, response);
    response = null;
  }
  resolve(response);
};

const onFailure = (err: any, dispatch: Function, resolve: any) => {
  dispatchError(dispatch, err.message);
  resolve(null);
};
