import React, { useEffect } from 'react'
import { Editor } from 'components/form'
import UploadReviewFiles from 'scenes/common/uploadFiles'
import Attachments from 'scenes/viewArticle/components/attachment'
import { Form, Input, Button, Select, Checkbox } from 'element-react'

import { useState } from 'react'
import './style.scss'
import { FaPaperPlane, FaTimes } from 'react-icons/fa'
import { isEmpty } from 'helpers'
import { PostComment } from 'store/actions/comment'
import { useDispatch } from 'react-redux'
import { AttachmentsButton } from 'components/form'

const CommentEditor = (props) => {
  const { articleId, onSaveClicked, editData, onCancel } = props
  const [state, setState] = useState({
    id: 0,
    comment: '',
    attachments: [],
    isLoading: false,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (editData) {
      setState((prev) => ({
        ...prev,
        id: editData.Id,
        comment: editData.Comment,
        attachments: editData.Attachments,
      }))
    }
  }, [editData])

  const handleSaveClick = async () => {
    setState({ ...state, isLoading: true })

    const param = {
      id: state.id,
      ArticleId: articleId || editData?.ArticleRef_Id,
      Comment: state.comment,
      Files: state.attachments,
    }

    const res: any = await dispatch(PostComment(param))

    setState((prev) => {
      let nextState = { ...prev }
      nextState.isLoading = false
      if (res && res.Status) {
        nextState.attachments = []
        nextState.comment = ''
      }
      onSaveClicked && onSaveClicked(param)
      return nextState
    })

    //  ;
  }
  const handleChange = (name, val) => {
    debugger
    setState((prev) => ({ ...prev, [name]: val }))
  }

  return (
    <div className="comment__new">
      <Editor name="comment" onChange={handleChange} value={state.comment} />

      <AttachmentsButton
        multiple
        value={state.attachments}
        name="attachments"
        onChange={handleChange}
        validExtensions={[
          'pdf',
          'doc',
          'docx',
          'jpeg',
          'jpg',
          'png',
          'xl',
          'xls',
        ]}
      />
      <div className="comment__new-footer">
        <div className="comment__new-footer-attachments"></div>
        <div className="comment__new-footer-submit">
          <Button className="btn-cancel" type="danger" onClick={onCancel}>
            <FaTimes />
            Cancel
          </Button>

          <Button
            type="primary"
            onClick={handleSaveClick}
            loading={state.isLoading}
          >
            <FaPaperPlane />
            Save Comment
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CommentEditor
