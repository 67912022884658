import React, { useEffect, useRef, useState } from 'react'
import {
  Dialog,
  Button,
  Input,
  Radio,
  Message,
  DatePicker,
  Form,
} from 'element-react'
import { FaPlus, FaMinus, FaUserPlus } from 'react-icons/fa'
import TableView from 'components/tableView'
import {
  SaveReview,
  GetReviewDetails,
  userWithAssignedInfo,
  AssignToReviewers,
} from 'store/actions/articles'
import { GetEmailTemplate } from 'store/actions/common'

import { useDispatch } from 'react-redux'
import RoleEnum from 'constants/roleEnum'
import EmailTemplateEnum from 'constants/emailTemplateEnum'
import { getRequiredRules, isEmpty } from 'helpers'
import { dateFormat } from 'helpers/dateHelper'
import Loader from 'components/loader.tsx'
import { Editor } from 'components/form'
import ReviewerInput from './reviewerInput'

const AssignReviewer = (props) => {
  const { onCancel, row } = props
  const [selectedReviewer, setSelectedReviewer] = useState(null)
  var formRef: any = useRef()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    reveiwer: '',
    loading: false,
    responseDueDate: null,
    reviewDueDate: null,
    emailText: '',
    reviewers: [],
    isLoading: false,
  })

  useEffect(() => {
    // const reviewId = row && row.ReviewId;
    // if (reviewId) loadReview(reviewId);
  }, [row])

  useEffect(() => {
    loadEmailTemplate()
  }, [])

  const rules = {
    responseDueDate: [getRequiredRules('Response due date is required')],
    reviewDueDate: [getRequiredRules('Review due date is required')],
    reviewers: [getRequiredRules('Reviewer is required', 'array')],
    emailText: [getRequiredRules('Email text is required.')],
  }

  const submit = async () => {
    formRef.current.validate(async (valid) => {
      if (valid) {
        setState({ ...state, loading: true })

        const res = await dispatch(
          AssignToReviewers({
            reviewId: props && props.row.ReviewId,
            articleId: props && props.row.Id,
            reviewers: state.reviewers && state.reviewers.map((x) => x.Id),
            assignedAs: RoleEnum.Reviewer,
            reviewDueDate: state.reviewDueDate,
            responseDueDate: state.responseDueDate,
            emailText: state.emailText,
          }),
        )
        props.onSuccess && props.onSuccess()

        setState({ ...state, loading: false })
      }
    })
  }

  const onChangeDate = (name, date) => {
    console.clear()
    console.log(date, dateFormat(date))
    setState({ ...state, [name]: dateFormat(date) })
  }
  const onChange = (name, value) => {
    setState({ ...state, [name]: value })
  }

  const loadEmailTemplate = async () => {
    var res: any = await dispatch(
      GetEmailTemplate(EmailTemplateEnum.AssingReviewArticle),
    )
    setState({ ...state, emailText: res.BodyText })
  }

  const loadReview = async (reviewId) => {
    setState({ ...state, isLoading: true })
    const res: any = await dispatch(GetReviewDetails(reviewId))

    if (res) {
      setSelectedReviewer({ FullName: res.ReviewerName, Id: res.ReviewerId })

      setState({
        ...state,
        reveiwer: res.ReviewerId,
        reviewDueDate: res.ReviewDueDate,
        responseDueDate: res.ResponsDueDate,
        emailText: res.EmailText,
        isLoading: false,
      })
    } else setState({ ...state, isLoading: false })
  }
  return (
    <Loader isLoading={state.isLoading}>
      <Form
        ref={formRef}
        rules={rules}
        model={state}
        className="demo-form-stacked"
        labelPosition="top"
        labelWidth="100"
      >
        <Form.Item label="Reviewer" prop="reviewers">
          <ReviewerInput
            name="reviewers"
            {...props}
            onChange={onChange}
            setSelectedReviewer={setSelectedReviewer}
            selectedReviewer={selectedReviewer}
          />
        </Form.Item>

        <Form.Item label="Email to be sent to reviewer" prop="emailText">
          <Editor
            name="emailText"
            value={state.emailText}
            onChange={onChange}
          />
        </Form.Item>
        <div className="flex">
          <Form.Item
            className="margin-right-10"
            label="Response Due Date"
            prop="responseDueDate"
          >
            <DatePicker
              value={state?.responseDueDate && new Date(state.responseDueDate)}
              placeholder="Response Due Date"
              onChange={(date) => onChangeDate('responseDueDate', date)}
              disabledDate={(time) => time.getTime() < Date.now() - 8.64e7}
            />
          </Form.Item>
          <Form.Item label="Review Due Date" prop="reviewDueDate">
            <DatePicker
              value={state?.reviewDueDate && new Date(state.reviewDueDate)}
              placeholder="Review Due Date"
              onChange={(date) => onChangeDate('reviewDueDate', date)}
              disabledDate={(time) => time.getTime() < Date.now() - 8.64e7}
            />
          </Form.Item>
        </div>

        <div className="flex-end">
          <Button type="primary" onClick={submit} loading={state.loading}>
            Save
          </Button>
          <Button type="danger" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Loader>
  )
}

export default AssignReviewer
