//@ts-nocheck
import React from "react";
import { Menu, Layout as ELayout } from "element-react";
import AdminSideBar from "./adminSideBar";
const AdminLayout = (props) => {
  return (
    <ELayout.Row className="admin-layout">
      <ELayout.Col span={4}>
        <AdminSideBar {...props} />
      </ELayout.Col>
      <ELayout.Col span={20}>
        <div className="admin-container">{props.children}</div>
      </ELayout.Col>
    </ELayout.Row>
  );
};

export default AdminLayout;
