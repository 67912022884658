import React, { useEffect, useState } from "react";
import "./styles.scss";

import { FiDownload } from "react-icons/fi";
import InnerImageZoom from "react-inner-image-zoom";

// import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import Dialog from "components/popup/dialog";
function ImageViewer(props) {
  const { image, dowloadLinkUrl, title, show, hostedUrl, onClose } = props;

  let imageSrc = image ? `${hostedUrl}/${image.serverFileName || ""}` : "";

  return (
    show && (
      <Dialog onClose={onClose} open={show}>
        {image && (
          <div className="image-modal__container">
            <span
              onClick={(e) => {
                e.stopPropagation();
                window.open(dowloadLinkUrl);
              }}
              className="image-modal__download"
            >
              <FiDownload />
            </span>
            <InnerImageZoom src={imageSrc} zoomSrc={imageSrc} zoomScale={1.5} />
          </div>
        )}
      </Dialog>

      // <Modal
      //   open={props.show}
      //   title={props.title || ""}
      //   onModalClose={() => props.onClose(false)}
      //   hideFooter={true}
      //   className="uploads-modal image-modal"
      //   // width="800px"
      // >

      // </Modal>
    )
  );
}

export default ImageViewer;
