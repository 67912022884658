import classNames from "classnames";
import React, { Children } from "react";

const Loader = ({ isLoading, children }) => {
  return (
    <div className={classNames({ "loader-container": isLoading })}>
      {isLoading && (
        <div className="loader-loading">
          <div className="loader-loading__symbol">
            <div className="spinner"></div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Loader;
