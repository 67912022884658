import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";

import { Form, Input, Button, Select } from "element-react";
import { login } from "store/actions/auth";
import Routes from "constants/routesEnum";

const Login = () => {
  const [state, setState] = useState({
    username: "",
    password: "",
    // username: "admin@admin.com",
    // password: "Admin@123",
    role: "",
  });
  const rules = {
    username: [
      {
        required: true,
        message: "Please input user name",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: "Please input password",
        trigger: "blur",
      },
    ],
    role: [
      {
        required: true,
        message: "Please input user role",
        trigger: "blur",
      },
    ],
  };
  const { roles } = useSelector((state: any) => state.commonReducer);
  var formRef: any = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const onChange = (key: any, value: any) => {
    setState({ ...state, [key]: value });
  };
  const handleLoginClick = async (e: any) => {
    e.preventDefault();

    formRef.current.validate(async (valid) => {
      if (valid) {
        await dispatch(login(state, history));
      } else {
        return false;
      }
    });
  };
  return (
    <div className="container">
      <div className="login-form">
        <div className="left-side">
          <div className="left-header">
            <h1>Welcome, </h1>
            <div className="left-detail-section">
              <h2>B&B IRC </h2>
              <i>
                <h3>"Research on medical science by B&B</h3>
              </i>
            </div>
          </div>
        </div>
        <div className="right-side">
          <Form
            ref={formRef}
            rules={rules}
            className="demo-form-stacked"
            model={state}
            labelPosition="top"
            labelWidth="100"
          >
            <Form.Item label="Email" prop="username">
              <Input
                placeholder="Email"
                value={state.username}
                onChange={(val: any) => onChange("username", val)}
              ></Input>
            </Form.Item>
            <Form.Item label="Password" prop="password">
              <Input
                placeholder="Password"
                type="password"
                value={state.password}
                onChange={(val: any) => onChange("password", val)}
              ></Input>
            </Form.Item>
            <Form.Item label="Log in as" prop="role">
              <Select
                value={state.role}
                placeholder="Role"
                onChange={(val: any) => onChange("role", val)}
              >
                {roles &&
                  roles.map((role, index) => (
                    <Select.Option
                      key={index}
                      label={role.Name}
                      value={role.Name}
                    ></Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={handleLoginClick}
                nativeType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
          <div className="login-form__forgot">
            <span className="forgot-link">
              <Link to={Routes.resetPassword}>Forgot Password?</Link>
            </span>
            <span className="forgot-link">
              <Link to={Routes.signup}>Sign up</Link>
            </span>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
export default Login;
