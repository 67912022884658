import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Layout, Table, Select } from "element-react";
import { AttachmentButton } from "components/form";
import EntryForm from "./entryForm";
import { SaveAuthors } from "store/actions/articles";
import { useDispatch, useSelector } from "react-redux";
import { emailRule, getRequiredRules, isEmpty, mobileRules } from "helpers";
import CoAuthorList from "./coAuthorList";
import ArticleStatusEnum from "constants/articleStatusEnum";

const Authors = (props) => {
  const { onSave } = props;
  const formRef: any = useRef();
  const dispatch = useDispatch();
  const rules = {
    FullName: [getRequiredRules("Full name is required.")],
    Salutation: [getRequiredRules("Salutation is required.")],
    Designation: [getRequiredRules("Designation is required.")],
    Institute: [getRequiredRules("Institute is required.")],
    PostalAddress: [getRequiredRules("Address is required.")],
    Email: [getRequiredRules("Email is required."), emailRule()],
    // Photo_UserFileName: [getRequiredRules("Photo is required.")],
    // DigitalSignature_UserFileName: [getRequiredRules("Signature is required.")],
    MobileNo: [mobileRules()],
  };

  const [isLoading, setLoading] = useState(false);

  const [coAuthorData, setCoAuthorData] = useState({
    index: -1,
    isVisible: false,
  });

  const [state, setState] = useState({
    ArticleId: 0,
    FullName: "",
    Salutation: "",
    Designation: "",
    Institute: "",
    PostalAddress: "",
    MobileNo: "",
    Email: "",
    Photo_ServerFileName: "",
    Photo_UserFileName: "",
    Photo: null,
    DigitalSignature_ServerFileName: "",
    DigitalSignature_UserFileName: "",
    DigitalSignature: null,
    Steps: 2,
    CoAuthors: [],
  });

  const { article } = useSelector((st: any) => st.articleReducer);
  const { salutations } = useSelector((st: any) => st.metaReducer);

  useEffect(() => {
    if (!isEmpty(article)) {
      setState({
        ...state,
        ArticleId: article.Id,
        FullName: article.InvestigatorName,
        Salutation: article.InvestigatorSalutation,
        Designation: article.InvestigatorDesignation,
        Institute: article.InvestigatorInstitute,
        PostalAddress: article.InvestigatorPostalAddress,
        MobileNo: article.InvestigatorMobileNo,
        Email: article.InvestigatorEmail,
        Photo_ServerFileName: article.InvestigatorPhoto_ServerFileName,
        Photo_UserFileName: article.InvestigatorPhoto_UserFileName,
        Photo: {
          serverFileName: article.InvestigatorPhoto_ServerFileName,
          name: article.InvestigatorPhoto_UserFileName,
        },
        DigitalSignature_ServerFileName:
          article.InvestigatorDigitalSignature_ServerFileName,
        DigitalSignature_UserFileName:
          article.InvestigatorDigitalSignature_UserFileName,
        DigitalSignature: {
          serverFileName: article.InvestigatorDigitalSignature_ServerFileName,
          name: article.InvestigatorDigitalSignature_UserFileName,
        },
        CoAuthors:
          article.ArticleCoAuthors &&
          article.ArticleCoAuthors.map((x) => ({
            ...x,
            Photo: !isEmpty(x.Photo_ServerFileName) && {
              serverFileName: x.Photo_ServerFileName,
              name: x.Photo_UserFileName,
            },
            DigitalSignature: !isEmpty(x.DigitalSignature_ServerFileName) && {
              serverFileName: x.DigitalSignature_ServerFileName,
              name: x.DigitalSignature_UserFileName,
            },
          })),
      });
    }
  }, [article]);

  const onChange = (name, val) => {
    let nextState = { ...state, [name]: val };
    if (val && name === "Photo") nextState.Photo_UserFileName = val.name;
    if (val && name === "DigitalSignature")
      nextState.DigitalSignature_UserFileName = val.name;

    setState(nextState);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    formRef.current.validate(async (valid) => {
      if (valid) {
        //await dispatch(GetRoles());
        let data = new FormData();

        for (var k in state) {
          if (k !== "CoAuthors") {
            state[k] && data.append(`${k}`, state[k]);
          }
        }
        state.CoAuthors &&
          state.CoAuthors.forEach((el, index) => {
            for (var k in el) {
              if (k !== "CoAuthors")
                el[k] && data.append(`CoAuthors[${index}].${k}`, el[k]);
            }
            //formD
          });

        setLoading(true);
        var res: any = await dispatch(SaveAuthors(data));
        setLoading(false);
        if (res && res.Status) onSave();
      } else {
        return false;
      }
    });
  };
  const handleReset = (e: any) => {
    formRef.current.resetFields();
    setState({} as any);
  };

  const handleCoAuthorSave = (index, obj) => {
    let nextState = { ...state, CoAuthors: [...state.CoAuthors] };
    if (index >= 0) {
      nextState.CoAuthors[index] = { ...obj };
    } else {
      nextState.CoAuthors.push({ ...obj });
    }

    setState(nextState);
    setCoAuthorData({ index: -1, isVisible: false });
    //  setVisible(false);
  };

  const handleCoAuthorEdit = (index, obj) => {
    setCoAuthorData({ index: index, isVisible: true });
  };
  const handleCoAuthorDelete = (index, obj) => {
    if (index >= 0) {
      let nextState = { ...state, CoAuthors: [...state.CoAuthors] };
      nextState.CoAuthors.splice(index, 1);
      setState(nextState);
    }
  };
  const checkIfDisabled = () => {
    if (article.Id != 0 && !article.CanEdit) return true;
    return article.Status === ArticleStatusEnum.SUBMITTED;
  };

  return (
    <div style={{ width: "900px" }}>
      <Form
        ref={formRef}
        rules={rules}
        className="demo-form-stacked"
        model={state}
        labelPosition="top"
        labelWidth="100"
      >
        <div className="author-steps__authors">
          <Layout.Row gutter="20">
            <Layout.Col span="12">
              <Form.Item label="Salutation" prop="Salutation">
                <Select
                  value={state.Salutation}
                  placeholder="Salutation"
                  onChange={(val: any) => onChange("Salutation", val)}
                >
                  {salutations &&
                    salutations.map((item, index) => (
                      <Select.Option
                        key={index}
                        label={item}
                        value={item}
                      ></Select.Option>
                    ))}
                </Select>
                {/* <Input
                  value={state.Salutation}
                  onChange={(val: any) => onChange("Salutation", val)}
                ></Input> */}
              </Form.Item>
            </Layout.Col>
          </Layout.Row>
          <Layout.Row gutter="20">
            <Layout.Col span="12">
              <Form.Item label="Full Name" prop="FullName">
                <Input
                  value={state.FullName}
                  onChange={(val: any) => onChange("FullName", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item label="Email" prop="Email">
                <Input
                  value={state.Email}
                  onChange={(val: any) => onChange("Email", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
          </Layout.Row>

          <Layout.Row gutter="20">
            <Layout.Col span="12">
              <Form.Item label="Mobile" prop="MobileNo">
                <Input
                  value={state.MobileNo}
                  onChange={(val: any) => onChange("MobileNo", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>

            <Layout.Col span="12">
              <Form.Item label="Address" prop="PostalAddress">
                <Input
                  value={state.PostalAddress}
                  onChange={(val: any) => onChange("PostalAddress", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
          </Layout.Row>
          <Layout.Row gutter="20">
            <Layout.Col span="12">
              <Form.Item label="Institute" prop="Institute">
                <Input
                  value={state.Institute}
                  onChange={(val: any) => onChange("Institute", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>

            <Layout.Col span="12">
              <Form.Item label="Designation" prop="Designation">
                <Input
                  value={state.Designation}
                  onChange={(val: any) => onChange("Designation", val)}
                ></Input>
              </Form.Item>
            </Layout.Col>
          </Layout.Row>
          <Layout.Row>
            <Layout.Col span="12">
              <Form.Item prop="Photo_UserFileName">
                <AttachmentButton
                  label="Photo"
                  value={state.Photo}
                  name="Photo"
                  onChange={onChange}
                  validExtensions={["jpg", "jpeg", "png"]}
                />
              </Form.Item>
            </Layout.Col>
            <Layout.Col span="12">
              <Form.Item prop="DigitalSignature_UserFileName">
                <AttachmentButton
                  label="Digital Signature"
                  value={state.DigitalSignature}
                  name="DigitalSignature"
                  onChange={onChange}
                  validExtensions={["jpg", "jpeg", "png"]}
                />
              </Form.Item>
            </Layout.Col>
          </Layout.Row>
        </div>
        <div className="author-steps__co-authors">
          <div className="author-steps__cauthors">
            <Button
              onClick={() => setCoAuthorData({ index: -1, isVisible: true })}
            >
              Add CoAuthors
            </Button>
          </div>

          <CoAuthorList
            CoAuthors={state.CoAuthors}
            onDelelteClicked={handleCoAuthorDelete}
            onEditClicked={handleCoAuthorEdit}
          />
        </div>

        <Form.Item>
          <Button
            type="primary"
            onClick={handleSubmit}
            nativeType="submit"
            loading={isLoading}
            disabled={checkIfDisabled()}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Form>

      <EntryForm
        coAuthorData={coAuthorData}
        coAuthorList={state.CoAuthors}
        onSave={handleCoAuthorSave}
        onCancel={() => setCoAuthorData({ index: -1, isVisible: false })}
      />
    </div>
  );
};

export default Authors;
