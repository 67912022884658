import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../layout";

import Login from "scenes/auth/login";

import routes from "routes";

import NotFound from "scenes/notFound";

import submission from "scenes/submitter/submission";
import Submitter from "scenes/submitter";
import User from "scenes/Administrator/user";

import { UserDetailInterface } from "interfaces/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./privateRoute";
import BaseRoute from "./baseRoute";
import { GetRoles } from "store/actions/common";

const Links = (props: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetRoles());
  }, []);
  return (
    <>
      <Layout>
        <Switch>
          {routes.map((route, index) => {
            return <PrivateRoute key={index} {...route} />;
          })}
          <Route exact path="/login" component={Login} />
          <BaseRoute exact path="/" component={NotFound} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </>
  );
};
export default Links;
