import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select, Checkbox } from "element-react";
import { CheckList, Wrapper } from "components/form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AcknowledgeReview } from "store/actions/articles";
import { isEmpty } from "helpers";
import ArticleStatusEnum from "constants/articleStatusEnum";
import Attachments from "../attachments";
import { dateFormat } from "helpers/dateHelper";
import { useHistory } from "react-router";
import RouteEnum from "constants/routesEnum";
import Loader from "components/loader.tsx";
import ReviewRecommendationEnum from "constants/reviewRecommendation";
import ReviewStatusEnum from "constants/reviewStatusEnum";

const Start = (props) => {
  var ref: any = useRef();
  const { onSave } = props;
  const history = useHistory();
  const [state, setState] = useState({
    ArticleId: 0,
    SectionId: 0,
    CheckList: [],
  });
  const [isLoading, setLoading] = useState(false);
  const [ckBody, setCkBody] = useState("");
  const rules = {};
  const dispatch = useDispatch();

  const { checkList, templates, sections } = useSelector(
    (state: any) => state.metaReducer
  );

  const { review } = useSelector((st: any) => st.articleReducer);

  const acceptAndContinue = async () => {
    //AcknowledgeReview
    setLoading(true);
    const res: any = await dispatch(
      AcknowledgeReview(review.ReviewId, ReviewStatusEnum.ACCEPTED)
    );
    setLoading(false);
    if (res.Status) {
      onSave && onSave();
    }
  };
  const decline = async () => {
    //AcknowledgeReview
    setLoading(true);
    const res: any = await dispatch(
      AcknowledgeReview(review.ReviewId, ReviewStatusEnum.DECLINED)
    );
    setLoading(false);
    if (res.Status) history.push(RouteEnum.base);
  };

  if (isEmpty(review)) return null;

  return (
    <Loader isLoading={isLoading}>
      <div className="review">
        <Wrapper label="Request for Review">
          You have been selected as a potentential reviewer of the following
          submission.
        </Wrapper>
        <Wrapper label="ArticleTitle">{review.ArticleTitle}</Wrapper>
        <Wrapper label="Abstract">
          <p>
            The study examines the process of intstitutionalization as it has
            occured for the scholarly Publishing Servive Program(SPSP), an
            evolution of the library activities at the University of Anywhere.
            As an innovation which challenges many of the conventions of
            library-based publishing, it is necessary to apporach the evaluation
            of SPSP with a concern for appropriate methods and a consideration
            of issues of legitimacy in the analysis then provides a basis for
            recasting evaluation as a political process where innovative
            programs struggle to maintain those exemplars which make them
            different while acheiving thw legitimacy which make them possible.
          </p>
        </Wrapper>

        <Wrapper>
          <Attachments
            files={review.SubmissoinFiles}
            title="Submission Files"
          />
        </Wrapper>
        <Wrapper label="Review Schedule">
          <div className="review-reviewDate">
            <div className="review-reviewDate-info">
              <span className="value">{dateFormat(review.CreatedOn)}</span>
              <span className="label">Editor's Request</span>
            </div>
            <div className="review-reviewDate-info">
              <span className="value">{dateFormat(review.ResponsDueDate)}</span>
              <span className="label">Response Due Date</span>
            </div>
            <div className="review-reviewDate-info">
              <span className="value">{dateFormat(review.ReviewDueDate)}</span>
              <span className="label">Review Due Date</span>
            </div>
          </div>
        </Wrapper>

        <Wrapper>
          <Button
            type="primary"
            onClick={acceptAndContinue}
            loading={isLoading}
            disabled={review.IsAccepted || review.IsCompleted}
          >
            Accept Review and Continue
          </Button>
          <Button
            type="danger"
            loading={isLoading}
            onClick={decline}
            disabled={review.IsAccepted || review.IsCompleted}
          >
            {/* Decline Review Request */}
            Reject Submission
          </Button>
        </Wrapper>
      </div>
    </Loader>
  );
};
export default Start;
