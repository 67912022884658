import React, { useEffect, useState } from "react";
import { Button } from "element-react";
import UploadFile from "./uploadFile";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "helpers";
import { FiFile } from "react-icons/fi";
import ArticleStatusEnum from "constants/articleStatusEnum";

const UploadFiles = (props) => {
  const { onSave, attachments, setAttachments, title, isDisabled } = props;
  const [isVisible, setVisible] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState({
    data: null,
    index: -1,
  });
  const dispatch = useDispatch();

  const { article } = useSelector((st: any) => st.articleReducer);

  useEffect(() => {
    if (!isEmpty(article)) {
      let attachments = article.ArticleAttachments || [];
      setAttachments([...attachments]);
    }
  }, [article]);

  const handleSaveFile = (index, obj) => {
    let newAttachments = [...attachments];
    if (index >= 0) newAttachments[index] = obj;
    else newAttachments.push(obj);

    setAttachments(newAttachments);
    setVisible(false);
  };

  const handleAttachmentEditClick = (index) => {
    setSelectedFileData({ index: index, data: { ...attachments[index] } });
    setVisible(true);
  };

  const handleAttachmentDeleteClick = (index) => {
    let newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  return (
    <div className="upload-steps__submissions">
      <div className="upload-steps__submissions-header">
        <div>{title}</div>
        <div className="upload-steps__submissions-header-action">
          <div>
            <Button
              type="text"
              disabled={isDisabled}
              onClick={() => {
                setSelectedFileData({ index: -1, data: null });
                setVisible(true);
              }}
            >
              Upload File
            </Button>
          </div>
        </div>
      </div>

      <div className="upload-steps__submissions-body">
        {attachments.length === 0 && (
          <div style={{ padding: "10px", textAlign: "center" }}>
            No files choosen
          </div>
        )}
        {attachments.map((attachment, index) => (
          <div className="upload-steps__attachment" key={index}>
            <div className="upload-steps__attachment-info">
              <div className="upload-steps__attachment-info-title">
                <FiFile />
                {attachment.UserFileName
                  ? attachment.UserFileName
                  : attachment.Attachment && attachment.Attachment.name}
              </div>
              {/* <div className="upload-steps__attachment-info-subtitle">
                Component: {attachment.ComponentName}
              </div> */}
            </div>
            <div className="upload-steps__attachment-action">
              <Button
                type="text"
                onClick={() => handleAttachmentEditClick(index)}
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => handleAttachmentDeleteClick(index)}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>

      <UploadFile
        title={title}
        isVisible={isVisible}
        setVisible={setVisible}
        selectedFileData={selectedFileData}
        onCancel={() => {
          setVisible(false);
          setSelectedFileData({ index: -1, data: null });
        }}
        onSave={(index, obj) => handleSaveFile(index, obj)}
      />
      <div className="upload-steps__submissions-footer"></div>
    </div>
  );
};
export default UploadFiles;
