import React, { useEffect, useState } from "react";
import { Button, Dialog, Progress } from "element-react";
import { useDispatch } from "react-redux";
import {
  GetReviewByArticleId,
  RequestRevision,
  DeleteReviewer,
} from "store/actions/articles";
import { Wrapper } from "components/form";
import Attachments from "scenes/common/attachments";
import { createMarkup, isEmpty } from "helpers";
import { FaUserPlus, FaTrash } from "react-icons/fa";
import ReviewStatusEnum from "constants/reviewStatusEnum";
import classNames from "classnames";
import moment from "moment";

const ReviewCard = ({ review, handleOnDelete }) => {
  const [state, setState] = useState({ isLoading: false });
  const dispatch = useDispatch();

  const getCard = (title, subtitle) => {
    return (
      title && (
        <div className="box">
          <span className="box-title">{moment(title).format("ll")}</span>
          <span className="box-subtitle">{subtitle}</span>
        </div>
      )
    );
  };
  const getStatus = () => {
    let statusText = "";
    let backColorClass = "gray";
    const { RecommendationId, Recommendation, ReviewStatus } = review;

    if (ReviewStatus === 0) {
      statusText = "Waiting for Acceptance";
      backColorClass = "gray";
    }
    // else if (!isEmpty(RecommendationId) && RecommendationId !== 0) {
    //   statusText = Recommendation;
    //   if (RecommendationId == 1) {
    //     backColorClass = "green";
    //   } else if (RecommendationId === 2 || RecommendationId === 4) {
    //     backColorClass = "yellow";
    //   } else {
    //     backColorClass = "red";
    //   }
    // }
    else if (ReviewStatus === ReviewStatusEnum.COMPLETED) {
      statusText = "Review Submitted";
      backColorClass = "green";
    } else if (ReviewStatus === ReviewStatusEnum.DECLINED) {
      statusText = "Review Declined";
      backColorClass = "red";
    } else if (
      ReviewStatus === ReviewStatusEnum.ACCEPTED ||
      ReviewStatus === ReviewStatusEnum.UNDERREVIEW
    ) {
      statusText = "Under Review";
      backColorClass = "yellow";
    }
    return (
      <span
        className={classNames("review-status", {
          [backColorClass]: true,
        })}
      >
        {statusText}
      </span>
    );
  };
  const reviewBody = () => {
    return (
      <>
        <div className="reviewdetail__reviewers-body">
          {!isEmpty(review.CommentsForEditor) && (
            <Wrapper label="Comment for Editor">
              <div
                className="ck-editor-content"
                dangerouslySetInnerHTML={createMarkup(review.CommentsForEditor)}
              />
            </Wrapper>
          )}
          {!isEmpty(review.CommentsForEditorAndAuthor) && (
            <Wrapper label="Comment for Editor and Author">
              <div
                className="ck-editor-content"
                dangerouslySetInnerHTML={createMarkup(
                  review.CommentsForEditorAndAuthor
                )}
              />
            </Wrapper>
          )}

          {review.ReviewFiles && review.ReviewFiles.length > 0 && (
            <Wrapper>
              <Attachments files={review.ReviewFiles} title="Review Files" />
            </Wrapper>
          )}
        </div>
      </>
    );
  };

  const onRemoveClick = async () => {
    setState({ ...state, isLoading: true });
    await dispatch(DeleteReviewer(review.ReviewerId));
    handleOnDelete && handleOnDelete(review.ReviewerId);
    setState({ ...state, isLoading: false });
  };

  return (
    <div className="reviewdetail__reviewers">
      <div className="reviewdetail__reviewers-name">
        Reviewer: {review.ReviewerName}
      </div>

      <div className="reviewdetail__reviewers-info">
        {getCard(review.CreatedOn, "Requested On")}
        {getCard(review.ResponsDueDate, "Response Due Date")}
        {getCard(review.ReviewDueDate, "Review Due Date")}
        {getCard(review.AcceptedOn, "Accepted On")}
        {getCard(review.CompletedOn, "Completed On")}
      </div>
      {reviewBody()}
      {!isEmpty(review.RecommendationId) && review.RecommendationId > 0 && (
        <Wrapper label="Review recommendation">{review.Recommendation}</Wrapper>
      )}
      <div className="reviewdetail__reviewers-sblock">
        <div className="article-card__status-progress">
          <Progress
            type="circle"
            strokeWidth={12}
            percentage={(review.Steps / 4) * 100}
          />
        </div>
        {getStatus()}
      </div>

      <div className="reviewdetail__reviewers-actionblock">
        <Button
          size="mini"
          type="danger"
          onClick={onRemoveClick}
          loading={state.isLoading}
        >
          <FaTrash />
          Remove Reviewer
        </Button>
      </div>
    </div>
  );
};
export default ReviewCard;
