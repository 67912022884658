import TemplateEnum from "constants/templateEnum";
import { Button } from "element-react";
import React from "react";
import { useSelector } from "react-redux";
import { createMarkup } from "helpers";
const Guidelines = (props) => {
  const { onSave } = props;
  const { templates } = useSelector((state: any) => state.metaReducer);

  const ReviewGuideline = templates.find(
    (x) => x.TemplateKey === TemplateEnum.ReviewGuideline
  );
  const handleNextClick = async () => {
    onSave && onSave();
  };
  return (
    <>
      {ReviewGuideline && (
        <div
          className="template"
          dangerouslySetInnerHTML={createMarkup(ReviewGuideline.TemplateText)}
        ></div>
      )}
      <Button type="primary" onClick={handleNextClick}>
        Continue
      </Button>
    </>
  );
};
export default Guidelines;
