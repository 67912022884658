import ArticleStatusEnum from "constants/articleStatusEnum";
import RoleEnum from "constants/roleEnum";
import React from "react";
import { toastMessage } from "../store/actions/validateAction";
import isEmpty from "./isEmpty";

export function getStatus(status) {
  switch (status) {
    case 1:
      return "NEW";
    case 2:
      return "SUBMITTED";
    case 3:
      return "REVERTED";
    case 4:
      return "REJECTED";
    case 5:
      return "APPROVED";
    case 6:
      return "ASSIGNED EDITOR";
    case 7:
      return "ASSIGNED REVIEWER";
    case 8:
      return "REVEIW ACEEPTED";
    case 9:
      return "REVIEW REJECTED";
    case 10:
      return "REVIEW COMPLETED";

    default:
      return "NEW";
  }
}

export function renderStatus(
  article,
  role,
  status,
  recommendation = "",
  noOfReviewWaitingAcceptance = 0
) {
  let res: any = {};

  if (role === RoleEnum.Submitter)
    res = statusTextForSubmitter(status, recommendation);
  else if (role === RoleEnum.Reviewer)
    res = statusTextForReviewer(article, status, recommendation);
  else if (role === RoleEnum.Editor)
    res = statusTextForEditor(status, recommendation);
  else res = getStatusText(status, recommendation, noOfReviewWaitingAcceptance);

  return !isEmpty(res) ? (
    <div className={`article-card__status-block ${res.color}`}>
      <span>{res.text}</span>
    </div>
  ) : null;
}

const getStatusText = (
  status,
  recommendation = "",
  noOfReviewWaitingAcceptance = 0
) => {
  if (status === ArticleStatusEnum.REVISION) {
    if (recommendation === "Revision Required")
      return { text: "Revision required", color: "yellow" };
    if (recommendation === "Decline Submission")
      return { text: "Review declined", color: "red" };
    if (recommendation === "See Comments")
      return { text: "Request to see comments", color: "yellow" };
    return { text: "Revision required", color: "yellow" };
  }

  if (
    status === ArticleStatusEnum.SUBMITTED ||
    status === ArticleStatusEnum.RESUBMITTED
  )
    return { text: "Submission", color: "blue" };

  if (status === ArticleStatusEnum.APPROVED)
    return { text: "Approved", color: "green" };

  if (status === ArticleStatusEnum.FINALAPPROVED)
    return { text: "Final Approved", color: "green" };

  if (status === ArticleStatusEnum.FINALREJECTED)
    return { text: "Final Rejected", color: "red" };

  if (status === ArticleStatusEnum.REJECTED)
    return { text: "Declined", color: "red" };

  if (status === ArticleStatusEnum.ASSIGNEDEDITOR)
    if (noOfReviewWaitingAcceptance === 0)
      return {
        text: "Editor Assigned. Need to assign Reviewer",
        color: "yellow",
      };
    else
      return {
        text: "Editor Assigned. Waiting for Reviewer Acceptance",
        color: "yellow",
      };

  if (status === ArticleStatusEnum.REVIEWING)
    return { text: "Under review", color: "yellow" };

  if (status === ArticleStatusEnum.REVIEWED) {
    if (recommendation === "Accept Submission")
      return { text: "Submisson accepted", color: "green" };
    if (recommendation === "Revision Required")
      return { text: "Revision required", color: "yellow" };
    if (recommendation === "Decline Submission")
      return { text: "Review declined", color: "red" };
    if (recommendation === "See Comments")
      return { text: "Request to see comments", color: "yellow" };
    return { text: "Reviewd", color: "green" };
  }
};
const statusTextForSubmitter = (status, recommendation = "") => {
  if (status === ArticleStatusEnum.NEW)
    return { text: "Need to submit", color: "blue" };

  if (status === ArticleStatusEnum.REVISION) {
    if (recommendation === "Revision Required")
      return { text: "Revision required", color: "yellow" };
    if (recommendation === "Decline Submission")
      return { text: "Review declined", color: "red" };
    if (recommendation === "See Comments")
      return { text: "Request to see comments", color: "" };
    return { text: "Revision required", color: "yellow" };
  }
  if (status === ArticleStatusEnum.APPROVED)
    return { text: "Approved", color: "green" };

  if (status === ArticleStatusEnum.REJECTED)
    return { text: "Declined", color: "red" };
    
  if (status === ArticleStatusEnum.FINALAPPROVED)
    return { text: "Final Approved", color: "green" };

  if (status === ArticleStatusEnum.FINALREJECTED)
    return { text: "Final Rejected", color: "red" };

  return { text: "Under review", color: "yellow" };
};

const statusTextForEditor = (status, recommendation = "") => {
  if (status === ArticleStatusEnum.ASSIGNEDEDITOR)
    return {
      text: "Submission",
      color: "blue",
    };

  if (status === ArticleStatusEnum.REVIEWING)
    return {
      text: "Under review",
      color: "yellow",
    };

  if (status === ArticleStatusEnum.REVISION)
    return {
      text: "Revision required",
      color: "green",
    };

  if (status === ArticleStatusEnum.REVIEWED) {
    if (recommendation === "Accept Submission")
      return { text: "Submisson accepted", color: "green" };
    if (recommendation === "Revision Required")
      return { text: "Revision required", color: "yellow" };
    if (recommendation === "Decline Submission")
      return { text: "Review declined", color: "red" };
    if (recommendation === "See Comments")
      return { text: "Request to see comments", color: "yellow" };
    return { text: "Reviewed", color: "green" };
  }
  if (status === ArticleStatusEnum.APPROVED)
    return { text: "Approved", color: "green" };

  if (status === ArticleStatusEnum.REJECTED)
    return { text: "Declined", color: "red" };
  if (status === ArticleStatusEnum.FINALAPPROVED)
    return { text: "Final Approved", color: "green" };

  if (status === ArticleStatusEnum.FINALREJECTED)
    return { text: "Final Rejected", color: "red" };
};

const statusTextForReviewer = (article, status, recommendation = "") => {
  // if (status === ArticleStatusEnum.REVIEWING)
  //   return {
  //     text: "Under review",
  //     color: "yellow",
  //   };

  const { ReviewStatus } = article;
  if (status === ArticleStatusEnum.REVIEWING) {
    if (ReviewStatus === 0) {
      return { text: "New", color: "blue" };
    }
    if (ReviewStatus === 2 || ReviewStatus === 1) {
      return { text: "Under review", color: "yellow" };
    }
    if (ReviewStatus === 4) {
      return { text: "Review declined", color: "red" };
    }

    if (recommendation === "Accept Submission")
      return { text: "Submisson accepted", color: "green" };
    if (recommendation === "Revision Required")
      return { text: "Revision required", color: "yellow" };
    if (recommendation === "Decline Submission")
      return { text: "Review declined", color: "red" };
    if (recommendation === "See Comments")
      return { text: "Request to see comments", color: "yellow" };
    return { text: "Reviewed", color: "green" };
  }
};
