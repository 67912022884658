export default {
  ADD_MESSAGE: "ADD_MESSAGE",
  ADD_MESSAGES: "ADD_MESSAGES",
  ADD_OTHER_MESSAGES: "ADD_OTHER_MESSAGES",
  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",

  GET_ROLES: "GET_ROLES",
  GET_TEMPLATES: "GET_TEMPLATES",
  GET_CHECKLIST: "GET_CHECKLIST",
  GET_SECTIONS: "GET_SECTIONS",
  GET_SUBSECTIONS: "GET_SUBSECTIONS",
  GET_COMPONENTS: "GET_COMPONENTS",
  GET_RECOMMENDATIONS: "GET_RECOMMENDATIONS",
  GET_SALUTATIONS: "GET_SALUTATIONS",

  GET_LOGGEDIN_USER_DETAIL: "GET_LOGGEDIN_USER_DETAIL",
  GET_USER_LIST: "GET_USER_LIST",

  GET_ARTICLE: "GET_ARTICLE",
  COUNT_ARTICLE: "COUNT_ARTICLE",
  DELETE_ARTICLE: "DELETE_ARTICLE",

  GET_REVIEW: "GET_REVIEW",
};
