import React, { useState } from "react";
import { Button } from "element-react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitReview as SubmitArticleReview } from "store/actions/articles";
import ArticleStatusEnum from "constants/articleStatusEnum";

const SubmitReview = (props) => {
  const { onConfirmation } = props;
  const { review } = useSelector((st: any) => st.articleReducer);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const onSaveClicked = async () => {
    setLoading(true);
    var res: any = await dispatch(SubmitArticleReview(review.ReviewId));
    setLoading(false);
    if (res && res.Status) onConfirmation();
  };
  return (
    <div>
      <div className="upload-steps__info">
        Your submission has been submitted and is ready to be sent. You may go
        back to review and adjust any of the information you have entered before
        continuing. When you are ready, click "Finish Review"
      </div>
      <div>
        <Button
          disabled={review.IsCompleted}
          type="primary"
          onClick={() => onSaveClicked()}
          loading={isLoading}
        >
          Finish Review
        </Button>
      </div>
    </div>
  );
};

export default SubmitReview;
