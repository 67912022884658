import React, { useEffect, useState } from "react";
import { Button } from "element-react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "helpers";

import { SaveAttachment } from "store/actions/articles";
import { FiFile } from "react-icons/fi";
import ArticleStatusEnum from "constants/articleStatusEnum";
import { AttachmentButton } from "components/form";
const Attachments = (props) => {
  const { onSave, files, title } = props;

  const dispatch = useDispatch();

  return (
    <div className="attachment__container">
      <div className="attachment__header">
        <div>{title}</div>
        <div className="attachment__header-action"></div>
      </div>

      <div className="attachment__body">
        {files &&
          files.map((file, index) => (
            <div className="attachment__files" key={index}>
              <div className="attachment__files-info">
                <div className="attachment__files-info-title">
                  <AttachmentButton value={file} isPreview={true} />
                </div>
                <div className="attachment__files-info-subtitle">
                  Component: {file.ComponentName}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Attachments;
