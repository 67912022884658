import React, { useState, useEffect, useRef } from "react";
import ReactDOM, { createPortal } from "react-dom";
import useOutsideClick from "hooks/useOutsideClick";
import configureStore from "store/configureStore";
import { Provider } from "react-redux";
import "./style.scss";
const store = configureStore({});

interface IProps {
  open: boolean;
  onClose: Function;
  children: any;
  title?: any;
}

const Dialog = (props: IProps) => {
  const { open } = props;
  const [state, setState] = useState({
    visibility: "visible",
  });
  useEffect(() => {
    setState({ ...state, visibility: open ? "visible" : "hidden" });
  }, [open]);

  const handleClick = () => {
    props.onClose && props.onClose();
  };
  const render = () => {
    const style: any = { ...state };
    style.visibility = open ? "visible" : "hidden";

    return createPortal(
      <div className="irc-dialog" style={style}>
        <div className="irc-dialog-container">
          <div className="irc-dialog-header">
            <span>{props?.title}</span>
            <span className="irc-dialog-header-closable" onClick={handleClick}>
              X
            </span>
          </div>
          <div className="irc-dialog-body">{props.children}</div>
        </div>
      </div>,
      document.body
    );
  };
  return render();
};
Dialog.defaultProps = {
  containerClassName: "irc-dialog",
  // height: 360,
  // width: 300,
};
export default Dialog;
