import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReviewerPopup from "./reviewerPopup";

const ReviewerInput = (props) => {
  const { row, handleReveiwerSelected, selectedReviewer, onChange } = props;
  const popRef: any = useRef();
  const [state, setState] = useState({ open: false, reviewers: [] });

  const dispatch = useDispatch();

  const onReviewerSelected = (index, val) => {
    handleReveiwerSelected(val);
    // setSelectedIndex(index);
    //setOpen(false);
  };
  return (
    <div ref={popRef}>
      <div
        className="assign-selector"
        onClick={() => setState({ ...state, open: !state.open })}
      >
        {state.reviewers && state.reviewers.length > 0 ? (
          state.reviewers.map((x) => (
            <span className="assign-selector-name">{x.FullName}</span>
          ))
        ) : (
          <span className="assign-selector-info">
            {selectedReviewer ? "Change Reviewer" : "Select Reviewer"}
          </span>
        )}
      </div>

      {state.open ? (
        <ReviewerPopup
          dispatch={dispatch}
          name="reviewers"
          selectedReviewer={state.reviewers}
          popRef={popRef}
          setOpen={(val) => setState({ ...state, open: val })}
          open={state.open}
          row={row}
          onChange={(name, val) => {
            setState({ ...state, [name]: val });
            onChange && onChange(name, val);
          }}
        />
      ) : null}
    </div>
  );
};

export default ReviewerInput;
