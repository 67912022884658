import React from "react";
const HWrapper = (props) => {
  const { label, children } = props;
  return (
    <div className="hwrapper">
      {label && <div className="hwrapper-label">{`${label}: `}</div>}
      <div className="hwrapper-control">{children}</div>
    </div>
  );
};

export default HWrapper;
