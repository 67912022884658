const checkFileExtension = (file) => {
  let isImage = file && file.toLowerCase().match(/.(jpg|jpeg|png)$/i) !== null;
  let isPdf = file && file.toLowerCase().match(/.(pdf)$/i) !== null;
  let isXls = file && file.toLowerCase().match(/.(xls|xlsx)$/i) !== null;
  let isDocs = file && file.toLowerCase().match(/.(doc|docx)$/i) !== null;
  let index = file && file.indexOf(".");
  let ext = file && file.substring(index + 1, file.length);

  return {
    isImage,
    isPdf,
    isDocs,
    isXls,
    ext,
  };
};

export default checkFileExtension;
